import { BaseHeaderMatcher, ModelConfig } from './BaseHeaderMatcher';

class ProductHeaderMatcher extends BaseHeaderMatcher {
  additionalFeatures: string[];

  category: string;

  createdBy: string;

  deleted: string;

  disabled: string;

  idOrSku: string;

  minOrderQty: string;

  name: string;

  price: string;

  units: string[];
}

const ProductConfig: ModelConfig = {
  required: [
    { title: 'ID or SKU', key: 'idOrSku', type: 'string' },
    { title: 'Name', key: 'name', type: 'string' },
  ],
  optional: [
    { title: 'Price', key: 'price', type: 'string' },
    { title: 'Units', key: 'units', type: 'list' },
    { title: 'Min Order Qty', key: 'minOrderQty', type: 'string' },
    { title: 'Category', key: 'category', type: 'string' },
    { title: 'Disabled', key: 'disabled', type: 'string' },
    { title: 'Deleted', key: 'delted', type: 'string' },
  ],
};

export { ProductHeaderMatcher, ProductConfig };

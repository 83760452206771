import { AxiosError } from 'axios';
import { capitalize, trim } from 'lodash';

import { logXhrError } from './xhr';
import { globalAlertData } from '../state/globalAlertData';

const genericErrorMessage = (error: unknown) => {
  let errorMessage;
  if (error instanceof AxiosError) {
    errorMessage = logXhrError(error);
  } else if (Array.isArray(error)) {
    console.error(error);
    errorMessage = error.join(', ') || undefined;
  } else if (error instanceof Error) {
    console.error(error.message);
    errorMessage = error.message || undefined;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }

  errorMessage = errorMessage ? capitalize(trim(errorMessage)) : undefined;

  return errorMessage;
};

const genericErrorFeedback = (errorTitle: string) => (error: unknown) => {
  const errorMessage = genericErrorMessage(error);
  globalAlertData.create(errorTitle, errorMessage, '#e57373');
};

export { genericErrorMessage, genericErrorFeedback };

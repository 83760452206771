import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import adam from '../../../assets/adam-face.png';

interface Props {
  children: ReactNode;
  variant?: 'small' | 'default';
}

const AdamMessageWrapper = ({ children, variant = 'default' }: Props) => (
  <div className={twMerge(
    'flex h-fit gap-5',
    variant === 'small' && 'gap-3',
  )}
  >
    <img
      src={adam}
      alt="adam"
      className={twMerge(
        'rounded-full object-cover h-10 w-10',
        variant === 'small' && 'h-8 w-8',
      )}
    />
    <div className={twMerge(
      'pt-3',
      variant === 'small' && 'pt-2 text-sm',
    )}
    >
      {children}
    </div>
  </div>
);

export default AdamMessageWrapper;

import { useCallback, useEffect, useState } from 'react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { twMerge } from 'tailwind-merge';
import { globalAlertData } from '../../state/globalAlertData';

interface Props {
  title: string;
  message?: string;
  iconColor?: string;
}

const Alert = ({
  title,
  message,
  iconColor, // Why... iconColor -> theme
}: Props) => {
  const [open, setOpen] = useState(true);
  const [animation, setAnimation] = useState('animate-slide-in');

  const onConfirmClick = useCallback(() => {
    setOpen(false);
    globalAlertData.dismiss();
  }, []);

  useEffect(() => {
    // if alert type was not an error
    if (onConfirmClick && iconColor !== '#e57373') {
      setTimeout(() => {
        setAnimation('animate-slide-out');
        setTimeout(() => {
          onConfirmClick();
        }, 500);
      }, 5000);
    }
  }, [iconColor, onConfirmClick]);

  // Success
  if (iconColor === '#00c853') {
    return (
      <div className="absolute left-0 top-0 h-screen w-screen">
        <div className="mr-[10px] mt-[70px] flex justify-end">
          <div
            className={twMerge(
              animation,
              'z-[200] flex min-w-[20%] max-w-[25%] justify-between border-l-4 border-green-400 bg-white p-4 shadow-lg',
            )}
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-md font-medium text-green-800">{title}</h3>
                {message && message.length > 0 && (
                  <div className="mt-2 text-sm text-green-700">
                    <p>{message}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-start">
              <button
                type="button"
                className="text-green-500"
                onClick={onConfirmClick}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Error
  if (iconColor === '#e57373') {
    return (
      <Transition show={open}>
        <Dialog className="relative z-[200]" onClose={onConfirmClick}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {title}
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{message}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={onConfirmClick}
                      data-autofocus
                    >
                      Close
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }

  // Warning
  return (
    <div className="absolute left-0 top-0 h-screen w-screen">
      <div className="mr-[10px] mt-[70px] flex justify-end">
        <div
          className={twMerge(
            animation,
            'z-[200] flex min-w-[20%] max-w-[25%] justify-between border-l-4 border-yellow-400 bg-white p-4 shadow-lg',
          )}
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-md font-medium text-yellow-800">{title}</h3>
              {message && message.length > 0 && (
                <div className="mt-2 text-sm text-yellow-700">
                  <p>{message}</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-start">
            <button
              type="button"
              className="text-green-500"
              onClick={onConfirmClick}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Alert.defaultProps = {
  message: null,
  iconColor: '#00c853',
};

export { Alert };

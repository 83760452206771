import {
  memo, useEffect, useMemo, useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { useSearchParams } from 'react-router-dom';

import { Page } from 'components/layout/Page/Page';
import { ChatList } from 'components/chat/chat-list';
import { Chat as ChatComponent } from 'components/chat/Chat/Chat';
import { DetailPanel } from 'components/common/DetailPanel';
import { ProcessOrderProvider } from 'features/order/contexts/useProcessOrderContext';
import {
  DetailPanelWidthContext,
  DetailPanelWidthType,
} from 'contexts/useDetailPanelWidthContext';
import { ChatsProvider, useChatsContext } from 'contexts/useChatsContext';
import {
  DetailPanelContentContext,
  DetailPanelContentType,
} from 'contexts/useDetailPanelContentContext';
import { OrderProvider } from 'features/order/contexts/useOrderContext';
import { PlayRecordingProvider } from 'contexts/usePlayRecordingIndex';
import { TeamMembersProvider } from 'contexts/useTeamMembersContext';
import {
  MessageProviderType,
  MessagesProvider,
  useMessagesContext,
} from 'contexts/useMessagesContext';
import { BusinessSettingsProvider } from 'contexts/useBusinessSettingsContext';
import { ChatMessagesProvider } from 'contexts/useChatMessagesContext';
import { Chat } from 'models/Chat';
import { isAiChat as isAichatHelper } from 'helpers/chat';
import { MessageIntent } from 'models/Message';

interface Props {
  // chat related
  chatId: string;
  navigateToChat: (chatId: string) => void;
}

const ChatPageContent = memo(({
  chatId,
  detailPanelWidth,
}: {
  chatId: string;
  detailPanelWidth: DetailPanelWidthType;
}) => {
  const { chats } = useChatsContext();
  const { messages, setMessages } = useMessagesContext();

  const chatGroupId = useMemo(() => messages?.[0]?.chatGroupId, [messages]);

  const chat: Chat | undefined = useMemo(
    () => chats.find((c) => c.id === chatId),
    [chats, chatId],
  );

  const isAiChat = useMemo(() => (chat ? isAichatHelper(chat) : true), [chat]);

  const mainWidthClassName = useMemo(() => {
    if (detailPanelWidth === DetailPanelWidthType.SMALL) {
      return isAiChat ? 'max-w-full min-w-full' : 'min-w-[70%] max-w-[70%]';
    }

    return isAiChat ? 'min-w-full max-w-full' : 'min-w-[50%] max-w-[50%]';
  }, [detailPanelWidth, isAiChat]);

  const detailPanelWidthClassName = useMemo(() => {
    console.log(detailPanelWidth);
    if (detailPanelWidth === DetailPanelWidthType.SMALL) {
      return isAiChat ? 'max-w-0' : 'min-w-[30%] max-w-[30%]';
    }

    return isAiChat ? 'max-w-0' : 'min-w-[50%] max-w-[50%]';
  }, [detailPanelWidth, isAiChat]);

  return (
    <ProcessOrderProvider onOrderDraftProcessed={() => {}}>
      <ChatMessagesProvider setMessages={setMessages} chat={chat} chatGroupId={chatGroupId}>
        <div
          className={twMerge(
            mainWidthClassName,
            'border-r border-blue-gray-50',
          )}
        >
          <ChatComponent chatId={chatId} />
        </div>
        <div
          className={twMerge(
            detailPanelWidthClassName,
            'h-full',
          )}
        >
          <DetailPanel chatId={chatId} />
        </div>
      </ChatMessagesProvider>
    </ProcessOrderProvider>
  );
});

function ChatPage({ chatId, navigateToChat }: Props) {
  const [searchParams] = useSearchParams();

  const unreadOnly = searchParams.get('unread_only') === 'true';
  const intent = searchParams.get('intent') as MessageIntent | null || null;
  // const messageSources = searchParams.get('message_sources')?.split(',') as MessageSource[];

  const [detailPanelWidth, setDetailPanelWidth] = useState<DetailPanelWidthType>(DetailPanelWidthType.SMALL);
  const [detailPanelContent, setDetailPanelContent] = useState<DetailPanelContentType>(DetailPanelContentType.USER_INFO);

  const detailPanelWidthContextValue = useMemo(
    () => ({
      detailPanelWidth,
      setDetailPanelWidth,
    }),
    [detailPanelWidth],
  );

  const detailPanelContentContextValue = useMemo(
    () => ({
      detailPanelContent,
      setDetailPanelContent,
    }),
    [detailPanelContent],
  );

  useEffect(() => {
    // Reset when chatId changes
    setDetailPanelWidth(DetailPanelWidthType.SMALL);
    setDetailPanelContent(DetailPanelContentType.USER_INFO);
  }, [chatId]);

  return (
    <Page isLoading={false} contentWithBorder>
      <ChatsProvider>
        <div className="flex h-full w-full">
          <div className="h-full min-w-[30%] max-w-[30%] md:min-w-[25%] md:max-w-[25%] xl:min-w-[20%] xl:max-w-[20%] 2xl:min-w-[15%] 2xl:max-w-[15%]">
            <ChatList selectedChatId={chatId} navigateToChat={navigateToChat} />
          </div>

          <div className="flex h-full flex-1">
            <PlayRecordingProvider>
              <DetailPanelWidthContext.Provider
                value={detailPanelWidthContextValue}
              >
                <DetailPanelContentContext.Provider
                  value={detailPanelContentContextValue}
                >
                  <MessagesProvider
                    providerType={MessageProviderType.BY_CHAT_ID}
                    chatId={chatId}
                    defaultFilter={{
                      unreadOnly, intent,
                    }}
                  >
                    <OrderProvider>
                      <BusinessSettingsProvider>
                        <TeamMembersProvider>
                          <ChatPageContent
                            chatId={chatId}
                            detailPanelWidth={detailPanelWidth}
                          />
                        </TeamMembersProvider>
                      </BusinessSettingsProvider>
                    </OrderProvider>
                  </MessagesProvider>
                </DetailPanelContentContext.Provider>
              </DetailPanelWidthContext.Provider>
            </PlayRecordingProvider>
          </div>
        </div>
      </ChatsProvider>
    </Page>
  );
}

export default ChatPage;

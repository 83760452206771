import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

const HStack = ({ children, className = '' }: Props) => (
  <div className={`flex justify-between ${className}`}>{children}</div>
);

export { HStack };

import { useCallback, useMemo } from 'react';
import He from 'he';
import ParseHtml from 'html-react-parser';

import { splitAtIndex } from 'helpers/strings';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { usePlayRecordingContext } from 'contexts/usePlayRecordingIndex';

import { Message } from 'models/Message';
import { twMerge } from 'tailwind-merge';

interface Props {
  message: Message;
}

const OrderBody = ({ message }: Props) => {
  const { scrollToKeyword, hoverId, triggerHoverEffect } = useProcessOrderContext();
  const { setPlayRecordingKeywordAndMessageId } = usePlayRecordingContext();

  const onMessageMarkMouseOver = useCallback(
    (keyword: string) => {
      if (keyword) triggerHoverEffect(keyword);
    },
    [triggerHoverEffect],
  );

  const onMessageMarkMouseLeave = useCallback(() => {
    triggerHoverEffect(null);
  }, [triggerHoverEffect]);

  const onMessageClick = useCallback(
    (keyword: string) => {
      if (keyword) scrollToKeyword(keyword);
      if (keyword) setPlayRecordingKeywordAndMessageId({ keyword, messageId: message.id });
    },
    [message?.id, setPlayRecordingKeywordAndMessageId, scrollToKeyword],
  );

  const messageText = useMemo(() => {
    if (message.context?.workflowOrder?.audioTranscript?.length > 0) {
      return message.context?.workflowOrder?.audioTranscript;
    }
    return message.message;
  }, [message.context, message.message]);

  const markedText = useMemo(() => {
    if (messageText) {
      const decodedString = messageText;
      let decodedStringIndex = 0;
      let remainingString = decodedString;
      const tokens: string[] = [];

      let keywords: { offset: number; word: string; originalWord: string, fieldId?: string }[] = [];
      if (message.context?.workflowOrder?.audioKeywords?.length > 0) {
        keywords = message.context?.workflowOrder?.audioKeywords;
      } else if (message.context?.workflowOrder?.keywords?.length > 0) {
        keywords = message.context?.workflowOrder?.keywords;
      }

      // reorder keywords by offset
      keywords.sort((a, b) => a.offset - b.offset);

      keywords.forEach((recordingKeyword, index) => {
        const adaptedOffset = recordingKeyword.offset - decodedStringIndex;
        const [nonKeywordPart, keywordPart] = splitAtIndex(
          remainingString,
          adaptedOffset,
        );
        tokens.push(He.decode(nonKeywordPart));
        const [keyword, restOfMessage] = splitAtIndex(
          keywordPart,
          recordingKeyword.word.length,
        );
        tokens.push(
          `&nbsp<mark keyword_index=${index})}>`
            + `&nbsp${keyword}&nbsp<span>`
            + `${recordingKeyword.fieldId || He.decode(recordingKeyword.originalWord)}</span></mark>&nbsp`,
        );
        const processedString = `${nonKeywordPart}${keyword}`;
        remainingString = restOfMessage;
        decodedStringIndex += processedString.length;
      });
      tokens.push(He.decode(remainingString));
      return tokens.join('');
    }
    return '';
  }, [
    message.context,
    messageText,
  ]);

  if (!messageText) return null;

  return (
    // eslint-disable-next-line react/no-danger
    <div className="whitespace-pre-wrap font-[400]">
      {ParseHtml(markedText, {
        // TODO: Optimize and fix typings in parsehtml.replace for marked keywords
        // eslint-disable-next-line react/no-unstable-nested-components
        replace: (domNode) => {
          // @ts-ignore
          if (domNode.name === 'mark') {
            // @ts-ignore
            const keyword = domNode.children[0].data;
            // @ts-ignore
            const originalKeyword = domNode.children[1].children[0].data;
            // @ts-ignore
            // const props = attributesToProps(domNode.attribs);
            return (
              // @ts-ignore
              <mark
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                // eslint-disable-next-line react/prop-types
                onMouseOver={() => onMessageMarkMouseOver(originalKeyword)}
                // eslint-disable-next-line react/prop-types
                onClick={() => onMessageClick(originalKeyword)}
                onMouseLeave={onMessageMarkMouseLeave}
                onKeyDown={() => {}}
                onFocus={() => {}}
                className={twMerge(
                  hoverId === originalKeyword ? 'bg-[#FFFF00]' : 'bg-primary-50 hover:bg-primary-100',
                  'cursor-pointer rounded-sm p-xs',
                )}
              >
                {keyword}
              </mark>
            );
          }
          return domNode;
        },
      })}
    </div>
  );
};

export default OrderBody;

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { globalChats } from 'state/globalChats';
import { globalOrderDrafts } from 'state/globalOrderDrafts';
import { globalMessageIntents } from 'state/globalMessageIntents';
import { ROUTES } from 'config/routes';

import { HomePage } from '../pages';

const HomeScreen = () => {
  const navigate = useNavigate();

  const navigateToOrderDraftsProcessing = useCallback(() => {
    navigate(ROUTES.PROCESS_ORDER_DRAFTS);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <HomePage
        orderDrafts={globalOrderDrafts}
        chats={globalChats}
        messageIntents={globalMessageIntents}
        navigateToOrderDraftsProcessing={navigateToOrderDraftsProcessing}
      />
    </ProtectedScreen>
  );
};

export default HomeScreen;

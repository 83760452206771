import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { ROUTES } from 'config/routes';

import { TeamMemberInvitePage } from '../pages';

const TeamMemberInviteScreen = () => {
  const navigate = useNavigate();

  const navigateToTeamMembersList = useCallback(() => {
    navigate(ROUTES.TEAM_MEMBERS);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <TeamMemberInvitePage navigateToTeamMembersList={navigateToTeamMembersList} />
    </ProtectedScreen>
  );
};

export default TeamMemberInviteScreen;

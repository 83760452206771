import { useCallback, useState } from 'react';

const useWizard = (stepsNumber: number) => {
  const [currentStep, setCurrentStep] = useState(0);

  const goToNextStep = useCallback(() => {
    setCurrentStep((step) => Math.min(step + 1, stepsNumber));
  }, [stepsNumber]);

  const goToPrevStep = useCallback(() => {
    setCurrentStep((step) => Math.max(step - 1, 0));
  }, []);

  const goToStep = useCallback(
    (number: number) => {
      if (number >= 0 && number < stepsNumber) {
        setCurrentStep(number);
      }
    },
    [stepsNumber],
  );

  return {
    currentStep,
    goToStep,
    goToNextStep,
    goToPrevStep,
  };
};

export { useWizard };

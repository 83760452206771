import { useState, useCallback } from 'react';

import { httpPost } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { globalAlertData } from 'state/globalAlertData';

const useDeclineOrderDraft = () => {
  const [isLoading, setIsLoading] = useState(false);

  const declineOrderDraft = useCallback(
    (id: string): Promise<void> => {
      if (isLoading) return Promise.resolve();
      setIsLoading(true);
      return httpPost(`/orders/${id}/reject`, { id })
        .then(() => {
          globalAlertData.create('Order declined successfully');
        })
        .catch((error) => {
          genericErrorFeedback('Decline order failed')(error);
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [isLoading],
  );

  return { isLoading, declineOrderDraft };
};

export { useDeclineOrderDraft };

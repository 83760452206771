import { AnimatePresence } from 'framer-motion';
import DefaultButtons from './DefaultButtons';
import MagicPenButtons from './MagicPenButtons';

import { DefaultButtonsProps, MagicPenButtonsProps } from './types';

interface Props {
  mode: 'default' | 'magic-pen';
  defaultProps?: DefaultButtonsProps;
  magicPenProps?: MagicPenButtonsProps;
}

const Buttons = ({ mode, defaultProps, magicPenProps }: Props) => (
  <AnimatePresence>
    {mode === 'default' ? <DefaultButtons {...defaultProps} /> : <MagicPenButtons {...magicPenProps} />}
  </AnimatePresence>
);

export default Buttons;

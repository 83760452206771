import { useCallback, useMemo, useState } from 'react';
import { LoadingOverlay } from '@mantine/core';

import { genericErrorFeedback } from 'helpers/errors';
import { httpPost } from 'helpers/xhr';
import { useFetchCustomers } from '../../../../hooks/fetch/useFetchCustomers';
import { FlashCard } from '../../../ui/FlashCard';
import { Select, SelectOption } from '../../../ui/Select';
import { globalAlertData } from '../../../../state/globalAlertData';
import { Button } from '../../../ui/Button';
import { Business } from '../../../../models/Business';

interface Props {
  customer?: Business;
  onComplete: (customer: Business) => void;
}

const _Body = ({ customer, onComplete }: Props) => {
  const {
    customers,
    isLoading: isCustomerLoading,
    loadCustomers,
    setSearchQuery,
  } = useFetchCustomers({});

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<SelectOption>(
    () => null,
  );

  const customerOptions = useMemo(
    () => customers.map((_customer) => ({
      label: `${_customer.name}`,
      value: _customer.id,
    })),
    [customers],
  );

  const onCustomerSelectChange = useCallback(
    (selected: { value: string; label: string }) => {
      setSelectedCustomer(selected);
    },
    [setSelectedCustomer],
  );

  const onCustomersSearchChange = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  const onCustomersSelectOptionsScrolledEnd = useCallback(() => {
    loadCustomers({});
  }, [loadCustomers]);

  const onConfirmMergeButtonClick = useCallback(() => {
    if (customer && selectedCustomer) {
      setIsLoading(true);
      httpPost(`/businesses/me/customers/${customer.id}/reconcile`, {
        toCustomerId: selectedCustomer.value,
      })
        .then(() => {
          onComplete(customers.find((c) => c.id === selectedCustomer.value));
          globalAlertData.create('Customer merged successfully');
        })
        .catch(genericErrorFeedback('Error merging customer'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [customer, onComplete, selectedCustomer, customers]);

  return (
    <div className="space-y-5 px-1">
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />
      <FlashCard
        title="Migration warning"
        description="All messages and orders will be migrated to the selected customer permanently."
      />

      <Select
        label="Select a customer"
        selectedOption={selectedCustomer}
        options={customerOptions}
        isLoading={isCustomerLoading}
        isExternalSearchEnabled
        onSelectionChange={onCustomerSelectChange}
        onSearchPerformed={onCustomersSearchChange}
        onScrolledEnd={onCustomersSelectOptionsScrolledEnd}
      />

      {/* Set bottom padding to give space for the select */}
      <div className="flex justify-end border-t pb-[400px] pt-3">
        <Button
          title="Confirm"
          onClick={onConfirmMergeButtonClick}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export { _Body };

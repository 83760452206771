import TypeWriter from 'typewriter-effect';
import { AdamMessageWrapper } from '../../../../wrapper/AdamMessageWrapper';

interface Props {
  onNext: () => void;
}

const text = 'I will redirect you to the order instruction page';

const RedirectToOrder = ({ onNext }: Props) => (
  <AdamMessageWrapper>
    <span className="flex">
      <TypeWriter
        onInit={(typewriter) => {
          typewriter
            .changeDelay(15)
            .typeString(text)
            .start()
            .changeDelay(150)
            .changeDeleteSpeed(150)
            .typeString('...')
            .callFunction(() => {
              setTimeout(() => {
                onNext();
              }, 2000);
            })
            .deleteChars(3)
            .typeString('...')
            .deleteChars(3)
            .typeString('...')
            .start();
        }}
      />
    </span>
  </AdamMessageWrapper>
);
export default RedirectToOrder;

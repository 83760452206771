import { Button } from '@mantine/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Attachment, AttachmentType } from '../../../../../models/Message';

import pdf from '../../../../../assets/document-icons/pdf.svg';
import excel from '../../../../../assets/document-icons/excel.svg';
import phone2 from '../../../../../assets/document-icons/phone.svg';
import png from '../../../../../assets/document-icons/png.svg';
import generic from '../../../../../assets/document-icons/generic.svg';

const getIconForAttachment = (attachmentType: AttachmentType) => {
  switch (attachmentType) {
    case AttachmentType.PDF:
      return pdf;
    case AttachmentType.EXCEL:
      return excel;
    case AttachmentType.AUDIO:
      return phone2;
    case AttachmentType.IMAGE:
      return png;
    default:
      return generic;
  }
};

export const AttachmentList = ({ attachments }: { attachments: Attachment[] }) => (
  <div>
    <div className="flex gap-2">
      {attachments.map((attachment) => (
        <Button
          key={attachment.url}
          variant="outline"
          className="group"
          leftSection={(
            <>
              <img
                src={getIconForAttachment(attachment.type)}
                className="w-5 h-5 group-hover:hidden"
                alt={attachment.type.toString()}
              />

              <FontAwesomeIcon icon={faDownload} className="w-5 h-5 group-hover:block hidden" />
            </>
          )}
          onClick={() => {
            window.open(attachment.url, '_blank');
          }}
        >
          {attachment.name}
        </Button>
      ))}
    </div>
  </div>
);

import { makeObservable, observable, action } from 'mobx';

class AlertData {
  title: string | null = null;

  message?: string = undefined;

  iconColor?: string = undefined;

  constructor() {
    makeObservable(this, {
      title: observable,
      message: observable,
      iconColor: observable,
      create: action,
      dismiss: action,
    });
  }

  create = (title: string, message?: string, iconColor?: string) => {
    this.title = title;
    this.message = message || undefined;
    this.iconColor = iconColor || undefined;
  };

  dismiss = () => {
    this.title = null;
    this.message = undefined;
    this.iconColor = undefined;
  };
}

export { AlertData };

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { ROUTES } from 'config/routes';
import { ChatsProvider } from 'contexts/useChatsContext';

import { ChatListPage } from '../pages';

const ChatListScreen = () => {
  const navigate = useNavigate();

  const navigateToChat = useCallback(
    (chatId: string) => {
      navigate(ROUTES.CHAT_BY_ID(chatId));
    },
    [navigate],
  );

  return (
    <ProtectedScreen>
      <ChatsProvider>
        <ChatListPage navigateToChat={navigateToChat} />
      </ChatsProvider>
    </ProtectedScreen>
  );
};

export default ChatListScreen;

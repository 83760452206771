import { useEffect, useRef } from 'react';

import { Page } from 'components/layout/Page/Page';
import { Card } from 'components/common/Card';
import { TeamMemberIcon } from 'components/team-member';
import { Tag } from 'components/common/Tag';
import { TextWithLabel } from 'components/ui/TextWithLabel';
import { formatUserRole, userRoleToTagVariant } from 'helpers/enums';
import { isUserActivated } from 'helpers/user';
import { TagVariant } from 'types/tag';
import { useDeleteUserById } from 'features/user/hooks/useDeleteUserById';
import { useFetchUserById } from 'features/user/hooks/useFetchUserById';
import { LoadingOverlay } from '@mantine/core';
import { Button } from 'components/ui/Button';
import { ConfirmModalWrapperBase } from 'components/wrapper';
import { genericErrorFeedback } from 'helpers/errors';
import { ROUTES } from 'config/routes';
import { useNavigate } from 'react-router-dom';

interface Props {
  userId: string;
}

const TeamMemberDetailsPage = ({ userId }: Props) => {
  const navigate = useNavigate();

  const { isLoading: isDeletingUser, deleteUserById } = useDeleteUserById();
  const { isLoading: isFetchingUser, user, fetchUserById } = useFetchUserById();

  const ref = useRef(null);

  const handleDeleteUser = () => {
    if (ref.current) {
      ref.current.openModal();
    } else {
      genericErrorFeedback('Error opening modal')('');
    }
  };

  const onConfirmDeleteUser = () => {
    deleteUserById(userId)
      .then(() => {
        navigate(ROUTES.TEAM_MEMBERS);
      });
  };

  useEffect(() => {
    fetchUserById(userId);
  }, [fetchUserById, userId]);

  return (
    <ConfirmModalWrapperBase
      ref={ref}
      title="Delete user"
      description="Are you sure you want to delete this user?"
      onConfirm={onConfirmDeleteUser}
    >
      <Page contentWithPadding>
        <LoadingOverlay
          visible={isFetchingUser || isDeletingUser}
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
        <div className="flex flex-1 flex-col overflow-hidden py-lg items-center">
          <div className="flex flex-1 flex-col overflow-y-auto space-y-4 w-full px-[15%]">
            <div className="flex flex-col justify-center gap-2">
              <h1 className="text-xl font-semibold leading-none text-gray-900">
                Team member details
              </h1>
              <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
                View team member details
              </div>
            </div>
            <div className="w-full flex-1 flex space-x-6">
              <div className="w-[30%]">
                <Card>
                  <Card.Body>
                    <div className="flex flex-col items-center">
                      <div className="mb-3.5">
                        <TeamMemberIcon user={user} />
                      </div>
                      <div className="mb-2 flex items-center justify-center gap-1.5">
                        <div className="hover:text-primary-active text-base font-semibold leading-5 text-gray-900">
                          {!isUserActivated(user)
                            ? 'Not activated'
                            : `${user?.firstName} ${user?.lastName}`}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        {user?.roles?.map((role) => (
                          <Tag
                            key={role}
                            className="static"
                            isTagLoading={!user}
                            tagTitle={formatUserRole(role)}
                            tagVariant={userRoleToTagVariant(role)}
                            hideCircle
                          />
                        ))}
                      </div>
                    </div>
                    <div className="space-y-1">
                      {
                    user?.username && (
                      <TextWithLabel
                        label="Username"
                        value={user?.username}
                      />
                    )
                  }
                      {
                    user?.email && (
                      <TextWithLabel
                        label="Email"
                        value={user?.email}
                      />
                    )
                  }
                      {
                    user?.phone && (
                      <TextWithLabel
                        label="Phone"
                        value={user?.phone}
                      />
                    )
                  }
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="flex-1 flex-col overflow-hidden">
                <div className="flex-1 space-y-4 pb-10">
                  <Card>
                    <Card.Header>
                      <div className="flex gap-2">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                          User&apos;s Schedule
                        </h3>
                        <Tag
                          tagTitle="Soon"
                          tagVariant={TagVariant.BLUE}
                          isTagLoading={false}
                          hideCircle
                          className="static"
                        />
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="flex justify-center items-center h-[20vh]">
                        Here you will see the schedule of the user
                      </div>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Header>
                      <div className="flex gap-2">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                          User&apos;s Tasks
                        </h3>
                        <Tag
                          tagTitle="Soon"
                          tagVariant={TagVariant.BLUE}
                          isTagLoading={false}
                          hideCircle
                          className="static"
                        />
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="flex justify-center items-center h-[40vh]">
                        Here you will see the tasks assigned to the user
                      </div>
                    </Card.Body>
                  </Card>
                  <div className="flex justify-end">
                    <Button
                      title="Delete user"
                      theme="red"
                      onClick={handleDeleteUser}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </ConfirmModalWrapperBase>
  );
};

export default TeamMemberDetailsPage;

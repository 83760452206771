interface Props {
  label: string;
  children: React.ReactNode;
}

const LabelWrapper = ({ label, children }: Props) => (
  <div className="flex w-full flex-wrap items-baseline gap-2.5 lg:flex-nowrap">
    <p className="flex w-full max-w-56 items-center gap-1">{label}</p>
    {children}
  </div>
);

export default LabelWrapper;

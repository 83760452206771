const ROUTES = {
  HOME: '/',

  SIGNIN: (username: string) => `/signin/${username}?`,
  INVITED_SIGNUP: (appLink: string) => `/invited-signup/${appLink}`,
  ACTIVATE_USER: '/activate-user',
  SEND_RESET_PASSWORD_LINK: '/send-reset-password-link',
  RESET_PASSWORD: '/password-reset',

  PRODUCTS: '/products',
  PRODUCT_BY_ID: (productId: string) => `/products/${productId}`,
  PRODUCTS_IMPORT: '/products/import',

  CUSTOMERS: '/customers',
  CUSTOMER_BY_ID: (customerId: string) => `/customers/${customerId}`,
  CUSTOMERS_IMPORT: '/customers/import',

  CHATS: '/chats',
  NEW_ADAM_CHAT: '/chats/adam/new',
  CHAT_BY_ID: (chatId: string) => `/chats/${chatId}`,
  MESSAGE_BY_ID: (messageId: string) => `/messages/${messageId}`,
  INBOX: (params: Record<string, string>) => {
    const queryString = new URLSearchParams(params).toString();
    return `/inbox?${queryString}`;
  },

  ORDERS: '/orders',
  ORDER_BY_ID: (orderId: string) => `/orders/${orderId}`,

  PROCESS_ORDER_DRAFTS: '/orders/process-draft',
  PROCESS_ORDER_DRAFT_BY_ID: (orderId: string) => `/orders/process-draft/${orderId}`,
  PROCESS_NEW_ORDER_DRAFTS: '/orders/process-new-draft',

  TEAM_MEMBERS: '/team-members',
  TEAM_MEMBER_ADD: '/team-members/add',
  TEAM_MEMBER_BY_ID: (teamMemberId: string) => `/team-members/${teamMemberId}`,

  INSTRUCTIONS: '/instructions',
  CREATE_INSTRUCTION: '/instruction/create',
  EDIT_INSTRUCTION: (instructionId: string) => `/instruction/edit/${instructionId}`,

  SETTINGS: '/settings',
};

export { ROUTES };

import Dayjs from 'dayjs';

import {
  CheckIcon, Textarea, Timeline,
} from '@mantine/core';
import { memo } from 'react';
import { formatOrderStatus } from 'helpers/enums';
import { Order, OrderDeliveryStatusTrail, OrderStatus } from '../../models/Order';

interface Props {
  order: Order;
}

const StatusIcon = ({ status }: { status: OrderStatus }) => {
  if (status === OrderStatus.Confirmed) return <CheckIcon size={12} />;
  return null;
};

const StatusColor = (status: OrderStatus) => {
  if (status === OrderStatus.Confirmed) return 'green';
  return 'gray';
};

const ItemProps = (status: OrderStatus) => {
  if (status === OrderStatus.Confirmed) {
    return {
      bullet: <StatusIcon status={status} />,
      color: StatusColor(status),
    };
  }
  return { color: 'gray' };
};

const TitleWithDatetime = memo(({
  status,
  datetime,
}: {
  status: OrderStatus;
  datetime: string;
}) => (
  <div className="flex-row justify-between">
    <div className="text-semibold">{`Order ${formatOrderStatus(status)}`}</div>
    <div className="text-xs text-gray-400">{Dayjs(datetime).format('lll')}</div>
  </div>
));

const Content = memo(({ statusTrail }: { statusTrail: OrderDeliveryStatusTrail }) => (
  statusTrail?.message ? (
    <Textarea
      label="Comment"
      variant="unstyled"
      value={statusTrail.message}
      classNames={{
        label: 'text-gray-400',
        input: 'text-gray-900',
      }}
      readOnly
    />
  ) : null
));

const Events = ({ order }: Props) => {
  if (!order) return null;

  return (
    <Timeline active={1} bulletSize={24} lineWidth={2}>
      {
          order.statusTrails.reverse().map((s) => (
            <Timeline.Item
              title={<TitleWithDatetime status={s.status} datetime={s.createdAt} />}
              {...ItemProps(s.status)}
            >
              <Content statusTrail={s} />
            </Timeline.Item>
          ))
        }
    </Timeline>
  );
};

export default Events;

import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';
import {
  Combobox, Input, InputBase, useCombobox,
} from '@mantine/core';

import { SetStateAction } from 'react';
import { MatchStatus } from '../../models/matcher/BaseHeaderMatcher';
import { HeaderMatcher } from '../../models/matcher/HeaderMatcher';
import { Button } from '../ui/Button';
import { Wrapper } from './Wrapper';

type SelectMenuProp = {
  matchers: HeaderMatcher[];
  selected: any;
  setSelected: React.Dispatch<any>;
};

const icons: Record<string, React.ReactNode> = {
  success: <CheckIcon className="aspect-square w-5 text-success" />,
  fail: <XMarkIcon className="aspect-square w-5 text-danger" />,
};

const SelectMenuMantine = ({
  matchers,
  selected,
  setSelected,
}: SelectMenuProp) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = matchers.map((matcher) => (
    <Combobox.Option
      value={matcher.matcherName}
      key={matcher.id}
      className="flex items-center justify-between"
    >
      <div>{matcher.matcherName}</div>
      <div>{matcher.isMatched ? icons.success : icons.fail}</div>
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setSelected(matchers.filter((m) => m.matcherName === val)[0]);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          className="rounded-full"
        >
          {selected ? (
            selected.matcherName
          ) : (
            <Input.Placeholder>Select Matcher</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

interface Props {
  showMatchingPreviewText: boolean;
  animate: number;
  animateDelayed: number;
  previewItems: string[][];
  matchingStates: MatchStatus[];
  matchers: HeaderMatcher[];
  selectedMatcher: any;
  setSelectedMatcher: React.Dispatch<SetStateAction<HeaderMatcher>>;
  setOpenCreateMatching: React.Dispatch<SetStateAction<boolean>>;
  onStepClicked: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => Promise<void>;
  onStepBackClicked: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => void;
}

const FieldsMatching = ({
  showMatchingPreviewText,
  matchingStates,
  animate,
  animateDelayed,
  previewItems,
  matchers,
  selectedMatcher,
  setSelectedMatcher,
  setOpenCreateMatching,
  onStepClicked,
  onStepBackClicked,
}: Props) => (
  <Wrapper
    stepCriteria={matchingStates.filter((item) => !item.matched).length === 0}
    onStepClicked={(e) => {
      if (matchingStates.filter((item) => !item.matched).length === 0) onStepClicked(e, 'Review & Confirm');
    }}
    onStepBackClicked={(e) => {
      onStepBackClicked(e, 'Fields Matching');
    }}
  >
    <div className="flex-grow">
      <div className="flex justify-between">
        <p className="text-label-caption-md text-description">
          Select or create a matching method that aligns with your file.
        </p>
      </div>
      <div className="pt-2">
        <div className="flex items-center space-x-4">
          <div className="min-w-[300px] max-w-[300px]">
            <SelectMenuMantine
              matchers={matchers}
              selected={selectedMatcher}
              setSelected={setSelectedMatcher}
            />
          </div>
          <Button
            title="Add"
            theme="secondary"
            onClick={() => setOpenCreateMatching(true)}
          />
        </div>
      </div>
      <div className="pt-2">
        {showMatchingPreviewText && (
          <div className="flex items-center">
            <p className="text-2xl font-bold text-[#539AF7]">
              Matching Preview
            </p>

            {selectedMatcher && selectedMatcher.isMatched ? (
              <CheckCircleIcon className="ml-3 aspect-square w-7 text-success" />
            ) : (
              <XMarkIcon className="ml-3 aspect-square w-7 text-danger" />
            )}
          </div>
        )}
        {previewItems.length > 0 && (
          <div className="flex justify-center pt-2">
            {selectedMatcher && (
              <div className="w-[70vw] space-y-2">
                <div className="flex items-center space-x-2">
                  <p className="relative h-12 w-52 px-slg py-smd text-center text-title-md font-semibold text-primary-500">
                    Database
                  </p>
                  <span className="h-5 w-5" />
                  <div className="h-1 flex-grow">
                    <div className="h-full w-full" />
                  </div>
                  <div className="w-10" />
                  <div className="h-1 flex-grow">
                    <div className="h-full w-full" />
                  </div>
                  <span className="h-5 w-5" />
                  <p className="relative h-12 w-52 px-slg py-smd text-center text-title-md font-semibold text-primary-500">
                    Your Header
                  </p>
                </div>

                {matchingStates
                  && matchingStates.map((state, idx) => (
                    <div
                      key={`${state.key + idx.toString()}`}
                      className="flex items-center space-x-2"
                    >
                      <div className="flex h-12 w-52 items-center justify-center rounded-sm border border-blue-gray-200">
                        <p className="truncate px-slg py-smd text-title-sm font-semibold">
                          {state.title}
                        </p>
                      </div>
                      <span
                        className={`border-2 ${state.matched ? 'border-success' : 'border-danger'} h-5 w-5 rounded-full`}
                      />
                      <div className="h-1 flex-grow">
                        <div
                          key={animate}
                          className={`h-full w-0 ${state.matched ? 'bg-green-300' : 'bg-red-600'} ${animate && 'animate-growWidth'}`}
                        />
                      </div>
                      <div
                        key={animateDelayed}
                        className={`opacity-0 ${animateDelayed === animate && 'animate-instantFadeIn'}`}
                      >
                        {state.matched ? (
                          <CheckCircleIcon className="ml-3 aspect-square w-7 text-success" />
                        ) : (
                          <XMarkIcon className="ml-3 aspect-square w-7 text-danger" />
                        )}
                      </div>
                      <div className="h-1 flex-grow">
                        <div
                          key={animateDelayed}
                          className={`h-full w-0 ${state.matched ? 'bg-green-300' : 'bg-red-600'} ${animateDelayed === animate && 'animate-growWidth'}`}
                        />
                      </div>
                      <span
                        className={`border-2 ${state.matched ? 'border-success' : 'border-danger'} h-5 w-5 rounded-full`}
                      />
                      <div className="flex h-12 w-52 items-center justify-center rounded-sm border border-blue-gray-200">
                        <p className="truncate px-slg py-smd text-title-sm font-semibold">
                          {state.key}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  </Wrapper>
);

export { FieldsMatching };

import { useLocation } from 'react-router-dom';

import { Page } from 'components/layout/Page/Page';
import { OrderProvider } from 'features/order/contexts/useOrderContext';
import { TeamMembersProvider } from 'contexts/useTeamMembersContext';
import {
  MessageProviderType,
  MessagesProvider,
} from 'contexts/useMessagesContext';
import { PlayRecordingProvider } from 'contexts/usePlayRecordingIndex';
import { BusinessSettingsProvider } from 'contexts/useBusinessSettingsContext';
import { SchemasProvider } from 'contexts/useSchemasContext';

import { ProcessNewOrderDraft } from 'features/order/components/process-order-draft';
import { ProcessOrderProvider } from 'features/order/contexts/useProcessOrderContext';

interface Props {
  navigateToHome: () => void;
}

const ProcessNewOrderDraftPage = ({ navigateToHome }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const messageId = searchParams.get('message_id');

  return (
    <Page isLoading={false} contentWithBorder>
      <MessagesProvider
        providerType={MessageProviderType.BY_MESSAGE_ID}
        messageId={messageId}
      >
        <PlayRecordingProvider>
          <BusinessSettingsProvider>
            <OrderProvider>
              <ProcessOrderProvider onOrderDraftProcessed={navigateToHome}>
                <SchemasProvider>
                  <TeamMembersProvider>
                    <ProcessNewOrderDraft />
                  </TeamMembersProvider>
                </SchemasProvider>
              </ProcessOrderProvider>
            </OrderProvider>
          </BusinessSettingsProvider>
        </PlayRecordingProvider>
      </MessagesProvider>
    </Page>
  );
};

export default ProcessNewOrderDraftPage;

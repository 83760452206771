import { twMerge } from 'tailwind-merge';
import { Loader, Tooltip } from '@mantine/core';
import { TagVariant } from '../../types/tag';

export interface TagProps {
  className?: string;

  isTagLoading: boolean;
  tagTitle: string;
  tagVariant: TagVariant;

  hoverText?: string;
  hideCircle?: boolean;
}

const Tag = ({
  className,
  isTagLoading,
  tagTitle,
  tagVariant,
  hoverText,
  hideCircle,
}: TagProps) => (
  <Tooltip
    label={hoverText}
    disabled={!hoverText}
    bg="white"
    styles={{
      tooltip: {
        color: 'black',
        border: '1.5px solid #E8E8E8',
        boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      },
    }}
    transitionProps={{ transition: 'pop' }}
  >
    <div className={twMerge('absolute -top-[30px] right-0', className)}>
      {isTagLoading ? (
        <span className="relative inline-flex items-center rounded-sm bg-blue-100 px-10 py-1 text-xs font-medium text-blue-500">
          <Loader type="dots" size="xs" color="cyan" />
          {/* Loading... */}
        </span>
      ) : (
        <span
          className={twMerge(
            'inline-flex items-center gap-x-1.5 rounded-sm px-2 py-1 text-xs font-medium',
            tagVariant === TagVariant.BLUE && 'bg-blue-100 text-blue-500',
            tagVariant === TagVariant.GREEN && 'bg-green-100 text-green-500',
            tagVariant === TagVariant.RED && 'bg-red-100 text-red-500',
            tagVariant === TagVariant.GRAY && 'bg-gray-100 text-gray-500',
            tagVariant === TagVariant.WHITE && 'bg-white text-gray-800',
          )}
        >
          {!hideCircle && (
            <svg
              className={twMerge(
                'h-1.5 w-1.5',
                tagVariant === TagVariant.BLUE && 'fill-blue-500',
                tagVariant === TagVariant.GREEN && 'fill-green-500',
                tagVariant === TagVariant.RED && 'fill-red-500',
                tagVariant === TagVariant.WHITE && 'hidden',
              )}
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
          )}
          {tagTitle}
        </span>
      )}
    </div>
  </Tooltip>
);

export { Tag };

import { twMerge } from 'tailwind-merge';
import { isUserActivated } from 'helpers/user';
import { User } from '../../models/User';

interface Props {
  user: User;
}

const getRandomCircleColor = () => {
  const colors = [
    'text-primary-600 ring-primary-100 bg-primary-50',
    'text-orange-600 ring-orange-100 bg-orange-50',
    'text-red-600 ring-red-100 bg-red-50',
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

const TeamMemberIcon = ({ user }: Props) => (
  <div
    className={twMerge(
      'relative flex size-20 items-center justify-center rounded-full text-2xl ring-1',
      getRandomCircleColor(),
    )}
  >
    {user?.firstName?.substring(0, 1)}
    <div
      className={twMerge(
        'absolute bottom-0.5 left-16 flex size-2.5 -translate-y-1/2 transform rounded-full bg-success',
        !isUserActivated(user) ? 'bg-neutral-100' : '',
      )}
    />
  </div>
);

export default TeamMemberIcon;

import { memo, useMemo, ReactNode } from 'react';
import { Accordion, Tooltip } from '@mantine/core';

import { CheckIcon, ExclamationTriangleIcon, PlayIcon } from '@heroicons/react/24/outline';

interface Props {
  hideTooltip: boolean;
  isProductConfirmed: boolean;
  productName: string;
  TooltipLabel: React.ReactNode;
  isRecordingAvailable: boolean;
  onPlayButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const IconWithTooltip = memo(({
  hideTooltip,
  isConfirmed,
  TooltipLabel,
}: {
  hideTooltip: boolean,
  isConfirmed: boolean,
  TooltipLabel: ReactNode,
}) => {
  const Icon = useMemo(() => {
    if (!hideTooltip) {
      return <ExclamationTriangleIcon className="w-5 h-5 text-warning-500" />;
    }

    if (isConfirmed) {
      return <CheckIcon className="w-5 h-5 text-success-700" />;
    }

    return null;
  }, [hideTooltip, isConfirmed]);
  return (
    <Tooltip
      label={TooltipLabel}
      hidden={hideTooltip}
    >
      <div>
        {Icon}
      </div>
    </Tooltip>
  );
});

const Control = memo(({
  hideTooltip,
  isProductConfirmed,
  TooltipLabel,
  isRecordingAvailable,
  onPlayButtonClick,
  productName,
}: Props) => (
  <Accordion.Control
    icon={(
      <IconWithTooltip
        hideTooltip={hideTooltip}
        isConfirmed={isProductConfirmed}
        TooltipLabel={TooltipLabel}
      />
  )}
    style={{ backgroundColor: 'white' }}
    className="hover:bg-green-500"
  >
    <div className="flex space-x-2">
      <p className={isProductConfirmed ? 'text-success' : ''}>
        {productName}
      </p>
      {isRecordingAvailable && (
        <Tooltip
          label="Play audio"
          transitionProps={{ transition: 'pop', duration: 200 }}
          bg="white"
          styles={{
            tooltip: {
              color: 'black',
              border: '1.5px solid #E8E8E8',
              boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            },
          }}
        >
          <button
            type="button"
            className="rounded-full border border-light-gray-100 bg-white p-1"
            onClick={onPlayButtonClick}
          >
            <PlayIcon className="aspect-square w-4" />
          </button>
        </Tooltip>
      )}
    </div>
  </Accordion.Control>
));

export default Control;

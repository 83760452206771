import { createRoot } from 'react-dom/client';

import './style.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';

import { App } from './App';

const root = createRoot(document.getElementById('root'));
root.render(<App />);

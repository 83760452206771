import { ReactElement } from 'react';

interface Props {
  steps: (() => ReactElement)[];
  currentStep: number;
}

const WizardContent = ({ steps, currentStep }: Props) => steps[currentStep]();

export { WizardContent };

import { PlusIcon } from '@heroicons/react/20/solid';
import { useGoogleLogin } from '@react-oauth/google';
import { HoverCard } from '@mantine/core';
import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { genericErrorFeedback } from 'helpers/errors';
import { decimalCommaToValue } from 'helpers/basicSettings';
import { Card } from 'components/common/Card';
import { globalUser } from '../../../../state/globalUser';
import { globalAlertData } from '../../../../state/globalAlertData';

import {
  OAuthProvider,
  OAuthFlow,
  OAuthAppState,
  OAuthBinding,
} from '../../../../types/oauth';

import { Button } from '../../../ui/Button';

import { BusinessSettings } from '../../../../models/BusinessSettings';

import outlook from '../../../../assets/logo/outlook.svg';
import google from '../../../../assets/logo/google.svg';
import standardEmail from '../../../../assets/email.svg';

import SubsectionTitle from '../ui/SubsectionTitle';
import TimeFrame from '../ui/TimeFrame/TimeFrame';
import Select from '../ui/Select/Select';

const getEmailIcon = (email: string) => {
  if (email.includes('gmail.com')) return google;
  if (email.includes('outlook.com')) return outlook;
  return standardEmail;
};

const getOAuthProviderIcon = (provider: OAuthProvider) => {
  switch (provider) {
    case OAuthProvider.GOOGLE:
      return google;
    case OAuthProvider.MICROSOFT:
      return outlook;
    default:
      return standardEmail;
  }
};

interface Props {
  businessSettings: BusinessSettings;
  onBusinessTimesTimeChange: (index: number, from: string, to: string) => void;
  onRemoveBusinessTimesIntervalButtonClick: (i: number) => void;
  onAddBusinessTimesIntervalButtonClick: () => void;
  onDefaultCurrencyChange: (value: string) => void;
  onDecimalCommaChange: (value: string) => void;
  onSaveButtonClick: () => void;
  isLoading: boolean;
}

const BasicSettings = ({
  businessSettings,
  onBusinessTimesTimeChange,
  onRemoveBusinessTimesIntervalButtonClick,
  onAddBusinessTimesIntervalButtonClick,
  onDefaultCurrencyChange,
  onDecimalCommaChange,
  onSaveButtonClick,
  isLoading,
}: Props) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const flow = searchParams.get('flow') as OAuthFlow;
    const binding = JSON.parse(
      atob(searchParams.get('binding') ?? '') || '{}',
    ) as OAuthBinding;

    let needsRefresh = false;
    switch (flow) {
      case OAuthFlow.BUSINESS_EMAIL_CONNECTION:
        if (binding.email) {
          globalAlertData.create(
            `Email ${binding.email} connected successfully`,
          );
        }
        needsRefresh = true;
        break;
      default:
        return;
    }

    searchParams.delete('flow');
    searchParams.delete('binding');
    searchParams.delete('user');
    window.history.replaceState({}, '', `?${searchParams.toString()}`);

    if (needsRefresh) {
      globalUser
        .refresh()
        .catch((err) => genericErrorFeedback(err.error_description));
    }
  }, [searchParams]);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const appState: OAuthAppState = {
        origin_uri: window.location.href,
        flow: OAuthFlow.BUSINESS_EMAIL_CONNECTION,
      };
      const appStateB64 = btoa(JSON.stringify(appState));

      const redirectSearchParams = new URLSearchParams();
      redirectSearchParams.append('code', codeResponse.code);
      redirectSearchParams.append('app_state', appStateB64);

      // Redirect to the backend to start the OAuth flow
      window.location.href = `/api/v1/auth/google?${redirectSearchParams.toString()}`;
    },
    onError: (err) => genericErrorFeedback(err.error_description),
    flow: 'auth-code',
    select_account: true,
  });

  return (
    <Card id="basic_settings" className="">
      <Card.Header>Basic settings</Card.Header>
      <Card.Body className="text-sm text-gray-700">
        {/* Business time */}
        {businessSettings?.businessTimes?.map((bt, i) => (
          <span key={`bt-${i.toString()}`} className="text-sm text-gray-700">
            <TimeFrame
              label={i === 0 ? 'Business times' : ''}
              from={bt.from}
              to={bt.to}
              onChange={(from, to) => onBusinessTimesTimeChange(i, from, to)}
              isLoading={isLoading}
              onRemove={() => onRemoveBusinessTimesIntervalButtonClick(i)}
            />
          </span>
        ))}
        {!businessSettings?.businessTimes
        || businessSettings?.businessTimes?.length < 21 ? (
          <div className="flex justify-end">
            <Button
              icon={<PlusIcon className="h-4 w-4" />}
              // title="Add"
              onClick={onAddBusinessTimesIntervalButtonClick}
              theme="secondary"
              variant="small"
              disabled={isLoading}
            />
          </div>
          ) : null}
        <Select
          label="Default currency"
          value={businessSettings?.defaultCurrency}
          values={['CHF', 'EUR']}
          readOnly={false}
          disabled={isLoading}
          onChange={onDefaultCurrencyChange}
        />

        <Select
          label="Decimal separator"
          value={decimalCommaToValue(businessSettings?.decimalComma)}
          values={['Dot (0.1)', 'Comma (0,1)']}
          readOnly={false}
          disabled={isLoading}
          fitWidth
          onChange={onDecimalCommaChange}
        />

        <SubsectionTitle
          title="Connected emails"
          subtitle="Adam is connected to the following mails."
        />
        <div className="flex flex-col gap-4">
          {globalUser.business?.emails?.map((email, i) => {
            const oauthBindings = Array.from(new Map(Object.entries(globalUser.business?.oauthBindings ?? {})).values());
            const oauthBinding = Array.from(oauthBindings.values()).find(
              (b) => b.email === email,
            );
            return (
              <div key={`email-${i.toString()}`} className="flex space-x-4">
                <img
                  className="relative h-5 w-5"
                  src={
                    oauthBinding
                      ? getOAuthProviderIcon(oauthBinding.provider)
                      : getEmailIcon(email)
                  }
                  alt=""
                />
                <div className={`text-sm ${oauthBinding ? 'font-bold' : ''}`}>
                  {email}
                </div>
              </div>
            );
          })}

          <div className="justify-start">
            <HoverCard
              shadow="md"
              position="bottom"
              openDelay={10}
              transitionProps={{ transition: 'scale-y', duration: 100 }}
            >
              <HoverCard.Target>
                <Button
                  theme="secondary"
                  variant="small"
                  icon={<PlusIcon className="h-4 w-4" />}
                  title="Connect email"
                  onClick={() => true}
                />
              </HoverCard.Target>
              <HoverCard.Dropdown p={0}>
                <Button
                  title="Gmail"
                  icon={(
                    <img
                      className="relative h-5 w-5"
                      src={getOAuthProviderIcon(OAuthProvider.GOOGLE)}
                      alt=""
                    />
                  )}
                  theme="secondary"
                  radius="sm"
                  className="w-full text-center font-semibold hover:bg-light-gray-50"
                  onClick={googleLogin}
                />
                <Button
                  title="Outlook"
                  icon={(
                    <img
                      className="relative h-5 w-5"
                      src={getOAuthProviderIcon(OAuthProvider.MICROSOFT)}
                      alt=""
                    />
                  )}
                  theme="secondary"
                  radius="sm"
                  className="w-full text-center font-semibold hover:bg-light-gray-50"
                  disabled
                  onClick={() => false}
                />
              </HoverCard.Dropdown>
            </HoverCard>
          </div>
        </div>

        <div className="flex justify-end">
          <Button
            title="Save"
            onClick={() => onSaveButtonClick()}
            disabled={isLoading}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default BasicSettings;

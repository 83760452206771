import {
  memo,
  useCallback, useMemo,
} from 'react';
import {
  Accordion, Textarea, Tooltip,
} from '@mantine/core';

import { ProductSelect } from 'features/order/components/process-order-draft/form-elemts/ProductSelect';

import { UniversalField } from 'components/common/UniversalField';
import {
  getFieldByPath, inputTypeToUniversalFieldType,
} from 'helpers/schema';
import { Button } from 'components/ui/Button';
import { StandardField } from 'components/common/StandardField';
import { Order, ProductWithQuantity } from 'features/order/models/Order';
import { FieldSpec, Schema } from 'models/Schema';
import { Product } from 'models/Product';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { ProductSortingColumn } from 'types/product';

interface Props {
  index: number;
  product: ProductWithQuantity;
  fieldSpecs: FieldSpec[];
  schema: Schema;
  sortingColumn: ProductSortingColumn;
  filterEnabledProducts: boolean;
  customerId: string;
  setAccordionValue: React.Dispatch<React.SetStateAction<string[]>>;
  setError: (key: string, error: string) => void;
  getProductFieldValue: (index: number, modelPath: string) => any;
  setGroupOrders: React.Dispatch<React.SetStateAction<Order[]>>;
  selectedIndex: number;
  hideTooltip: boolean;
  TooltipLabel: React.ReactNode;
  isProductConfirmed: boolean;
}

const FieldItem = memo(({
  fieldSpec,
  schema,
  setError: _setError,
  setProductError,
  product,
  index,
  getProductFieldValue,
}: {
  fieldSpec: FieldSpec;
  schema: Schema;
  setError: (key: string, error: string) => void;
  setProductError: (key: string, error: string) => void;
  product: ProductWithQuantity;
  index: number;
  getProductFieldValue: (index: number, modelPath: string) => any;
}) => {
  const { updateValueByPath } = useProcessOrderContext();

  const field = useMemo(() => getFieldByPath(schema?.fields, fieldSpec.path), [schema?.fields, fieldSpec.path]);

  const value = useMemo(() => getProductFieldValue(
    index, fieldSpec.modelPath), [index, fieldSpec.modelPath, getProductFieldValue]);

  const itemStyle = useMemo(() => ({
    minWidth: `${fieldSpec.name?.length}ch`,
    maxWidth: '300px',
    flexGrow: 1,
  }), [fieldSpec.name]);

  const type = useMemo(() => inputTypeToUniversalFieldType(field?.inputType, field?.type), [field?.inputType, field?.type]);

  const onValueChange = useCallback((_value: any) => updateValueByPath?.(
    _value, fieldSpec.modelPath, [index]), [index, updateValueByPath, fieldSpec.modelPath]);

  const onUnitChange = useCallback((ind: number, _value: any) => updateValueByPath?.(
    _value, fieldSpec.modelPath, [index]), [index, updateValueByPath, fieldSpec.modelPath]);

  const props = useMemo(() => ({
    index,
    productWithQuantity: product,
    onUnitChange,

    key: fieldSpec.path,
    type,
    label: fieldSpec.name,
    value,
    size: 'xs' as 'xs',
    shouldScroll: false,
    itemStyle,
    onValueChange,
  }), [fieldSpec.name, fieldSpec.path, index, onUnitChange, onValueChange, product, type, value, itemStyle]);

  const setError = useCallback((error: string) => {
    _setError?.(`${product.uiId}-${fieldSpec.path}`, error);
    setProductError?.(`${product.uiId}-${fieldSpec.path}`, error);
  }, [fieldSpec.path, _setError, setProductError, product.uiId]);

  const setStandardFieldError = useCallback((key: string, error: string) => {
    _setError?.(`${product.uiId}-${fieldSpec.path}-${key}`, error);
  }, [_setError, product.uiId, fieldSpec.path]);

  return (
    <div
      id={`content-${fieldSpec.uiId}`}
      className="flex py-2 px-1 items-end flex-1"
    >
      {field?.isStandard
        ? (
          <StandardField
            fieldSchema={getFieldByPath(schema?.fields, fieldSpec.path)}
            setError={setStandardFieldError}
            setProductError={setProductError}
            props={props}
          />
        )
        : (
          <UniversalField
            key={fieldSpec.path}
            type={type}
            label={fieldSpec.name}
            value={value}
            validation={fieldSpec.validation}
            size="xs"
            shouldScroll={false}
            itemStyle={itemStyle}
            onValueChange={onValueChange}
            setError={setError}
          />
        )}
    </div>
  );
});

const CommentField = memo(({
  onTextAreaChange,
}: {
  onTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => (
  <Textarea
    placeholder="Add some comment if needed..."
    label="Comment"
    minRows={2}
    maxRows={4}
    autosize
    autoComplete="off"
    className="pt-4"
    onChange={onTextAreaChange}
  />
));

const Buttons = memo(({
  onRemoveProductButtonClick,
  onMarkAsCheckedButtonClick,
  hideTooltip,
  TooltipLabel,
  isProductConfirmed,
}: {
  onRemoveProductButtonClick: () => void;
  onMarkAsCheckedButtonClick: () => void;
  hideTooltip: boolean;
  TooltipLabel: React.ReactNode;
  isProductConfirmed: boolean;
}) => (
  <div className="pt-4 flex justify-end">
    <div className="flex space-x-2">
      <Button
        title="Remove"
        theme="red"
        onClick={onRemoveProductButtonClick}
      />
      <Tooltip
        label={TooltipLabel}
        hidden={hideTooltip}
      >
        <Button
          title={isProductConfirmed ? 'Mark as unchecked' : 'Mark as checked'}
          onClick={onMarkAsCheckedButtonClick}
          disabled={!hideTooltip}
        />
      </Tooltip>
    </div>
  </div>
));

const Panel = ({
  index, product, fieldSpecs, schema, sortingColumn, filterEnabledProducts, customerId,
  setAccordionValue, setError,
  getProductFieldValue, setGroupOrders, selectedIndex, hideTooltip, TooltipLabel, isProductConfirmed,
}: Props) => {
  const { updateProductByIndex, removeProductByUiIdorPositionId } = useProcessOrderContext();

  const onProductChange = useCallback((ind: number, p: Product) => updateProductByIndex(index, {
    updatedName: p.name,
    id: p.id,
    score: p.score,
    product: p,
  }), [index, updateProductByIndex]);

  const onRemoveProductButtonClick = useCallback(() => {
    removeProductByUiIdorPositionId(product?.positionId || product?.uiId);
  }, [product?.positionId, product?.uiId, removeProductByUiIdorPositionId]);

  const onMarkAsCheckedButtonClick = useCallback(() => {
    setGroupOrders((groupOrders) => {
      const _groupOrders = [...groupOrders];

      const confirmed = _groupOrders[selectedIndex].products[index].confirmed;

      if (!confirmed) {
        setAccordionValue((prev) => (!prev.includes(
          product.uiId) ? [...prev, product.uiId] : prev.filter((id) => id !== product.uiId)));
      }

      _groupOrders[selectedIndex].products[index].confirmed = !confirmed;
      return _groupOrders.map((prevGroupOrder, i) => (i === selectedIndex
        ? {
          ...prevGroupOrder,
          products: (prevGroupOrder.products || []).map((prevProduct: ProductWithQuantity) => ({ ...prevProduct })),
        } : prevGroupOrder));
    });
  }, [index, product.uiId, selectedIndex, setAccordionValue, setGroupOrders]);

  const setProductError = useCallback((key: string, error: string) => {
    setGroupOrders((groupOrders) => {
      const _groupOrders = [...groupOrders];

      if (!_groupOrders[selectedIndex].products[index]) return _groupOrders;

      _groupOrders[selectedIndex].products[index].errors = {
        ..._groupOrders[selectedIndex].products[index]?.errors,
        [key]: error,
      };
      return _groupOrders.map((prevGroupOrder, i) => (i === selectedIndex
        ? {
          ...prevGroupOrder,
          products: (prevGroupOrder.products || []).map((prevProduct: ProductWithQuantity) => ({ ...prevProduct })),
        } : prevGroupOrder));
    });
  }, [index, selectedIndex, setGroupOrders]);

  const onTextAreaChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => updateProductByIndex?.(index, {
    comment: event.target.value,
  }), [index, updateProductByIndex]);

  return (
    <Accordion.Panel
      style={{ backgroundColor: 'white' }}
    >
      <div
        className="flex flex-col gap-sm items-stretch w-full"
      >
        <div key={`preview-${product.uiId}`} className="flex flex-wrap gap-y-sm w-full">
          <ProductSelect
            label="Product"
            businessId={customerId}
            productWithQuantity={product}
            sortingColumn={sortingColumn}
            filterEnabledProducts={filterEnabledProducts}
            index={index}
            onProductChange={onProductChange}
          />
          {fieldSpecs.filter((fieldSpec) => fieldSpec.modelPath !== 'products.*.product.name').map((fieldSpec) => (
            <FieldItem
              key={`preview-${fieldSpec.uiId}`}
              fieldSpec={fieldSpec}
              schema={schema}
              setError={setError}
              setProductError={setProductError}
              product={product}
              index={index}
              getProductFieldValue={getProductFieldValue}
            />
          ))}
        </div>
      </div>
      <CommentField
        onTextAreaChange={onTextAreaChange}
      />
      <Buttons
        onRemoveProductButtonClick={onRemoveProductButtonClick}
        onMarkAsCheckedButtonClick={onMarkAsCheckedButtonClick}
        hideTooltip={hideTooltip}
        TooltipLabel={TooltipLabel}
        isProductConfirmed={isProductConfirmed}
      />
    </Accordion.Panel>
  );
};

export default Panel;

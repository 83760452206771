/* eslint-disable react/no-children-prop */
import {
  useMemo, useState, useRef, useEffect,
  createElement,
  ReactNode,
} from 'react';

  type Props = {
    /**
     * Whether the element should be visible initially or not.
     * Useful e.g. for always setting the first N items to visible.
     * Default: false
     */
    initialVisible?: boolean
    /** An estimate of the element's height */
    defaultHeight?: number
    /** How far outside the viewport in pixels should elements be considered visible?  */
    visibleOffset?: number
    /** Should the element stay rendered after it becomes visible? */
    stayRendered?: boolean
    root?: HTMLElement | null
    /** E.g. 'span', 'tbody'. Default = 'div' */
    rootElement?: string
    rootElementClass?: string
    /** E.g. 'span', 'tr'. Default = 'div' */
    placeholderElement?: ReactNode
    children: React.ReactNode
  };

const RenderIfVisible = ({
  initialVisible = false,
  defaultHeight = 300,
  visibleOffset = 1000,
  stayRendered = false,
  root = null,
  rootElement = 'div',
  rootElementClass = '',
  placeholderElement = null,
  children,
}: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(initialVisible);
  const wasVisible = useRef<boolean>(initialVisible);
  const placeholderHeight = useRef<number>(defaultHeight);
  const intersectionRef = useRef<HTMLDivElement>(null);

  // Set visibility with intersection observer
  useEffect(() => {
    if (intersectionRef.current) {
      const localRef = intersectionRef.current;
      const observer = new IntersectionObserver(
        (entries) => {
          // Before switching off `isVisible`, set the height of the placeholder
          if (!entries[0].isIntersecting) {
            placeholderHeight.current = localRef!.offsetHeight;
          }
          if (typeof window !== undefined && window.requestIdleCallback) {
            window.requestIdleCallback(
              () => {
                setTimeout(() => {
                  if (entries[0].isIntersecting) {
                    setIsVisible(true);
                  } else {
                    setIsVisible(false);
                  }
                }, 100);
              },
              {
                timeout: 10,
              },
            );
          } else {
            setTimeout(() => {
              if (entries[0].isIntersecting) {
                setIsVisible(true);
              } else {
                setIsVisible(false);
              }
            }, 100); // 500ms delay
          }
        },
        { root, rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px` },
      );

      observer.observe(localRef);
      return () => {
        if (localRef) {
          observer.unobserve(localRef);
        }
      };
    }
    return () => {};
  }, [root, visibleOffset]);

  useEffect(() => {
    if (isVisible) {
      wasVisible.current = true;
    }
  }, [isVisible]);

  const rootClasses = useMemo(
    () => `renderIfVisible ${rootElementClass}`,
    [rootElementClass],
  );

  return createElement(rootElement, {
    children: isVisible || (stayRendered && wasVisible.current) ? (
      children
    ) : (
      placeholderElement
    ),
    ref: intersectionRef,
    className: rootClasses,
  });
};

export default RenderIfVisible;

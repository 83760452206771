import {
  memo, useCallback, useEffect, useMemo,
  useRef,
} from 'react';
import { Tooltip } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import Dayjs from 'dayjs';
import { isBefore, max, startOfToday } from 'date-fns';

import { isZeroTime } from 'helpers/dateTime';
import stars from 'assets/stars-svgrepo-com.svg';

import { RequestedDeliveryTimePickerProps } from './types';

const RequestedDeliveryTimePicker = memo(({
  requestedDeliveryTime,
  autoMatchedRequestedDeliveryTime,
  onDeliveryDateTimeChange,
  setError,
}: RequestedDeliveryTimePickerProps) => {
  const openRef = useRef<boolean>(false);

  const requestedDeliveryDatetime: Date | null = useMemo(() => {
    if (requestedDeliveryTime) return Dayjs(requestedDeliveryTime).toDate();

    return null;
  }, [requestedDeliveryTime]);

  const datetimeError: string = useMemo(() => {
    if (isZeroTime(requestedDeliveryTime)) return 'The datetime was not matched';

    if (
      !requestedDeliveryTime
      || (requestedDeliveryDatetime
          && isBefore(requestedDeliveryDatetime, startOfToday()))
    ) return 'Select a valid delivery date';

    return '';
  }, [requestedDeliveryTime, requestedDeliveryDatetime]);

  const isRequestedDeliveryTimeAutoMatched = useMemo(() => {
    if (
      !requestedDeliveryTime
          || !autoMatchedRequestedDeliveryTime
    ) return false;

    return (
      requestedDeliveryTime === autoMatchedRequestedDeliveryTime
    );
  }, [requestedDeliveryTime, autoMatchedRequestedDeliveryTime]);

  const showAutoMatched = useMemo(
    () => isRequestedDeliveryTimeAutoMatched && datetimeError === '',
    [isRequestedDeliveryTimeAutoMatched, datetimeError],
  );

  const minDate = useMemo(() => startOfToday(), []);

  const rightSection = useMemo(() => {
    if (showAutoMatched) {
      return (
        <img
          className="relative h-[25.4px] w-[20px]"
          src={stars}
          alt=""
          style={{
            filter: 'invert(54%) sepia(62%) saturate(6540%) hue-rotate(237deg) brightness(99%) contrast(94%)',
          }}
        />
      );
    }

    return null;
  }, [showAutoMatched]);

  const styles = useMemo(() => ({
    input: {
      color: showAutoMatched ? '#8b5cf6' : '',
    },
  }), [showAutoMatched]);

  const onDeliveryDateTimeClick = useCallback(() => {
    if (openRef.current) {
      openRef.current = false;
      return;
    }

    openRef.current = true;
    if (!requestedDeliveryDatetime) {
      onDeliveryDateTimeChange(Dayjs(startOfToday()).toDate());
      return;
    }

    const maxDate = Dayjs(
      max([requestedDeliveryDatetime, startOfToday()]),
    ).toDate();

    if (maxDate.getTime() !== requestedDeliveryDatetime.getTime()) {
      onDeliveryDateTimeChange(maxDate);
    }
  }, [onDeliveryDateTimeChange, requestedDeliveryDatetime]);

  useEffect(() => {
    setError(datetimeError);
  }, [datetimeError, setError]);

  return (
    <Tooltip
      disabled={!showAutoMatched}
      label="Adam has matched the delivery time for this order"
      bg="#a78bfa"
      withArrow
      position="top-start"
    >
      <DateTimePicker
        value={requestedDeliveryDatetime}
        onChange={onDeliveryDateTimeChange}
        onClick={onDeliveryDateTimeClick}
        label="Delivery date and time"
        minDate={minDate}
        error={datetimeError}
        rightSection={rightSection}
        styles={styles}
      />
    </Tooltip>
  );
});

export default RequestedDeliveryTimePicker;

import { useParams } from 'react-router-dom';
import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { ProcessOrderDraftByIdPage } from 'features/order/pages';

const ProcessOrderDraftByIdScreen = () => {
  const { orderId } = useParams();

  return (
    <ProtectedScreen>
      <ProcessOrderDraftByIdPage orderId={orderId} />
    </ProtectedScreen>
  );
};

export default ProcessOrderDraftByIdScreen;

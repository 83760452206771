import {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { BusinessSettings } from '../models/BusinessSettings';

type BusinessSettingsContextType = {
  businessSettings: BusinessSettings;
  setBusinessSettings: React.Dispatch<
  React.SetStateAction<BusinessSettings>
  >;
};

const BusinessSettingsContext = createContext<
BusinessSettingsContextType | undefined
>(undefined);

interface BusinessSettingsProviderProps {
  children: React.ReactNode;
  businessSettings?: BusinessSettings;
}

const BusinessSettingsProvider: React.FC<BusinessSettingsProviderProps> = ({
  children,
  businessSettings: _businessSettings,
}: BusinessSettingsProviderProps) => {
  const [businessSettings, setBusinessSettings] = useState<BusinessSettings>(_businessSettings);

  const contextValue = useMemo(
    () => ({
      businessSettings,
      setBusinessSettings,
    }),
    [businessSettings],
  );

  return (
    <BusinessSettingsContext.Provider value={contextValue}>
      {children}
    </BusinessSettingsContext.Provider>
  );
};

const useBusinessSettingsContext = () => {
  const context = useContext(BusinessSettingsContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by BusinessSettingsContext provider.');
  }

  return context;
};

export {
  BusinessSettingsProvider,
  useBusinessSettingsContext,
};

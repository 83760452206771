interface Props {
  isLoading: boolean;
}

const LoadingIndicator = ({ isLoading }: Props) => {
  if (!isLoading) return null;

  return (
    <div className="relative h-5 w-5">
      <div className="absolute h-5 w-5 rounded-full border-4 border-solid border-gray-200" />
      <div className="absolute h-5 w-5 animate-spin rounded-full border-4 border-solid border-hoshii border-t-transparent" />
    </div>
  );
};

export { LoadingIndicator };

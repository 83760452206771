interface Props {
  title: string;
  subtitle?: string;
}

const SubsectionTitle = ({ title, subtitle }: Props) => (
  <div className="mb-1 flex flex-col gap-0.5">
    <div className="text-sm font-semibold text-gray-900">{title}</div>
    {subtitle && (
      <div className="text-xs font-medium text-gray-700">{subtitle}</div>
    )}
  </div>
);

export default SubsectionTitle;

import { useState, useCallback } from 'react';

import { genericErrorFeedback } from 'helpers/errors';
import { globalAlertData } from 'state/globalAlertData';

import { useCreateOrderDrafts } from './useCreateOrderDrafts';
import { useSaveOrderDrafts } from './useSaveOrderDrafts';
import { useDeleteOrderDrafts } from './useDeleteOrderDraft';

import { Order } from '../models/Order';

const useUpdateOrderDrafts = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { createAndGroupOrderDrafts } = useCreateOrderDrafts();
  const { saveOrderDrafts } = useSaveOrderDrafts();
  const { deleteOrderDrafts } = useDeleteOrderDrafts();

  const updateOrderDrafts = useCallback(
    async (
      orderDrafts: Order[],
      deletedOrderIds: string[],
      messageId?: string,
      createAlertData: boolean = true,
      giveErrorFeedback: boolean = true,
    ): Promise<Order[]> => {
      if (isLoading) {
        return Promise.reject(
          new Error('Order drafts are already being processed'),
        );
      }

      setIsLoading(true);

      // Delete to delete drafts
      if (deleteOrderDrafts.length > 0) {
        try {
          await deleteOrderDrafts(deletedOrderIds, false, false);
        } catch (error) {
          if (giveErrorFeedback) {
            genericErrorFeedback('Delete order failed')(error);
          }
          setIsLoading(false);
          return Promise.reject(error);
        }
      }

      // First separate the orderDrafts into two arrays: one for created orders and one for changed orders
      const { createdOrders, changedOrders } = orderDrafts.reduce(
        (acc, orderDraft) => {
          if (orderDraft.isCreated) {
            acc.createdOrders.push(orderDraft);
          } else if (orderDraft.didChangeMade && !orderDraft.isCreated) {
            acc.changedOrders.push(orderDraft);
          }
          return acc;
        },
        { createdOrders: [], changedOrders: [] },
      );

      let groupId: string = '';
      let _orderDrafts = orderDrafts.filter(
        (order) => !createdOrders.includes(order),
      );

      try {
        // Check if there are created orders, if yes, create them and group them
        if (createdOrders.length > 0) {
          const orders = (
            await createAndGroupOrderDrafts(
              createdOrders,
              // map all ids that are not present in createdOrders
              _orderDrafts.map((order) => order.id),
              messageId,
              false,
              false,
            )
          ).map((order) => ({
            ...order,
            isGroupSibling: true,
          }));

          // set the new order group id
          groupId = orders[0].groupId;
          _orderDrafts = [..._orderDrafts, ...orders];
        }

        // If there are changed orders, save them
        if (changedOrders.length > 0) {
          await saveOrderDrafts(
            changedOrders,
            createAlertData,
            giveErrorFeedback,
          );
        }

        if (createAlertData) {
          globalAlertData.create('Orders updated successfully');
        }
        return await Promise.resolve(
          _orderDrafts.map((_order) => ({
            ..._order,
            groupId: groupId || _order.groupId,
          })),
        );
      } catch (error) {
        if (giveErrorFeedback) {
          genericErrorFeedback('Save order failed')(error);
        }
        return await Promise.reject(error);
      } finally {
        setIsLoading(false);
      }
    },
    [createAndGroupOrderDrafts, deleteOrderDrafts, isLoading, saveOrderDrafts],
  );

  return { isLoading, updateOrderDrafts };
};

export { useUpdateOrderDrafts };

import { useMemo } from 'react';
import { DateTimePicker } from '@mantine/dates';
import Dayjs from 'dayjs';

import { TextWithLabel } from 'components/ui/TextWithLabel';
import { isZeroTime } from 'helpers/dateTime';
import { Order } from '../../models/Order';

interface Props {
  order: Order;
}

const Details = ({ order }: Props) => {
  const requestedDeliveryDatetime: Date | null = useMemo(() => {
    if (order?.requestedDeliveryTime) return Dayjs(order?.requestedDeliveryTime).toDate();

    return null;
  }, [order?.requestedDeliveryTime]);

  const createdDatetime: Date | null = useMemo(() => {
    if (order?.createdAt) return Dayjs(order?.createdAt).toDate();

    return null;
  }, [order?.createdAt]);

  const lastErpSynchDatetime: Date | null = useMemo(() => {
    if (order?.lastErpSyncAt && !isZeroTime(order?.lastErpSyncAt)) return Dayjs(order?.lastErpSyncAt).toDate();

    return null;
  }, [order?.lastErpSyncAt]);

  if (!order) return null;
  return (
    <div className="grid grid-cols-2 gap-sm">
      <DateTimePicker
        label="Placed at"
        value={createdDatetime}
        readOnly
        variant="unstyled"
        classNames={{
          label: 'text-gray-400',
          input: 'text-gray-900',
        }}
      />
      <DateTimePicker
        label="Requested delivery time"
        value={requestedDeliveryDatetime}
        variant="unstyled"
        readOnly
        classNames={{
          label: 'text-gray-400',
          input: 'text-gray-900',
        }}
      />
      <TextWithLabel
        label="Order ID in Hoshii"
        value={order?.id}
      />
      <TextWithLabel
        label="Order ID in your ERP"
        value={order?.externalId}
        className={!order?.externalId && 'invisible'}
      />
      <TextWithLabel
        label="Comment"
        value={order?.draft.comment || '-'}
      />
      <DateTimePicker
        label="Last ERP synchronization at"
        value={lastErpSynchDatetime}
        variant="unstyled"
        readOnly
        className={`col-span-2 ${!lastErpSynchDatetime && 'hidden'}`}
        classNames={{
          label: 'text-gray-400',
          input: 'text-gray-900',
        }}
      />
    </div>
  );
};

export default Details;

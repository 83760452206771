import { InputWrapper } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { TrashIcon } from '@heroicons/react/24/outline';
import LabelWrapper from '../LabelWrapper';

interface Props {
  label: string;
  from: string;
  to: string;
  onChange: (from: string, to: string) => void;
  isLoading: boolean;

  onRemove?: () => void;
}

const TimeFrame = ({
  label,
  from,
  to,
  onChange,
  isLoading,
  onRemove,
}: Props) => (
  <LabelWrapper label={label}>
    <div className="flex w-full items-center gap-smd">
      <div className="flex-1">
        <TimeInput
          label="Start"
          value={from || ''}
          onChange={(event) => onChange(event.target.value, to)}
          disabled={isLoading}
        />
      </div>
      <div className="flex-1">
        <TimeInput
          label="End"
          value={to || ''}
          onChange={(event) => onChange(from, event.target.value)}
          disabled={isLoading}
        />
      </div>
      {onRemove && (
        <InputWrapper label=" ">
          <div className="items-center">
            <button type="button" onClick={onRemove} disabled={isLoading}>
              <TrashIcon className="h-5 w-5" />
            </button>
          </div>
        </InputWrapper>
      )}
    </div>
  </LabelWrapper>
);

export default TimeFrame;

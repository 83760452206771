import { useCallback, useMemo, useState } from 'react';

import { Button } from 'components/ui/Button';
import { Page } from 'components/layout/Page/Page';
import { genericErrorFeedback } from 'helpers/errors';
import { httpGetBlob } from 'helpers/xhr';
import { Order } from 'features/order/models/Order';
import { OrderSearchBar, OrderListTable } from 'features/order/components/order-list';
import { useFetchOrders } from 'features/order/api/useFetchOrders';

interface Props {
  navigateToOrder: (orderId: string) => void;
  navigateToProcessDraftById: (orderId: string) => void;
}

const OrderListPage = ({ navigateToOrder, navigateToProcessDraftById }: Props) => {
  const {
    orders,
    isLoading: isLoadingOrders,
    setOrders,
    params,
    setParams,
    resetParams,
    loadOrders,
  } = useFetchOrders({
    initialFetch: true,
    autoLoad: true,
  });
  const [isExportLoading, setIsExportLoading] = useState(false);

  const isLoading = useMemo(
    () => isLoadingOrders || isExportLoading,
    [isLoadingOrders, isExportLoading],
  );

  const [selectedOrders, setSelectedOrders] = useState([]);

  const onOrderOpened = (idx: number, orderId: string, isDraft: boolean) => {
    if (isDraft) {
      navigateToProcessDraftById(orderId);
    } else {
      navigateToOrder(orderId);
    }
  };

  const onOrdersSelectionChange = useCallback((list: Order[]) => {
    setSelectedOrders(list);
  }, []);

  const onExportClick = useCallback(() => {
    if (selectedOrders.length > 0) {
      setIsExportLoading(true);
      httpGetBlob('/orders/export', {
        params: { ids: selectedOrders.map((o) => o.id) },
      })
        .then((response) => {
          const contentDisposition = response.headers['content-disposition'];
          let filename = 'orders.html';
          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="?(.+)?"/);
            if (filenameMatch.length === 2) {
              filename = filenameMatch[1];
            }
          }

          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          URL.revokeObjectURL(url);

          setOrders((os) => os.map((o) => {
            if (selectedOrders.includes(o)) {
              return { ...o, isExported: true };
            }
            return o;
          }),
          );
        })
        .catch(genericErrorFeedback('Error loading orders'))
        .finally(() => {
          setIsExportLoading(false);
        });
    }
  }, [selectedOrders, setOrders]);

  const onOrderTableScrolledToEnd = useCallback(() => {
    if (!isLoadingOrders) {
      loadOrders();
    }
  }, [loadOrders, isLoadingOrders]);

  const onResetFilters = useCallback(() => {
    resetParams();
  }, [resetParams]);

  return (
    <Page>
      <div className="m-4 flex w-[calc(100%-32px)] flex-1 flex-col gap-lg rounded-2xl border border-solid bg-white p-lg">
        <div className="flex justify-between gap-lg">
          <OrderSearchBar
            params={params}
            setParams={setParams}
            onResetFilters={onResetFilters}
          />
          <div className="flex space-x-3">
            <Button
              disabled={isLoading || selectedOrders.length === 0}
              title="Export"
              onClick={onExportClick}
            />
          </div>
        </div>

        <OrderListTable
          isLoading={isLoading}
          orders={orders}
          selectedOrders={selectedOrders}
          onOrderOpened={onOrderOpened}
          onSelectionChange={onOrdersSelectionChange}
          onScrolledToEnd={onOrderTableScrolledToEnd}
        />
      </div>
    </Page>
  );
};

export default OrderListPage;

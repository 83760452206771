import Dayjs from 'dayjs';

import {
  memo, useCallback, useMemo,
} from 'react';
import {
  getFieldByPath, inputTypeToUniversalFieldType, getValueByPath, isListFieldSpec, fieldSpecToModel,
} from 'helpers/schema';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { UniversalField } from 'components/common/UniversalField';
import { StandardField } from 'components/common/StandardField';
import { FieldSpec, Schema } from '../../../models/Schema';
import { CustomerFields, ProductFields } from './custom-fields';
import { Model } from '../../../types/prompt';

interface Props {
  fieldSpecs: FieldSpec[];
  setError?: (key: string, error: string) => void;
  obj: any;
  schema: Schema;
}

const FieldItem = memo(({
  obj, schema, fieldSpec, setError: _setError,
}: {
  obj: any,
  schema: Schema,
  fieldSpec: FieldSpec,
  setError: (key: string, error: string) => void,
}) => {
  const { updateValueByPath } = useProcessOrderContext();

  const field = getFieldByPath(schema?.fields, fieldSpec.path);

  const fieldValue = useMemo(() => (
    getValueByPath(obj, fieldSpec.modelAutoMatchedPath)) || getValueByPath(obj, fieldSpec.modelPath),
  [fieldSpec.modelPath, fieldSpec.modelAutoMatchedPath, obj]);

  const onDeliveryDateTimeChange = useCallback((datetime: Date) => updateValueByPath(
    datetime ? Dayjs(datetime).format() : null, fieldSpec.modelPath), [updateValueByPath, fieldSpec.modelPath]);

  const onValueChange = useCallback(
    (value: string | Date | number) => updateValueByPath(
      value, fieldSpec.modelPath), [updateValueByPath, fieldSpec.modelPath]);

  const setError = useCallback((error: string) => {
    _setError?.(fieldSpec.path, error);
  }, [_setError, fieldSpec.path]);

  const props = useMemo(() => ({
    key: fieldSpec.path,
    type: inputTypeToUniversalFieldType(field?.inputType, field?.type),
    label: fieldSpec.name,
    value: fieldValue,
    shouldScroll: false,
    requestedDeliveryTime: obj.requestedDeliveryTime,
    autoMatchedRequestedDeliveryTime: obj.autoMatchedRequestedDeliveryTime,
    onDeliveryDateTimeChange,
    onValueChange,
  }), [field?.inputType, field?.type, fieldSpec.name,
    fieldSpec.path, fieldValue, obj.requestedDeliveryTime, obj.autoMatchedRequestedDeliveryTime,
    onDeliveryDateTimeChange, onValueChange]);

  return (
    field?.isStandard
      ? (
        <StandardField
          fieldSchema={field}
          setError={_setError}
          props={props}
        />
      )
      : (
        <UniversalField
          type={inputTypeToUniversalFieldType(field?.inputType, field?.type)}
          label={fieldSpec.name}
          value={fieldValue}
          validation={fieldSpec.validation}
          shouldScroll={false}
          onValueChange={onValueChange}
          setError={setError}
        />
      )
  );
});

const GroupedFields = ({
  fieldSpecs,
  setError: _setError,
  obj,
  schema,
}: Props) => {
  const model = fieldSpecToModel(fieldSpecs[0]);

  const setError = useCallback((subKey: string, error: string) => {
    _setError?.(subKey, error);
  }, [_setError]);

  if (isListFieldSpec(fieldSpecs[0])) {
    switch (model) {
      case Model.PRODUCTS:
        return (
          <ProductFields
            fieldSpecs={fieldSpecs}
            setError={setError}
            obj={obj}
            schema={schema}
          />
        );
      default:
        return (
          <div>
            Unsupported model
          </div>
        );
    }
  }

  switch (model) {
    case Model.CUSTOMER:
      return (
        <CustomerFields
          fieldSpecs={fieldSpecs}
          setError={setError}
          obj={obj}
          schema={schema}
        />
      );
    default:
      return (
        <div className="space-y-4">
          {fieldSpecs.map((fieldSpec) => (
            <FieldItem
              key={fieldSpec.path}
              obj={obj}
              schema={schema}
              fieldSpec={fieldSpec}
              setError={setError}
            />
          ))}
        </div>
      );
  }
};

export default GroupedFields;

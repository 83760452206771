import {
  ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { Message } from '../models/Message';
import { MessagesByChatIdFilter, useFetchMessagesByChatId } from '../hooks/fetch/useFetchMessagesByChatId';
import { useFetchMessagesByReferenceId } from '../hooks/fetch/useFetchMessagesByReferenceId';
import { useFetchMessageById } from '../hooks/fetch/useFetchMessageById';
import { MessagesFilter, useFetchMessages } from '../hooks/fetch/useFetchMessages';

enum MessageProviderType {
  PLAIN = 'plain',
  BY_CHAT_ID = 'chat id',
  BY_REFERENCE_ID = 'reference id',
  BY_MESSAGE_ID = 'message id',
}

type MessagesContextType = {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  selectedMessageId: string;
  setSelectedMessageId: React.Dispatch<React.SetStateAction<string>>;
  loadMessages: (reset?: boolean, giveError?: boolean) => Promise<void>;
  isLoading: boolean;

  messagesFilter?: MessagesByChatIdFilter | MessagesFilter;
  setMessagesFilter?: React.Dispatch<React.SetStateAction<MessagesByChatIdFilter | MessagesFilter>>;
};

const MessagesContext = createContext<MessagesContextType | undefined>(
  undefined,
);

// Providers
type MessagesProviderByReferenceIdProps = {
  children: ReactNode;
  referenceId: string;
  preventInitialFetch: boolean;
};

const MessagesProviderByReferenceId: React.FC<
MessagesProviderByReferenceIdProps
> = ({
  children,
  referenceId,
  preventInitialFetch = false,
}: MessagesProviderByReferenceIdProps) => {
  const {
    messages, setMessages, isLoading, loadMessages,
  } = useFetchMessagesByReferenceId({ referenceId, preventInitialFetch });

  const [selectedMessageId, setSelectedMessageId] = useState<string>();

  const contextValue = useMemo(
    () => ({
      messages,
      setMessages,
      selectedMessageId,
      setSelectedMessageId,
      loadMessages,
      isLoading,
    }),
    [isLoading, loadMessages, messages, selectedMessageId, setMessages],
  );

  return (
    <MessagesContext.Provider value={contextValue}>
      {children}
    </MessagesContext.Provider>
  );
};

type MessagesProviderByChatIdProps = {
  children: ReactNode;
  chatId: string;
  defaultFilter?: MessagesByChatIdFilter;
  preventInitialFetch: boolean;
};

const MessagesProviderByChatId: React.FC<MessagesProviderByChatIdProps> = ({
  children,
  chatId,
  defaultFilter,
  preventInitialFetch = false,
}: MessagesProviderByChatIdProps) => {
  const {
    messages, setMessages, isLoading, loadMessages, setMessagesFilter, messagesFilter,
  } = useFetchMessagesByChatId({
    chatId,
    preventInitialFetch,
    defaultFilter,
  });

  const [selectedMessageId, setSelectedMessageId] = useState<string>();

  const contextValue = useMemo(
    () => ({
      messages,
      setMessages,
      selectedMessageId,
      setSelectedMessageId,
      loadMessages,
      isLoading,
      setMessagesFilter,
      messagesFilter,
    }),
    [
      isLoading,
      loadMessages,
      messages,
      messagesFilter,
      setMessages,
      setMessagesFilter,
      selectedMessageId,
    ],
  );

  return (
    <MessagesContext.Provider value={contextValue}>
      {children}
    </MessagesContext.Provider>
  );
};

type MessagesProviderByMessageIdProps = {
  children: ReactNode;
  messageId: string;
  preventInitialFetch: boolean;
};

const MessagesProviderByMessageId: React.FC<MessagesProviderByMessageIdProps> = ({
  children,
  messageId,
  preventInitialFetch = false,
}: MessagesProviderByMessageIdProps) => {
  const {
    message, isLoading, loadMessage,
  } = useFetchMessageById();

  const [messages, setMessages] = useState<Message[]>([]);
  const [selectedMessageId, setSelectedMessageId] = useState<string>();

  const loadMessages = useCallback((): Promise<void> => {
    loadMessage(messageId);

    return Promise.resolve();
  }, [loadMessage, messageId]);

  useEffect(() => {
    if (preventInitialFetch) return;

    loadMessage(messageId);
  }, [loadMessage, messageId, preventInitialFetch]);

  useEffect(() => {
    if (message) {
      setMessages([message]);
    }
  }, [message]);

  const contextValue = useMemo(
    () => ({
      messages,
      setMessages,
      selectedMessageId,
      setSelectedMessageId,
      loadMessages,
      isLoading,
    }),
    [isLoading, loadMessages, messages, selectedMessageId, setMessages],
  );

  return (
    <MessagesContext.Provider value={contextValue}>
      {children}
    </MessagesContext.Provider>
  );
};

type MessagesProviderPlainProps = {
  children: ReactNode;
  defaultFilter?: MessagesFilter;
  preventInitialFetch: boolean;
};

const MessagesProviderPlain: React.FC<MessagesProviderPlainProps> = ({
  children,
  defaultFilter,
  preventInitialFetch = false,
}: MessagesProviderPlainProps) => {
  const {
    messages,
    setMessages,
    isLoading,
    loadMessages,
    messagesFilter,
    setMessagesFilter,
  } = useFetchMessages({ preventInitialFetch, defaultFilter });

  const [selectedMessageId, setSelectedMessageId] = useState<string>();

  const contextValue = useMemo(
    () => ({
      messages,
      setMessages,
      selectedMessageId,
      setSelectedMessageId,
      loadMessages,
      isLoading,
      messagesFilter,
      setMessagesFilter,
    }),
    [isLoading, loadMessages, messages, messagesFilter, selectedMessageId, setMessages, setMessagesFilter],
  );

  return (
    <MessagesContext.Provider value={contextValue}>
      {children}
    </MessagesContext.Provider>
  );
};

type MessagesProviderProps = {
  children: ReactNode;

  providerType: MessageProviderType;

  defaultFilter?: MessagesFilter;

  chatId?: string;
  referenceId?: string;
  messageId?: string;
  preventInitialFetch?: boolean;
};

const MessagesProvider: React.FC<MessagesProviderProps> = ({
  children,

  providerType,

  defaultFilter,

  chatId,
  referenceId,
  messageId,
  preventInitialFetch = false,
}: MessagesProviderProps) => {
  switch (providerType) {
    case MessageProviderType.BY_REFERENCE_ID:
      return (
        <MessagesProviderByReferenceId
          referenceId={referenceId}
          preventInitialFetch={preventInitialFetch}
        >
          {children}
        </MessagesProviderByReferenceId>
      );
    case MessageProviderType.BY_CHAT_ID:
      return (
        <MessagesProviderByChatId
          chatId={chatId}
          defaultFilter={defaultFilter}
          preventInitialFetch={preventInitialFetch}
        >
          {children}
        </MessagesProviderByChatId>
      );
    case MessageProviderType.BY_MESSAGE_ID:
      return (
        <MessagesProviderByMessageId
          messageId={messageId}
          preventInitialFetch={preventInitialFetch}
        >
          {children}
        </MessagesProviderByMessageId>
      );
    case MessageProviderType.PLAIN:
    default:
      return (
        <MessagesProviderPlain
          defaultFilter={defaultFilter}
          preventInitialFetch={preventInitialFetch}
        >
          {children}
        </MessagesProviderPlain>
      );
  }
};

const useMessagesContext = () => {
  const context = useContext(MessagesContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by MessagesContext provider.');
  }

  return context;
};

export {
  MessagesContext,
  useMessagesContext,
  MessagesProvider,
  MessageProviderType,
};

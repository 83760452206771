import {
  Loader,
  LoaderProps,
  LoadingOverlay as MantineLoadingOverlay,
  OverlayProps,
} from '@mantine/core';
import { useMemo } from 'react';

interface Props {
  visible: boolean;
  loaderProps?: LoaderProps;
  overlayProps?: OverlayProps;

  displayText?: string;
}

const LoadingOverlay = ({
  visible,
  loaderProps,
  overlayProps,
  displayText,
}: Props) => {
  const loaderChild = useMemo(() => {
    if (!displayText) return <Loader type="dots" {...loaderProps} />;

    return (
      <div className="flex flex-col justify-center items-center gap-2">
        <Loader type="dots" {...loaderProps} />
        <p className="text-xs text-gray-500">{displayText}</p>
      </div>
    );
  }, [displayText, loaderProps]);

  return (
    <MantineLoadingOverlay
      visible={visible}
      loaderProps={{ children: loaderChild, ...loaderProps }}
      overlayProps={{ blur: 2, ...overlayProps }}
    />
  );
};

export default LoadingOverlay;

export const decimalCommaToValue = (value: '.' | ',' | '') => {
  if (!value) return 'Dot (0.1)';

  return value === '.' ? 'Dot (0.1)' : 'Comma (0,1)';
};

export const valueToDecimalComma = (value: 'Dot (0.1)' | 'Comma (0,1)') => {
  if (!value) return '.';

  return value === 'Dot (0.1)' ? '.' : ',';
};

import { useParams } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { OrderDetailsPage } from 'features/order/pages';

const OrderDetailsScreen = () => {
  const { orderId } = useParams();

  return (
    <ProtectedScreen>
      <OrderDetailsPage orderId={orderId} />
    </ProtectedScreen>
  );
};

export default OrderDetailsScreen;

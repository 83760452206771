import { useState, useCallback, useEffect } from 'react';

import { httpGet } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { BusinessSettings } from '../../models/BusinessSettings';

interface Props {
  preventInitialFetch?: boolean;
}

const useFetchBusinessSettings = ({
  preventInitialFetch = false,
}: Props) => {
  const [businessSettings, setBusinessSettings] = useState<BusinessSettings>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadBusinessSettings = useCallback(
    (
      giveErrorFeedback: boolean = true,
    ): Promise<BusinessSettings> => {
      setIsLoading(true);
      return httpGet('/businesses/me/settings')
        .then((response) => {
          const _businessSettings = {
            ...response.data.settings,
            businessTimes:
            response.data?.settings?.businessTimes
            && response.data?.settings?.businessTimes.length > 0
              ? response.data?.settings?.businessTimes
              : [{ from: null, to: null }],
          } as BusinessSettings;
          setBusinessSettings(_businessSettings);

          return _businessSettings;
        })
        .catch((err) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Error loading business settings')(err);
          }
          return Promise.reject(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  useEffect(() => {
    if (!preventInitialFetch) {
      loadBusinessSettings();
    }
  }, [preventInitialFetch, loadBusinessSettings]);

  return {
    businessSettings,
    setBusinessSettings,
    loadBusinessSettings,
    isLoading,
  };
};

export { useFetchBusinessSettings };

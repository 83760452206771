import Dayjs from 'dayjs';
import { DateTimePicker } from '@mantine/dates';
import { Select } from '@mantine/core';
import { useCallback } from 'react';

import { GetOrdersParams } from 'features/order/api/useFetchOrders';

import { Button } from 'components/ui/Button';
import { doesExist } from 'helpers/comparison';
import { OrderStatus } from '../../models/Order';

interface Props {
  params: GetOrdersParams;
  setParams: React.Dispatch<React.SetStateAction<GetOrdersParams>>;
  onResetFilters: () => void;
}

const OrderSearchBar = ({ params, setParams, onResetFilters }: Props) => {
  const onFromDatetimeSelectChange = useCallback(
    (datetime: Date) => {
      setParams((_params) => ({
        ..._params,
        from_time: datetime ? Dayjs(datetime).format() : null,
      }));
    },
    [setParams],
  );

  const onToDatetimeSelectChange = useCallback(
    (datetime: Date) => {
      setParams((_params) => ({
        ..._params,
        to_time: datetime ? Dayjs(datetime).format() : null,
      }));
    },
    [setParams],
  );

  const onAllButtonClick = useCallback(() => {
    setParams((_params) => ({ ..._params, is_not_exported: false }));
  }, [setParams]);

  const onOnlyNotExportedButtonClick = useCallback(() => {
    setParams((_params) => ({ ..._params, is_not_exported: true }));
  }, [setParams]);

  const onOrderStatusSelectChange = useCallback(
    (status: string) => {
      setParams((_params) => ({
        ..._params,
        status: doesExist(status, '') ? [status as OrderStatus] : null,
      }));
    },
    [setParams],
  );

  return (
    <div className="items-bottom flex gap-smd">
      <div className="max-w-sm">
        <Select
          value={(params.status || [])[0] || ''}
          data={[
            { value: '', label: 'All statuses' },
            { value: OrderStatus.New, label: 'New' },
            { value: OrderStatus.Confirmed, label: 'Confirmed' },
            { value: OrderStatus.Rejected, label: 'Rejected' },
            { value: OrderStatus.TimedOut, label: 'Timed out' },
          ]}
          onChange={onOrderStatusSelectChange}
          clearable
        />
      </div>
      <div className="max-w-sm">
        <DateTimePicker
          value={params.from_time ? Dayjs(params.from_time).toDate() : null}
          placeholder="From datetime"
          onChange={onFromDatetimeSelectChange}
          clearable
        />
      </div>
      <div className="max-w-sm">
        <DateTimePicker
          value={params.to_time ? Dayjs(params.to_time).toDate() : null}
          placeholder="To datetime"
          onChange={onToDatetimeSelectChange}
          clearable
        />
      </div>
      <div className="flex items-center space-x-2 rounded-smd border border-neutral-100 px-sm py-xs">
        <button
          type="button"
          className={`rounded-smd px-md py-xs text-sm ${!params.is_not_exported ? 'bg-primary-800 text-white' : 'bg-white text-neutral-700'}`}
          onClick={onAllButtonClick}
        >
          All
        </button>
        <button
          type="button"
          className={`rounded-sm px-md py-xs text-sm ${params.is_not_exported ? 'bg-primary-800 text-white' : 'bg-white text-neutral-500'}`}
          onClick={onOnlyNotExportedButtonClick}
        >
          Not exported
        </button>
      </div>
      <Button
        theme="secondary"
        title="Reset filters"
        onClick={onResetFilters}
      />
    </div>
  );
};

export default OrderSearchBar;

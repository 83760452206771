import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';

const styles = {
  wrapper: {
    width: '100%',
  },
  leftButton: {},
  rightButton: {},
  textWrapper: {},
};

interface Props {
  listLength: number;
  currentIndex: number;
  onNext: () => void;
  onPrev: () => void;
}

const ListNavigator = ({
  listLength, currentIndex, onNext, onPrev,
}: Props) => {
  const text = useMemo(
    () => `${currentIndex + 1} of ${listLength}`,
    [currentIndex, listLength],
  );

  return (
    <div
      style={styles.wrapper}
      className="flex h-fit items-center justify-center gap-lg border-b border-solid bg-white py-sm"
    >
      <button type="button" style={styles.leftButton} onClick={onPrev}>
        <ArrowLeftIcon height={16} width={16} />
      </button>
      <div style={styles.textWrapper}>{text}</div>
      <button type="button" style={styles.rightButton} onClick={onNext}>
        <ArrowRightIcon height={16} width={16} />
      </button>
    </div>
  );
};

export { ListNavigator };

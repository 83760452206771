import {
  forwardRef,
  useCallback, useEffect, useImperativeHandle, useState, useMemo,
} from 'react';
import {
  Layer, Rect, Stage, Text,
} from 'react-konva';
import { LoadingOverlay, Tooltip } from '@mantine/core';
import Konva from 'konva';
import { twMerge } from 'tailwind-merge';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { Annotation, KonvaStageRef, Tool } from './type';

interface Props {
  annotationsKey: string;
  imageDimensions: {
    width: number;
    height: number;
  };
  scaleX: number;
  scaleY: number;
  useMagicPen: boolean;
  selectedTool: Tool;
  sendScreenshot: (annotation: Annotation) => void;
}

const MIN_ANNOTATION_SIZE = 50;

const doesAnnotationContainAnotherAnnotation = (annotations: Annotation[], annotation: Annotation) => {
  const topLeft = { x: annotation.x, y: annotation.y };
  const bottomRight = { x: annotation.x + annotation.width, y: annotation.y + annotation.height };

  return annotations.some(
    (a) => (
      a.x >= topLeft.x
      && a.y >= topLeft.y
      && a.x + a.width <= bottomRight.x
      && a.y + a.height <= bottomRight.y
    ),
  );
};

const KonvaStage = ({
  annotationsKey, imageDimensions, scaleX, scaleY, useMagicPen, selectedTool, sendScreenshot,
}: Props, ref: React.ForwardedRef<KonvaStageRef>) => {
  const {
    annotationsRecord,
    setAnnotations,
    removeAnnotationByPoint,
    setAnnotationIsLoading,
    addAnnotationProductUiIds,
    addAnnotationPopupContent,
    scrollToKeyword,
  } = useProcessOrderContext();

  const [newAnnotation, setNewAnnotation] = useState<Annotation | null>(null);
  const [openTooltips, setOpenTooltips] = useState<string[]>([]);

  const annotations = useMemo(() => annotationsRecord[annotationsKey] || [], [annotationsRecord, annotationsKey]);

  const handleMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (!useMagicPen || (selectedTool !== 'Magic Pen' && selectedTool !== 'Eraser')) {
      return;
    }

    if (!newAnnotation) {
      const { x, y } = event.target.getStage().getPointerPosition();

      // If the selected tool is the eraser, clear the annotation at the point
      if (selectedTool === 'Eraser') {
        removeAnnotationByPoint(annotationsKey, x, y);
      } else {
        setNewAnnotation({
          x, y, width: 0, height: 0, key: '0',
        });
      }
    }
  };

  const handleMouseUp = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (!useMagicPen) {
      return;
    }

    if (newAnnotation) {
      const sx = newAnnotation.x;
      const sy = newAnnotation.y;
      const { x, y } = event.target.getStage().getPointerPosition();
      const width = x - sx;
      const height = y - sy;

      if (width / scaleX < MIN_ANNOTATION_SIZE
        || height / scaleY < MIN_ANNOTATION_SIZE
        || doesAnnotationContainAnotherAnnotation(annotations, {
          x: sx, y: sy, width, height, key: '',
        })) {
        setNewAnnotation(null);
        return;
      }

      const annotationToAdd = {
        x: sx,
        y: sy,
        width,
        height,
        key: `${annotations.length + 1}`,
      } as Annotation;
      annotations.push(annotationToAdd);
      setNewAnnotation(null);
      setAnnotations(annotationsKey, annotations);

      sendScreenshot(annotationToAdd);
    }
  };

  const handleMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (!useMagicPen) {
      return;
    }

    if (newAnnotation) {
      const sx = newAnnotation.x;
      const sy = newAnnotation.y;
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewAnnotation({
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
        isTooSmall: (x - sx) / scaleX < MIN_ANNOTATION_SIZE
          || (y - sy) / scaleY < MIN_ANNOTATION_SIZE,
        key: '0',
      });
    }
  };

  const keyDownHandler = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape' && newAnnotation) {
      setNewAnnotation(null);
    }
  }, [newAnnotation]);

  const onPopupClicked = useCallback((key: string | number) => {
    const annotation = annotations?.find((a) => a.key === key);

    if (annotation?.productUiIds) {
      document.getElementById(annotation.productUiIds[0])?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      scrollToKeyword(annotation.productUiIds[0]);
    }
  }, [annotations, scrollToKeyword]);

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => window.removeEventListener('keydown', keyDownHandler);
  }, [keyDownHandler]);

  useImperativeHandle(ref, () => ({
    setIsLoading: (key: string, isLoading: boolean) => (
      setAnnotationIsLoading(annotationsKey, key, isLoading)
    ),
    addProductUiIds: (key: string, productUiIds: string[]) => (
      addAnnotationProductUiIds(annotationsKey, key, productUiIds)
    ),
    addPopup: (key: string, content: string) => {
      addAnnotationPopupContent(annotationsKey, key, content);
      onPopupClicked(key);
    },
  }));

  return (
    <div className="absolute left-0 top-0 z-[100]">
      <div className="relative">
        <Stage
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          width={imageDimensions.width}
          height={imageDimensions.height}
          className={twMerge(
            'z-[100]',
          )}
        >
          <Layer>
            {annotations.map((value) => (
              <Rect
                key={value.key}
                x={value.x}
                y={value.y}
                width={value.width}
                height={value.height}
                fill="rgba(66,149,245,0.2)"
                stroke="blue"
                strokeWidth={1}
                onClick={() => onPopupClicked(value.key)}
                onMouseEnter={(e) => {
                  const rect = e.target as Konva.Rect;
                  rect.fill('rgba(138, 93, 250, 0.4)');
                  rect.strokeWidth(2);
                  setOpenTooltips((prev) => (
                    prev.includes(value.key)
                      ? prev
                      : [...prev, value.key]
                  ));
                }}
                onMouseLeave={(e) => {
                  const rect = e.target as Konva.Rect;
                  rect.fill('rgba(138, 93, 250, 0.2)');
                  rect.strokeWidth(1);
                  setOpenTooltips((prev) => prev.filter((k) => k !== value.key));
                }}
              />
            ))}

            {
              newAnnotation && (
                <>
                  <Rect
                    key={newAnnotation.key}
                    x={newAnnotation.x}
                    y={newAnnotation.y}
                    width={newAnnotation.width}
                    height={newAnnotation.height}
                    fill={newAnnotation.isTooSmall ? 'rgba(255,0,0,0.2)' : 'rgba(66,149,245,0.2)'}
                    stroke={newAnnotation.isTooSmall ? 'red' : 'blue'}
                    strokeWidth={1}
                  />

                  {
                    newAnnotation.isTooSmall && (
                      <Text
                        x={newAnnotation.x}
                        y={newAnnotation.y - 10}
                        text="Choose a larger area"
                        fill="red"
                        fontSize={10}
                      />
                    )
                  }
                </>
              )
            }
          </Layer>
        </Stage>

        {annotations.map((value) => (
          value.isLoading ? (
            <div
              key={value.key}
              className="flex items-center justify-center"
              style={{
                position: 'absolute',
                left: value.x,
                top: value.y,
                width: value.width,
                height: value.height,
              }}
            >
              <LoadingOverlay
                visible
                loaderProps={{ type: 'dots' }}
                overlayProps={{ blur: 2 }}
              />
            </div>
          ) : null
        ))}

        {annotations.map((value) => (
          value.popupContent && selectedTool !== 'Eraser' ? (
            <div
              key={value.key}
              style={{
                position: 'absolute',
                left: value.x,
                top: value.y,
                width: 'fit-content',
                height: 'fit-content',
              }}
            >
              <Tooltip
                withArrow
                position="top"
                label={value.popupContent}
                opened={openTooltips.includes(value.key)}
                defaultOpened
                transitionProps={{ transition: 'pop', duration: 200 }}
                bg="white"
                styles={{
                  tooltip: {
                    color: 'black',
                    border: '1.5px solid #E8E8E8',
                    boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                  },
                }}
              >
                <div style={{ width: value.width }} />
              </Tooltip>
            </div>
          ) : null
        ))}
      </div>
    </div>
  );
};

export default forwardRef(KonvaStage);

import {
  memo, useCallback, useMemo, useRef,
} from 'react';
import { Accordion } from '@mantine/core';

import { Order, ProductWithQuantity } from 'features/order/models/Order';
import { useOrderContext } from 'features/order/contexts/useOrderContext';
import { FieldSpec, Schema } from 'models/Schema';
import { usePlayRecordingContext } from 'contexts/usePlayRecordingIndex';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { useDragDropContext } from 'contexts/useDragDropContext';

import RenderIfVisible from 'components/ui/RenderIfVisible';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import { ProductSortingColumn } from 'types/product';
import { isZeroId } from 'helpers/objectId';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { twMerge } from 'tailwind-merge';
import Control from './Control';
import Panel from './Panel';

interface Props {
  index: number;
  value: string;
  product: ProductWithQuantity;
  customerId: string;
  fieldSpecs: FieldSpec[];
  schema: Schema;
  sortingColumn: ProductSortingColumn;
  filterEnabledProducts: boolean;
  setAccordionValue: React.Dispatch<React.SetStateAction<string[]>>;
  getProductFieldValue: (index: number, modelPath: string) => any;
  setError?: (key: string, error: string) => void;
}

const MemoizedProductField = memo(
  ({
    index,
    value,
    product,
    fieldSpecs,
    schema,
    sortingColumn,
    filterEnabledProducts,
    customerId,
    setAccordionValue,
    setError,
    getProductFieldValue,
    hoverId,
    triggerHoverEffect,
    setGroupOrders,
    selectedIndex,
    selectedMessageId,
    setPlayRecordingKeywordAndMessageId,
    setDraggedItemId,
    setDragging,
    isRecordingAvailable,
  }: {
    filterEnabledProducts: boolean;
    hoverId: string;
    triggerHoverEffect: React.Dispatch<React.SetStateAction<string>>;
    setGroupOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    selectedIndex: number;
    selectedMessageId: string;
    setPlayRecordingKeywordAndMessageId: (keywordAndMessageId: {
      keyword: string;
      messageId: string;
    }) => void;
    setDraggedItemId: React.Dispatch<React.SetStateAction<string>>;
    setDragging: React.Dispatch<React.SetStateAction<boolean>>;
    isRecordingAvailable: boolean;
  } & Props) => {
    const dragImageRef = useRef(null);
    const selectRef = useRef<HTMLDivElement>(null);

    const hideTooltip = useMemo(
      () => !Object.values(product?.errors || {}).some((error) => error),
      [product?.errors],
    );

    const isProductConfirmed = useMemo(
      () => product?.confirmed,
      [product?.confirmed],
    );

    const isHovered = useMemo(() => (
      hoverId === product?.uiId || hoverId === product?.positionId || hoverId === product?.name
    ), [hoverId, product?.name, product?.positionId, product?.uiId]);

    const TooltipLabel = useMemo(
      () => (
        <ul className="list-disc px-3">
          {Object.values(product?.errors || {})
            .reduce((acc, _value) => (_value ? [...acc, _value] : acc), [])
            .map((err) => (
              <li key={err}>{err}</li>
            ))}
        </ul>
      ),
      [product?.errors],
    );

    const onPlayButtonClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setPlayRecordingKeywordAndMessageId({
          keyword: product.name,
          messageId: selectedMessageId,
        });
      },
      [product.name, selectedMessageId, setPlayRecordingKeywordAndMessageId],
    );

    const onMouseEnter = useCallback(() => {
      triggerHoverEffect(product?.positionId || product?.name);
    }, [product?.name, product?.positionId, triggerHoverEffect]);

    const onMouseLeave = useCallback(() => {
      triggerHoverEffect('');
    }, [triggerHoverEffect]);

    const createDragImage = useCallback((node: HTMLElement) => {
      const dragImage = node.cloneNode(true) as HTMLElement;
      dragImage.style.position = 'absolute';
      dragImage.style.top = '-9999px';
      dragImage.style.left = '-9999px';
      dragImage.style.transform = 'scale(0.4)';
      document.body.appendChild(dragImage);
      dragImageRef.current = dragImage;
    }, []);

    const onDragStart = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        if (selectRef.current) {
          createDragImage(selectRef.current);
          e.dataTransfer.setDragImage(dragImageRef.current!, 0, 0);
        }
        setDraggedItemId(product.uiId);
        setDragging(true);
      },
      [createDragImage, product.uiId, setDraggedItemId, setDragging],
    );

    const onDragEnd = useCallback(() => {
      setDragging(false);
    }, [setDragging]);

    const id = useMemo(() => {
      if (product?.positionId && !isZeroId(product?.positionId)) {
        return product.positionId;
      }
      if (product?.uiId) {
        return product.uiId;
      }

      return product?.name?.trim();
    }, [product?.positionId, product?.uiId, product?.name]);

    const name = useMemo(() => product?.name?.trim(), [product?.name]);

    const placeholderElement = useMemo(() => (
      <div id={id} data-name={name} className="h-[200px] flex flex-col rounded-md border border-gray-200">
        <div className="px-10 py-2">{product?.name}</div>
        <div className="flex-1 relative">
          <LoadingOverlay
            visible
          />
        </div>
      </div>
    ), [product?.name, id, name]);

    return (
      <RenderIfVisible stayRendered placeholderElement={placeholderElement}>
        <Accordion.Item
          id={id}
          data-name={name}
          value={value}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={selectRef}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          draggable
          className={twMerge(
            isHovered && 'shadow-md scale-[1.01]',
            'transition-all duration-300 hover:scale-100',
          )}
        >
          <Control
            hideTooltip={hideTooltip}
            TooltipLabel={TooltipLabel}
            isProductConfirmed={isProductConfirmed}
            productName={product?.name}
            isRecordingAvailable={isRecordingAvailable}
            onPlayButtonClick={onPlayButtonClick}
          />
          <Panel
            index={index}
            product={product}
            fieldSpecs={fieldSpecs}
            schema={schema}
            sortingColumn={sortingColumn}
            filterEnabledProducts={filterEnabledProducts}
            customerId={customerId}
            setAccordionValue={setAccordionValue}
            setError={setError}
            getProductFieldValue={getProductFieldValue}
            setGroupOrders={setGroupOrders}
            selectedIndex={selectedIndex}
            hideTooltip={hideTooltip}
            TooltipLabel={TooltipLabel}
            isProductConfirmed={isProductConfirmed}
          />
        </Accordion.Item>
      </RenderIfVisible>
    );
  },
);

const ProductField = memo((props: Props) => {
  const { hoverId, triggerHoverEffect } = useProcessOrderContext();
  const { selectedMessageId } = useMessagesContext();
  const { setGroupOrders, selectedIndex } = useOrderContext();
  const { isRecordingAvailable, setPlayRecordingKeywordAndMessageId } = usePlayRecordingContext();
  const { setDraggedItemId, setDragging } = useDragDropContext();

  return (
    <MemoizedProductField
      hoverId={hoverId}
      triggerHoverEffect={triggerHoverEffect}
      setGroupOrders={setGroupOrders}
      selectedIndex={selectedIndex}
      selectedMessageId={selectedMessageId}
      setPlayRecordingKeywordAndMessageId={setPlayRecordingKeywordAndMessageId}
      setDraggedItemId={setDraggedItemId}
      setDragging={setDragging}
      isRecordingAvailable={isRecordingAvailable}
      {...props}
    />
  );
});

export default ProductField;

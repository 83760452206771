import { useMessagesContext } from '../../../../contexts/useMessagesContext';
import { ChatMessagesBase } from './ChatMessagesBase';
import { Business } from '../../../../models/Business';

interface Props {
  business?: Business;
  isAdamThinking?: boolean;
}

const ChatMessages = ({ business, isAdamThinking }: Props) => {
  const {
    messages, isLoading, loadMessages,
  } = useMessagesContext();

  return (
    <ChatMessagesBase
      messages={messages}
      business={business}
      isLoading={isLoading}
      isAdamThinking={isAdamThinking}
      loadMessages={loadMessages}
      isExpandable
    />
  );
};

export default ChatMessages;

import {
  memo, useCallback, useRef, useState,
} from 'react';
import Dayjs from 'dayjs';
import { LoadingOverlay, ScrollArea, Tooltip } from '@mantine/core';
import { startOfToday } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { EnvelopeOpenIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';
import { intentToTagVariant } from 'helpers/enums';
import { isZeroTime } from 'helpers/dateTime';
import { Tag } from 'components/common/Tag';
import { Message } from '../../models/Message';
import { OverflowEllipsisParagraph } from '../ui/OverflowEllipsisParagraph';
import MessageFilter from './MessageFilter';
import { useMessagesContext } from '../../contexts/useMessagesContext';
import { useMarkMessageAsRead } from '../../hooks/useMarkMessageAsRead';
import { ROUTES } from '../../config/routes';
import { decodeEntities } from '../../utils/messageUtils';

const formatDatetime = (message: Message) => {
  const now = startOfToday();
  if (isZeroTime(message.originalCreatedAt) && isZeroTime(message.createdAt)) {
    return '-';
  }

  const messageDatetime = Dayjs(message.originalCreatedAt || message.createdAt);

  if (messageDatetime.isSame(now, 'day')) {
    return messageDatetime.format('LT');
  }

  return messageDatetime.format('MMM D');
};

const MessageRow = memo(
  ({
    message,
    selectedMessages,
    setSelectedMessages,
    onMessageRowClick,
  }: {
    message: Message;
    selectedMessages: Message[];
    setSelectedMessages: (messages: Message[]) => void;
    onMessageRowClick: (message: Message) => void;
  }) => (
    <tr
      key={message.id}
      className={twMerge(
        'cursor-pointer bg-white',
        selectedMessages.includes(message) || !isZeroTime(message.readAt)
          ? 'bg-gray-100'
          : 'hover:bg-gray-50',
      )}
      onClick={() => onMessageRowClick(message)}
    >
      <td className="relative px-7 sm:w-12 sm:px-xl">
        {selectedMessages.includes(message) && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          value={message.id}
          checked={selectedMessages.includes(message)}
          onChange={(e) => setSelectedMessages(
            e.target.checked
              ? [...selectedMessages, message]
              : selectedMessages.filter((p) => p !== message),
          )}
          onClick={(e) => e.stopPropagation()}
        />
      </td>
      <td className="px-lg">
        <div className="items-center">
          {/* Business icon that might be added later */}
          {/* <div className="h-11 w-11 flex-shrink-0">
          <div className="h-11 w-11 rounded-full bg-indigo-50 text-indigo-500 flex items-center justify-center">
            <p className="text-2xl font-bold">{message.businessSentByInfo?.name?.slice(0, 2).toUpperCase()}</p>
          </div>
        </div> */}
          <div className="text-gray-900">
            {message.businessSentByInfo?.name}
          </div>
        </div>
      </td>
      <td className="px-lg">
        <div className="flex items-center gap-1">
          {message.intents && message.intents.length > 0 && (
            <>
              <Tag
                tagTitle={message.intents[0] || '-'}
                isTagLoading={false}
                className="static"
                tagVariant={intentToTagVariant(message.intents[0])}
                hideCircle
              />
              {message.intents.length > 1 && (
                <Tooltip
                  label={(
                    <div className="flex flex-col gap-2">
                      {message.intents?.map((intent) => (
                        <Tag
                          key={intent}
                          tagTitle={intent || '-'}
                          isTagLoading={false}
                          className="static"
                          tagVariant={intentToTagVariant(intent)}
                          hideCircle
                        />
                      ))}
                    </div>
                  )}
                  transitionProps={{ transition: 'pop' }}
                  bg="white"
                  position="bottom"
                  styles={{
                    tooltip: {
                      boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                    },
                  }}
                >
                  <div className="flex aspect-square h-fit items-center justify-center rounded-full border border-gray-200 px-1 text-xxs text-gray-500">
                    +
                    {message.intents.length - 1}
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </td>
      <td className="w-full px-lg py-smd text-sm text-gray-500">
        <div className="font-bold text-gray-900">
          {decodeEntities(message.context?.subject) || 'No subject'}
        </div>
        <div className="max-w-lg">
          <OverflowEllipsisParagraph maxLines={1}>
            <div className="text-gray-500">{decodeEntities(message.message)}</div>
          </OverflowEllipsisParagraph>
        </div>
      </td>
      <td className="whitespace-nowrap px-lg py-smd pr-7 text-sm text-gray-500">
        {formatDatetime(message)}
      </td>
    </tr>
  ),
);

const MessageListTable = () => {
  const navigate = useNavigate();

  const {
    messages,
    setMessages,
    isLoading: isMessagesLoading,
    messagesFilter,
    setMessagesFilter,
    loadMessages,
  } = useMessagesContext();

  const {
    markMessagesAsRead: _markMessageAsRead,
    isLoading: isMarkMessageAsReadLoading,
  } = useMarkMessageAsRead();

  const checkbox = useRef<HTMLInputElement>();
  const [allSelected, setAllSelected] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);

  const toggleAll = useCallback(() => {
    setSelectedMessages((prev) => (prev.length > 0 ? [] : messages));
    setAllSelected((prev) => !prev);
  }, [messages]);

  const reload = useCallback(() => {
    loadMessages(true);
  }, [loadMessages]);

  const onMessageRowClick = useCallback(
    (message: Message) => {
      if (message.ref) {
        navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(message.ref));
      } else {
        navigate(ROUTES.MESSAGE_BY_ID(message.id));
      }
    },
    [navigate],
  );

  const markMessagesAsRead = useCallback(() => {
    _markMessageAsRead(selectedMessages.map((m) => m.id)).then(() => {
      if (messagesFilter.unreadOnly) {
        setMessages((prev) => prev.filter((m) => !selectedMessages.includes(m)),
        );
      } else {
        setMessages((prev) => prev.map((m) => ({
          ...m,
          readAt:
              m.id === selectedMessages[0].id
                ? new Date().toISOString()
                : m.readAt,
        })),
        );
      }
      setSelectedMessages([]);
    });
  }, [
    _markMessageAsRead,
    messagesFilter.unreadOnly,
    selectedMessages,
    setMessages,
  ]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden rounded-md border border-solid">
      <ScrollArea.Autosize className="relative" onBottomReached={loadMessages}>
        {isMarkMessageAsReadLoading && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/50">
            <LoadingOverlay
              visible={isMarkMessageAsReadLoading}
              loaderProps={{ type: 'dots' }}
              overlayProps={{ blur: 2 }}
            />
          </div>
        )}
        <div className="sticky top-0 z-10 border-b border-gray-300 bg-white drop-shadow-sm">
          <div className="flex items-center gap-3 py-2">
            <div className="relative px-5">
              <input
                type="checkbox"
                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                ref={checkbox}
                checked={allSelected}
                onChange={toggleAll}
              />
            </div>
            <Tooltip
              label="Refresh"
              transitionProps={{
                transition: 'pop',
                duration: 200,
                enterDelay: 100,
              }}
              bg="white"
              styles={{
                tooltip: {
                  color: 'black',
                  border: '1.5px solid #E8E8E8',
                  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                },
              }}
            >
              <button
                type="button"
                onClick={reload}
                className="rounded-full p-2 transition-colors delay-100 duration-200 hover:bg-gray-100"
              >
                <ArrowPathIcon className="h-5 w-5 text-gray-600" />
              </button>
            </Tooltip>
            {selectedMessages.length > 0 && (
              <Tooltip
                label="Mark as read"
                transitionProps={{
                  transition: 'pop',
                  duration: 200,
                  enterDelay: 100,
                }}
                bg="white"
                styles={{
                  tooltip: {
                    color: 'black',
                    border: '1.5px solid #E8E8E8',
                    boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                  },
                }}
              >
                <button
                  type="button"
                  onClick={markMessagesAsRead}
                  className="rounded-full p-2 transition-colors delay-100 duration-200 hover:bg-gray-100"
                >
                  <EnvelopeOpenIcon className="h-5 w-5 text-gray-600" />
                </button>
              </Tooltip>
            )}
            <MessageFilter
              setMessagesFilter={setMessagesFilter}
              defaultFilter={messagesFilter}
            />
          </div>
        </div>
        <table className="min-w-full divide-y divide-gray-300">
          <tbody className="divide-y divide-gray-200 bg-white">
            {messages.map((message) => (
              <MessageRow
                key={message.id}
                message={message}
                selectedMessages={selectedMessages}
                setSelectedMessages={setSelectedMessages}
                onMessageRowClick={onMessageRowClick}
              />
            ))}
          </tbody>
        </table>
        <div className="relative flex h-10 w-full items-center justify-center">
          <LoadingOverlay
            visible={isMessagesLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </ScrollArea.Autosize>
    </div>
  );
};

export default MessageListTable;

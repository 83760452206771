import { ChevronLeftIcon } from '@heroicons/react/24/outline';

interface Props {
  onBack: () => void;
}

const _Header = ({ onBack }: Props) => (
  <div className="flex space-x-2">
    <button type="button" onClick={onBack}>
      <ChevronLeftIcon height={20} width={20} />
    </button>
    <div>Create customer</div>
  </div>
);

export { _Header };

import { _Sidebar as Sidebar } from './_CustomerDetail/_Sidebar';
import { Business } from '../../../models/Business';

interface Props {
  customer?: Business;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  onComplete: (customer: Business) => void;
}

const CustomerDetailSidebar = ({
  customer,
  sidebarOpen,
  setSidebarOpen,
  onComplete,
}: Props) => {
  const _onComplete = (_customer: Business) => {
    setSidebarOpen(false);
    onComplete(_customer);
  };

  return (
    <Sidebar
      customer={customer}
      _onComplete={_onComplete}
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
    />
  );
};

export { CustomerDetailSidebar };

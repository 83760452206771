import { GroupedFields } from 'types/schema';
import { FieldSpec, Schema } from '../models/Schema';
import { getFieldByPath } from './schema';

export const groupFields = (fields: FieldSpec[], schema: Schema): GroupedFields[] => {
  if (!fields || !schema) return [];

  const pathFieldsMap = fields?.reduce(
    (acc: Record<string, FieldSpec[]>, field) => {
      const parentPath = field.lastCardableParentPath;

      if (!acc[parentPath]) {
        acc[parentPath] = [];
      }
      acc[parentPath].push(field);
      return acc;
    },
    {},
  );

  function buildGroupedFields(
    currentPath: string,
    pathFieldsMap_: Record<string, FieldSpec[]>,
  ): GroupedFields {
    const subGroups: GroupedFields[] = [];

    if (currentPath.includes('.')) {
      // Find all children paths
      Object.keys(pathFieldsMap_)
        .forEach((path) => {
          if (path.includes('.') && path !== currentPath && path.startsWith(currentPath)) {
            const subGroup = buildGroupedFields(path, pathFieldsMap_);
            subGroups.push(subGroup);
          }
        });
    }

    return {
      title: currentPath?.includes('.') ? getFieldByPath(schema?.fields, currentPath)?.name || currentPath : 'Standard fields',
      fields: pathFieldsMap_[currentPath] || [],
      subGroups,
    };
  }

  // Initialize the root groups based on the pathFieldsMap
  const groupedFields_ = Object.keys(pathFieldsMap)
    .sort((a, b) => a.localeCompare(b))
    .filter((path) => !Object.keys(pathFieldsMap).some((p) => p.includes('.') && path.startsWith(p) && path !== p))
    .map((rootPath) => buildGroupedFields(rootPath, pathFieldsMap));

  return groupedFields_;
};

export const getGroupKeyByPath = (path: string, groupedFields: GroupedFields[]) => (
  groupedFields.find((group) => group.fields.some((field) => field.path === path))?.title
);

import { useEffect, useState } from 'react';
import {
  Combobox,
  ComboboxProps,
  Input,
  InputBase,
  ScrollArea,
  useCombobox,
} from '@mantine/core';
import { twMerge } from 'tailwind-merge';

import { GroupedOption, Option } from './types';

interface Props extends ComboboxProps {
  groupedOptions: GroupedOption[];

  selectedValue: string;

  placeHolder?: string;
  inputBaseClassName?: string;

  onChange?: (option: Option) => void;
}

const getOptionByValue = (groupedOptions: GroupedOption[], value: string) => groupedOptions
  .flatMap((group) => group.options)
  .find((_option) => _option.value === value);

const GroupedCombobox = ({
  groupedOptions,
  placeHolder,
  inputBaseClassName,
  selectedValue,
  onChange,
  ...props
}: Props) => {
  const combobox = useCombobox({});

  // const [value, setValue] = useState<string | null>(selectedValue);
  const [label, setLabel] = useState<string | null>(null);

  useEffect(() => {
    const option = getOptionByValue(groupedOptions, selectedValue);

    setLabel(option?.label);
  }, [groupedOptions, selectedValue]);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        combobox.closeDropdown();

        // get option from groupoptions by val
        const option = getOptionByValue(groupedOptions, val);

        setLabel(option?.label);

        onChange?.(option);
      }}
      {...props}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          className={twMerge('w-full', inputBaseClassName)}
        >
          {label || (
            <Input.Placeholder>{placeHolder || 'Pick value'}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options className="max-h-[40vh]">
          <ScrollArea.Autosize
            className="max-h-[40vh]"
            type="scroll"
            offsetScrollbars
          >
            {groupedOptions.length > 0 ? (
              groupedOptions.map((groupedOption, i) => (
                <span key={`groupedOption-${i.toString()}`}>
                  {groupedOption.label !== '' ? (
                    <Combobox.Group label={groupedOption.label}>
                      {groupedOption.options.map((option) => (
                        <Combobox.Option
                          key={option.label}
                          value={option.value}
                        >
                          {option.label}
                        </Combobox.Option>
                      ))}
                    </Combobox.Group>
                  ) : (
                    <>
                      {groupedOption.options.map((option) => (
                        <Combobox.Option
                          key={option.label}
                          value={option.value}
                        >
                          {option.label}
                        </Combobox.Option>
                      ))}
                    </>
                  )}
                </span>
              ))
            ) : (
              <Combobox.Empty>No options</Combobox.Empty>
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default GroupedCombobox;

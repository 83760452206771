import { createContext, useContext, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Accordion } from '@mantine/core';
import { v4 as uuidv4 } from 'uuid';

import { Tag, TagProps } from '../Tag';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  id?: string;
  tagProps?: TagProps;
  accordion?: boolean;
  openAccordion?: boolean;
}

const CardContext = createContext<Partial<Props>>({});

const Card = ({
  children, className, id, tagProps, accordion, openAccordion, ...props
}: Props) => {
  const value = useMemo(() => ({ accordion }), [accordion]);
  const uniqueId = useMemo(() => uuidv4(), []);

  if (accordion) {
    return (
      <Accordion
        className={twMerge('flex-1', className)}
        defaultValue={openAccordion ? uniqueId : ''}
      >
        <Accordion.Item
          value={uniqueId}
          className="flex flex-col rounded-lg border border-[#f1f1f4] bg-white shadow-sm relative"
        >
          <CardContext.Provider value={value}>
            {children}
          </CardContext.Provider>
        </Accordion.Item>
      </Accordion>
    );
  }

  return (
    <CardContext.Provider value={value}>
      <div
        id={id}
        className={twMerge(
          'flex flex-col rounded-lg border border-[#f1f1f4] bg-white shadow-sm relative',
          className,
        )}
        {...props}
      >
        {children}
        {/* TODO: take care of padding */}
        {tagProps && (
        <Tag {...tagProps} />
        )}
      </div>
    </CardContext.Provider>
  );
};

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  parentClassName?: string;
  titleClassName?: string;
}

const CardHeader = ({
  children,
  parentClassName,
  titleClassName,
  ...props
}: HeaderProps) => {
  const { accordion } = useContext(CardContext);

  if (accordion) {
    return (
      <Accordion.Control
        className={twMerge('flex min-h-[56px] items-center justify-between', parentClassName)}
        style={{
          backgroundColor: 'transparent',
          borderBottom: '1px solid #f1f1f4',
          paddingLeft: '1.875rem',
          paddingRight: '1.875rem',
        }}
      >
        <h3
          className={twMerge(
            'text-[1rem] font-semibold leading-6 text-gray-900',
            titleClassName,
          )}
        >
          {children}
        </h3>
      </Accordion.Control>
    );
  }

  return (
    <div
      className={twMerge(
        'flex min-h-[56px] items-center justify-between border-b border-[#f1f1f4] px-[1.875rem] py-[0.75rem]',
        parentClassName,
      )}
      {...props}
    >
      <h3
        className={twMerge(
          'text-[1rem] font-semibold leading-6 text-gray-900',
          titleClassName,
        )}
      >
        {children}
      </h3>
    </div>
  );
};

Card.Header = CardHeader;

interface BodyProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

const CardBody = ({
  children,
  className,
  ...props
}: BodyProps) => {
  const { accordion } = useContext(CardContext);

  if (accordion) {
    return (
      <Accordion.Panel
        className={twMerge('flex-1 flex items-center', className)}
        style={{
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingTop: '0.6rem',
          paddingBottom: '0.6rem',
        }}
      >
        {children}
      </Accordion.Panel>
    );
  }

  return (
    <div className={twMerge('grid flex-1 gap-5 px-8 py-5', className)} {...props}>
      {children}
    </div>
  );
};

Card.Body = CardBody;

export default Card;

import { useEffect, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { object } from 'yup';
import { Formik } from 'formik';

import { Page } from 'components/layout/Page/Page';
import { BluePinkyBackground } from 'components/background/BluePinkyBackground';
import { ThreeDots } from 'components/ThreeDots';
import { FormInput } from 'components/FormInput';
import { username as usernameValidation } from 'helpers/validations';

import { ROUTES } from 'config/routes';
import { globalUser } from 'state/globalUser';

import logo from 'assets/logo/hoshii.svg';
import { useInitResetPassword } from 'features/user/hooks/useInitResetPassword';
import { globalAlertData } from 'state/globalAlertData';

interface SigninFormValues {
  username: string;
}

const formInitialValues = {
  username: '',
};

const formValidationSchema = object({
  username: usernameValidation().required(),
});

const SendResetPasswordLinkPage = () => {
  const navigate = useNavigate();

  const { isLoading, initResetPassword } = useInitResetPassword();

  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    inputRef.current?.focus();

    const handleKeyDown = (event: KeyboardEvent) => {
      if (buttonRef.current && event.key === 'Enter') {
        buttonRef.current?.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (globalUser.isSignedIn) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return (
    <Page isLoading={isLoading} hideMenu hideHeader>
      <div className="flex flex-1 items-start justify-center p-lg">
        <div className="relative flex h-full w-full items-center justify-center space-y-5">
          <BluePinkyBackground />

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[30%] 3xl:max-w-[25%] 4xl:max-w-[20%]">
            <div className="space-y-3 bg-white px-6 py-12 shadow-md sm:rounded-lg sm:px-12">
              <div className="mb-3">
                <div className="flex w-full items-center justify-center">
                  <img
                    className="relative mr-2 h-[2.5rem] w-auto sm:h-[3rem]"
                    src={logo}
                    alt=""
                    style={{
                      filter:
                        'brightness(0) saturate(100%) invert(12%) sepia(18%) saturate(5372%) hue-rotate(193deg) brightness(97%) contrast(96%)',
                    }}
                  />
                </div>
                <h1 className="font-bold pt-4">Reset password</h1>
                <p className="text-xs text-gray-500 pt-1">
                  Enter your username to receive a link to reset your password.
                </p>
              </div>
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={(values: SigninFormValues) => {
                  initResetPassword(values.username)
                    .then(() => {
                      globalAlertData.create(
                        'Password reset link sent successfully',
                        '',
                        '#00c853',
                      );
                      navigate(ROUTES.SIGNIN(values.username));
                    });
                }}
                enableReinitialize
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                }) => (
                  <div className="space-y-6">
                    <FormInput
                      ref={inputRef}
                      field="username"
                      required
                      disabled={isLoading}
                      label="Username"
                      value={values.username}
                      error={errors.username}
                      touched={touched.username}
                      onChange={handleChange('username')}
                      onBlur={handleBlur('username')}
                    />

                    <div>
                      <button
                        type="button"
                        onClick={() => handleSubmit()}
                        ref={buttonRef}
                        className="flex w-full justify-center rounded-md bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                      >
                        <span>
                          {isLoading ? (
                            <div className="flex max-h-[24px] min-h-[24px] flex-col items-center justify-center">
                              <ThreeDots />
                            </div>
                          ) : (
                            'Reset password'
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SendResetPasswordLinkPage;

interface Props {
  headers: string[];
  contents: string[][];
}

const ImportPreviewTable = ({ headers, contents }: Props) => (
  <div className="flow-root">
    <div className="overflow-x-auto">
      <div className="inline-block min-w-full py-smd align-middle">
        <div
          className="overflow-y-auto"
          style={{ maxHeight: 'calc(80vh - 250px)' }}
        >
          <table className="min-w-full table-fixed divide-y divide-gray-300">
            <thead className="sticky top-0 z-10 bg-white drop-shadow-md">
              <tr>
                {headers.map((header, idx) => (
                  <th
                    key={header + idx.toString()}
                    scope="col"
                    className="px-slg text-left text-sm font-semibold text-gray-900"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {contents.map((content) => (
                <tr key={`row-${content[0]}`} className="">
                  {content.slice(1).map((item, idx) => (
                    <td
                      key={`row-${content[0]}-item-${parseInt(content[0], 10) + idx}`}
                      className="whitespace-nowrap px-slg py-lg text-sm text-gray-500"
                    >
                      {item}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

export { ImportPreviewTable };

import { AnimatePresence, Reorder } from 'framer-motion';

import { Accordion } from '@mantine/core';
import { FieldSpec } from '../../../../../../models/Schema';
import { GroupedOption } from '../../../../../settings/SettingCard/ui/GroupedCombobox/types';

import { UniversalField } from '../../../../../common/UniversalField';
import BodyBase from './_BodyBase';
import { inputTypeToUniversalFieldType } from '../../../../../../helpers/schema';

interface Props {
  parentPath: string;
  fieldSpecs: FieldSpec[];
  setFieldSpecs: React.Dispatch<React.SetStateAction<FieldSpec[]>>;
  groupedOptions: GroupedOption[];
}

const ProductBody = ({
  parentPath, fieldSpecs, setFieldSpecs, groupedOptions,
}: Props) => (
  <BodyBase
    parentPath={parentPath}
    fieldSpecs={fieldSpecs}
    setFieldSpecs={setFieldSpecs}
    groupedOptions={groupedOptions}
    previewNode={(
      <div className="w-full overflow-hidden">
        <div className="w-full overflow-hidden rounded-tl-xl bg-white border-l border-l-gray-200">
          <div className="flex bg-primary-600/80 ring-1 ring-white/5">
            <div className="-mb-px flex text-sm font-medium leading-6">
              <div className="px-4 py-2 text-white">
                Preview
              </div>
            </div>
          </div>
          <div className="px-6 pb-14 pt-6">
            <Accordion defaultValue="product" variant="contained">
              <Accordion.Item
                value="product"
              >
                <Accordion.Control
                  style={{ backgroundColor: 'white' }}
                  className="hover:bg-green-500"
                >
                  <div className="flex space-x-2">
                    <p>
                      Product name
                    </p>
                  </div>
                </Accordion.Control>
                <Accordion.Panel
                  style={{ backgroundColor: 'white' }}
                >
                  <Reorder.Group
                    axis="y"
                    values={fieldSpecs}
                    onReorder={(newOrder) => setFieldSpecs((_fieldSpecs) => {
                      const droppedFieldSpecs = _fieldSpecs.filter(
                        (fieldSpec) => fieldSpec.lastCardableParentPath !== parentPath,
                      );

                      return [...droppedFieldSpecs, ...newOrder];
                    })}
                    initial="hidden"
                    animate="visible"
                    variants={{
                      visible: {
                        transition: {
                          staggerChildren: 0.5,
                        },
                      },
                    }}
                    className="flex flex-col gap-sm w-full"
                  >
                    <AnimatePresence>
                      <div key="preview" className="gap-y-sm w-full">
                        {fieldSpecs.filter((fieldSpec) => fieldSpec.name).map((fieldSpec) => (
                          <Reorder.Item
                            key={`preview-${fieldSpec.uiId}`}
                            id={`content-${fieldSpec.uiId}`}
                            className="flex py-2 px-1 items-end flex-1"
                            value={fieldSpec}
                            exit="exit"
                          >
                            <UniversalField
                              className="w-full"
                              itemClassName="w-full"
                              type={inputTypeToUniversalFieldType(fieldSpec.inputType, fieldSpec.type)}
                              label={fieldSpec.name}
                              placeHolder={fieldSpec.examples?.length ? fieldSpec.examples.join(', ') : fieldSpec.path}
                              shouldScroll={!!fieldSpec.added}
                              validation={fieldSpec.validation}
                              readOnly
                            />
                          </Reorder.Item>
                        ))}
                      </div>
                    </AnimatePresence>
                  </Reorder.Group>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    )}
  />
);

export default ProductBody;

import CountUp from 'react-countup';

import { Card } from 'components/common/Card';
// import { TagVariant } from '../../../types/tag';

interface Props {
  icon: string;
  title: string;
  count: number;
}

const AdamProcessedCard = ({ icon, title, count }: Props) => (
  <Card
    className="row-span-1 aspect-square"
    // tagProps={{
    //   tagTitle: 'Month',
    //   tagVariant: TagVariant.GRAY,
    //   isTagLoading: false,
    //   hideCircle: true,
    //   className: 'top-2 right-2',
    // }}
  >
    <Card.Body className="flex-col justify-between gap-6 h-full bg-cover bg-[right_top_-1.7rem] bg-no-repeat channel-stats-bg px-2 py-2">
      <img alt="" className="w-7 mt-4 ms-5" src={icon} />
      <div className="flex flex-col gap-1 pb-4 px-5">
        <span className="text-3xl font-semibold text-gray-900">
          <CountUp
            end={count}
            duration={2}
            separator=","
            formattingFn={(value) => {
              if (value > 1000) {
                return `${(value / 1000).toFixed(1)}k`;
              }
              return value.toString();
            }}
          />
        </span>
        <span className="text-xxs font-medium text-gray-600">{title}</span>
      </div>
    </Card.Body>
  </Card>
);

export default AdamProcessedCard;

import {
  useState, useCallback, useRef, useEffect,
} from 'react';

import { httpGet } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { Event } from '../../models/Event';

interface Props {
  preventInitialFetch?: boolean;
}

type LoadEventsProps = {
  get_notification_events?: boolean;
  consume?: boolean;
  giveErrorFeedback?: boolean;
};

const useFetchEvents = ({ preventInitialFetch = false }: Props) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const eventsPaginationCursor = useRef<string | null>(null);

  const loadEvents = useCallback(
    ({
      get_notification_events = true,
      consume = false,
      giveErrorFeedback = true,
    }: LoadEventsProps): Promise<Event[]> => {
      setIsLoading(true);
      return httpGet('/events', {
        params: {
          get_notification_events,
          consume,
          limit: 20,
          cursor: eventsPaginationCursor,
        },
      })
        .then((response) => {
          const data: Event[] = response.data.result;

          if (data) {
            setEvents((_events) => [..._events, ...data]);
          }

          eventsPaginationCursor.current = response.data.cursor;

          return data;
        })
        .catch((error) => {
          if (giveErrorFeedback) genericErrorFeedback('Error getting events')(error);
          return Promise.reject();
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  useEffect(() => {
    if (preventInitialFetch) return () => {};

    loadEvents({});

    return () => {};
  }, [loadEvents, preventInitialFetch]);

  return {
    events,
    isLoading,
    loadEvents,
  };
};

export { useFetchEvents, LoadEventsProps };

import { makeAutoObservable } from 'mobx';
import { httpGet } from '../helpers/xhr';
import { genericErrorFeedback } from '../helpers/errors';
import { Operation } from '../models/Lro';
import { WorkflowRun } from '../models/Workflow';

export interface LROData {
  data: any;
  group: string;
}

class PendingLROsStore {
  pendingLROs: Map<string, LROData> = new Map();

  pendingLROsByGroup: Map<string, Map<string, LROData>> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  addPendingLRO(lroId: string, data: LROData) {
    this.pendingLROs.set(lroId, data);
    const group = data.group;
    if (!this.pendingLROsByGroup.has(group)) {
      this.pendingLROsByGroup.set(group, new Map());
    }
    this.pendingLROsByGroup.get(group).set(lroId, data);
  }

  removePendingLRO(lroId: string) {
    const group = this.pendingLROs.get(lroId).group;
    this.pendingLROs.delete(lroId);
    this.pendingLROsByGroup.get(group)?.delete(lroId);
    if (this.pendingLROsByGroup.get(group)?.size === 0) {
      this.pendingLROsByGroup.delete(group);
    }
  }
}

export const pendingLROsStore = new PendingLROsStore();

export const pollPendingLROGroup = async (group: string) => {
  if (pendingLROsStore.pendingLROsByGroup.size > 0) {
    const lroPromises = Array.from(pendingLROsStore.pendingLROsByGroup.get(group)).map(async ([lroId]) => {
      try {
        const response = await httpGet(`/lros/${lroId}`);
        const lro: Operation<WorkflowRun> = response.data;
        if (lro.isDone) {
          pendingLROsStore.removePendingLRO(lroId);
          return lro;
        }
      } catch (err) {
        genericErrorFeedback(`Error checking LRO status for group ${group}: ${err}`);
        pendingLROsStore.removePendingLRO(lroId);
      }

      return null;
    });

    const lros = await Promise.all(lroPromises);
    return lros.filter((lro) => lro !== null);
  }

  return [];
};

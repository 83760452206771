import { useCallback, useMemo } from 'react';

import LoadingOverlay from 'components/ui/LoadingOverlay';

import { useOrderContext } from 'features/order/contexts/useOrderContext';

import { useAssignDraft } from 'features/order/api/useAssignDraft';
import { useFetchOrderDrafts } from 'features/order/api/useFetchOrderDrafts';

import { AssignedStatus } from 'types/order';

import { getAssignedStatus } from 'features/order/utils/order';
import { isZeroId } from 'helpers/objectId';
import { globalUser } from 'state/globalUser';

import { Assignee } from '../Assignee';
import { Body } from './Body';
import BodyWrapper from './Body/BodyWrapper';

interface Props {
  isOrderProcessingMode?: boolean;
  isNewOrderDraft?: boolean;
  onOrderProcessed?: (orderId: string) => void; // in case, some action is required
}

const OrderDraftPanelContent = ({
  isOrderProcessingMode = false,
  isNewOrderDraft = false,
  onOrderProcessed,
}: Props) => {
  const { groupOrders, setGroupOrders, selectedIndex } = useOrderContext();

  const { isLoading: isAssignLoading, assignTeamMember } = useAssignDraft();
  const { isLoading: isGroupLoading, loadOrderDrafts } = useFetchOrderDrafts({
    preventInitialFetch: true,
  });

  const order = useMemo(() => {
    const _order = groupOrders[selectedIndex];
    return {
      ..._order,
      products: (_order?.products || []).map((product) => ({ ...product })),
    };
  }, [groupOrders, selectedIndex]);

  const closeOrderDraftPanel = useCallback(
    (orderId: string = order?.id) => {
      setGroupOrders([]);

      onOrderProcessed?.(orderId);
    },
    [
      setGroupOrders,
      onOrderProcessed,
      order?.id,
    ],
  );

  const assignedStatus: AssignedStatus = useMemo(
    () => getAssignedStatus(order?.assigneeId),
    [order?.assigneeId],
  );

  const onAssignButtonClick = useCallback(
    async (
      teamMemberId: string,
      teamMemberName: string,
      assignedComment: string,
    ) => {
      try {
        const result = await assignTeamMember({
          order,
          teamMemberId,
          teamMemberName,
          assignedComment,
        });

        setGroupOrders((_groupOrders) => _groupOrders.map((o) => (o.id === result?.id ? result : o)),
        );

        if (result && teamMemberId !== globalUser.id && isOrderProcessingMode) {
          onOrderProcessed(order?.id);
        }

        if (
          result
          && teamMemberId !== globalUser.id
          && !isOrderProcessingMode
        ) {
          closeOrderDraftPanel();
        }

        if (result.groupId && !isZeroId(result.groupId)) {
          // Get order groups
          loadOrderDrafts(false, true, result.groupId).then((orders) => {
            setGroupOrders([result, ...(orders || [])]);
          });
        }
      } catch (error) {
        /* empty */
      }
    },
    [
      assignTeamMember,
      closeOrderDraftPanel,
      isOrderProcessingMode,
      loadOrderDrafts,
      onOrderProcessed,
      order,
      setGroupOrders,
    ],
  );

  if (isAssignLoading || isGroupLoading) {
    return <LoadingOverlay visible displayText="Assigning order" />;
  }

  if (!order || !order.assigneeId) {
    return (
      <LoadingOverlay
        visible
        displayText="Loading order"
      />
    );
  }

  if (assignedStatus === AssignedStatus.OTHER) {
    return (
      <div className="relative flex h-full flex-1 items-center justify-center">
        <div className="flex flex-col space-y-2">
          <h1 className="text-center text-title-md">Oops!</h1>
          <p>This order is already assigned to other team member.</p>
        </div>
      </div>
    );
  }

  if (assignedStatus === AssignedStatus.UNASSIGNED) {
    return (
      <Assignee
        order={order}
        assignOrder={onAssignButtonClick}
      />
    );
  }

  return (
    <BodyWrapper>
      <Body
        orderDraftsProcessingMode
        isNewOrderDraft={isNewOrderDraft}
        onCancel={closeOrderDraftPanel}
      />
    </BodyWrapper>
  );
};

const OrderDraftPanel = (props: Props) => (
  <div className="flex h-full w-full flex-col overflow-hidden bg-white">
    <div className="flex w-full flex-1 flex-col overflow-hidden p-lg">
      <OrderDraftPanelContent {...props} />
    </div>
  </div>
);

export default OrderDraftPanel;

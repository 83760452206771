import { UserIcon } from '@heroicons/react/20/solid';

import adam from '../../../../assets/adam-face.png';
import { useMessagesContext } from '../../../../contexts/useMessagesContext';
import MessageFilter from '../../../messages/MessageFilter';

interface Props {
  title: string;
  isAiChat?: boolean;
}

const ChatHeading = ({
  title,
  isAiChat,
}: Props) => {
  const { messagesFilter, setMessagesFilter } = useMessagesContext();
  return (
    <div className="flex h-14 items-center border-b bg-white px-3 py-2 shadow-sm space-x-2">
      {isAiChat ? (
        <img
          src={adam}
          alt="adam"
          className="w-10 aspect-square rounded-full object-cover"
        />
      ) : (
        <div className="aspect-square rounded-full bg-gray-200">
          <UserIcon className="w-10 aspect-square text-white" />
        </div>
      )}
      <p className="font-semibold flex-1">{title}</p>

      <MessageFilter
        defaultFilter={messagesFilter}
        setMessagesFilter={setMessagesFilter}
      />
    </div>
  );
};

export default ChatHeading;

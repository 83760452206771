import { TypeRef } from '../types/type-ref';

export type FieldType = 'string' | 'numeric' | 'bool' | 'struct' | 'array';

export type InputType = 'standard' | 'queryable' | 'multivalue' | 'datetime' | 'readonly';

export enum StandardFieldType {
  StandardFieldTypeProductName = 'product_name',
  StandardFieldTypeProductUnit = 'product_unit',
  StandardFieldTypeProductIdOrSku = 'product_id_or_sku',
  StandardFieldTypeProductQuantity = 'product_quantity',
  StandardFieldTypeOrderDeliveryDateTime = 'order_delivery_datetime',
}

type FieldValidator = {
  min?: number;

  max?: number;

  required: boolean;
};

class TypeSchema {
  name: string;

  typeRef: TypeRef;

  fields: FieldSchema[];
}

export class FieldSchema {
  name: string;

  description?: string;

  path: string; // Check

  type: FieldType; // check

  typeSchema?: TypeSchema;

  validators?: FieldValidator[];

  isStandard: boolean;

  isCardable: boolean;

  isErpBindable: boolean;

  inputType: InputType;

  standardFieldType: string;

  queryURLPath: string;

  modelPath: string;

  autoMatchedModelPath: string;

  lastCardableParentPath: string;

  // UI onlu field
  uiId: string;

  added?: boolean;
}

export class Schema {
  typeRef: TypeRef;

  name: string;

  fields: FieldSchema[];
}

export class FieldSpec {
  name?: string;

  description?: string;

  examples?: string[];

  path: string;

  type: FieldType;

  validation?: string;

  isErpBindable: boolean;

  modelPath: string;

  modelAutoMatchedPath: string;

  lastCardableParentPath: string;

  // UI onlu field
  uiId: string;

  isRemovable?: boolean;

  inputType: InputType;

  added?: boolean;
}

export class TypeSpec {
  typeRef: TypeRef;

  fields: FieldSpec[];
}

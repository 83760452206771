import { twMerge } from 'tailwind-merge';
import { SettingSection } from '../types';

interface Props {
  currentSectionId: string;
  section: SettingSection;

  isSubSection?: boolean;
}

const FeedButton = ({
  currentSectionId,
  section,
  isSubSection = false,
}: Props) => (
  <button
    type="button"
    onClick={() => {
      document
        .getElementById(section.sectionId)
        ?.scrollIntoView({ behavior: 'smooth' });
    }}
    className={twMerge(
      'flex items-center gap-1.5 rounded-md border border-transparent py-2.5 pl-2.5 pr-2.5 text-sm font-medium text-gray-700 hover:rounded-md hover:text-primary-500',
      section.sectionId === currentSectionId
        ? 'border-gray-100 bg-[#f9f9f9] text-primary-500'
        : '',
      isSubSection ? 'gap-3.5' : '',
    )}
  >
    <span
      className={twMerge(
        'relative flex w-1.5',
        section.sectionId === currentSectionId
          ? 'before:absolute before:left-px before:top-0 before:size-1.5 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:bg-primary-500'
          : '',
      )}
    />
    {section.title}
  </button>
);

export default FeedButton;

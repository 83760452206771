// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FK_44DijwNyJMXmPgsdb {\n  width: 10px;\n  height: 10px;\n  transition: width 250ms ease;\n  background-color: blue;\n}\n\n.FK_44DijwNyJMXmPgsdb[data-active] {\n    width: 20px;\n  }\n\n.A0ZTpIpljfntTjqhYeQ6 {\n  transition: opacity 150ms ease;\n  opacity: 0;\n}\n\n.A0ZTpIpljfntTjqhYeQ6[data-inactive] {\n    opacity: 0;\n    cursor: default;\n  }\n\n.C4NGs6Z8iKO6JEVI6CWE:hover .A0ZTpIpljfntTjqhYeQ6 {\n      opacity: 1;\n    }", "",{"version":3,"sources":["webpack://./src/components/dashboard/IntentCard/IntentCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,4BAA4B;EAC5B,sBAAsB;AAKxB;;AAHE;IACE,WAAW;EACb;;AAGF;EACE,8BAA8B;EAC9B,UAAU;AAMZ;;AAJE;IACE,UAAU;IACV,eAAe;EACjB;;AAKE;MACE,UAAU;IACZ","sourcesContent":[".indicator {\n  width: 10px;\n  height: 10px;\n  transition: width 250ms ease;\n  background-color: blue;\n\n  &[data-active] {\n    width: 20px;\n  }\n}\n\n.controls {\n  transition: opacity 150ms ease;\n  opacity: 0;\n\n  &[data-inactive] {\n    opacity: 0;\n    cursor: default;\n  }\n}\n\n.root {\n  &:hover {\n    .controls {\n      opacity: 1;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicator": "FK_44DijwNyJMXmPgsdb",
	"controls": "A0ZTpIpljfntTjqhYeQ6",
	"root": "C4NGs6Z8iKO6JEVI6CWE"
};
export default ___CSS_LOADER_EXPORT___;

import { useCallback, useState } from 'react';

import { ImportMain } from 'components/matcher/ImportMain';
import {
  ProductConfig,
  ProductHeaderMatcher,
} from 'models/matcher/ProductHeaderMatcher';
import { MatchStatus } from 'models/matcher/BaseHeaderMatcher';

interface Props {
  navigateToProductsList: () => void;
}

const ProductsImportPage = ({ navigateToProductsList }: Props) => {
  const [confirmedHeaders, setConfirmedHeaders] = useState<string[]>([]);
  const [confirmedProducts, setConfirmedProducts] = useState<string[][]>([]);

  const [matchers, setMatchers] = useState<ProductHeaderMatcher[]>([]);
  const [selectedMatcher, setSelectedMatcher] = useState<ProductHeaderMatcher>(null);

  const getMatchingStates = useCallback(
    (matcher: ProductHeaderMatcher, previewHeaders: string[]) => {
      const temp: MatchStatus[] = [];
      temp.unshift({
        title: 'Category',
        key: matcher.category,
        matched:
          !matcher.category
          || matcher.category === ''
          || previewHeaders.includes(matcher.category),
      });

      temp.unshift({
        title: 'Deleted',
        key: matcher.deleted,
        matched:
          !matcher.deleted
          || matcher.deleted === ''
          || previewHeaders.includes(matcher.deleted),
      });

      temp.unshift({
        title: 'Disabled',
        key: matcher.disabled,
        matched:
          !matcher.disabled
          || matcher.disabled === ''
          || previewHeaders.includes(matcher.disabled),
      });

      temp.unshift({
        title: 'Min Order Qty',
        key: matcher.minOrderQty,
        matched:
          !matcher.minOrderQty
          || matcher.minOrderQty === ''
          || previewHeaders.includes(matcher.minOrderQty),
      });

      temp.unshift({
        title: 'Unit',
        key: !matcher.units ? '' : matcher.units.join(','),
        matched:
          !matcher.units
          || matcher.units.length === 0
          || previewHeaders.some((val) => matcher.units.includes(val)),
      });

      temp.unshift({
        title: 'Name',
        key: matcher.name,
        matched:
          !matcher.name
          || matcher.name === ''
          || previewHeaders.includes(matcher.name),
      });

      temp.unshift({
        title: 'Price',
        key: matcher.price,
        matched:
          !matcher.price
          || matcher.price === ''
          || previewHeaders.includes(matcher.price),
      });

      temp.unshift({
        title: 'ID or SKU',
        key: matcher.idOrSku,
        matched:
          !matcher.idOrSku
          || matcher.idOrSku === ''
          || previewHeaders.includes(matcher.idOrSku),
      });

      return temp;
    },
    [],
  );

  const setConfirmContent = useCallback(
    (previewHeaders: string[], previewProducts: string[][]) => {
      const products: string[][] = [];

      const idOrSKUIndex = previewHeaders.indexOf(selectedMatcher.idOrSku);
      const nameIndex = previewHeaders.indexOf(selectedMatcher.name);
      const priceIndex = previewHeaders.indexOf(selectedMatcher.price);
      const minOrderQtyIndex = previewHeaders.indexOf(
        selectedMatcher.minOrderQty,
      );
      const disabledIndex = previewHeaders.indexOf(selectedMatcher.disabled);
      const deletedIndex = previewHeaders.indexOf(selectedMatcher.deleted);
      const categorydIndex = previewHeaders.indexOf(selectedMatcher.category);

      const unitsIndices = selectedMatcher.units.map((u) => previewHeaders.indexOf(u),
      );

      for (let i = 0; i < previewProducts.length; i += 1) {
        const temp: string[] = [];
        temp.push(i.toString());

        temp.push(
          idOrSKUIndex !== -1
            ? previewProducts[i][idOrSKUIndex + 1].toString()
            : '',
        );
        temp.push(
          nameIndex !== -1 ? previewProducts[i][nameIndex + 1].toString() : '',
        );
        temp.push(
          priceIndex !== -1
            ? previewProducts[i][priceIndex + 1].toString()
            : '',
        );

        const tempUnits = unitsIndices
          .filter((uI) => uI !== -1)
          .map((uI) => previewProducts[i][uI + 1])
          .join(',');

        temp.push(tempUnits);

        temp.push(
          minOrderQtyIndex !== -1
            ? previewProducts[i][minOrderQtyIndex + 1].toString()
            : '',
        );
        temp.push(
          disabledIndex !== -1
            ? previewProducts[i][disabledIndex + 1].toString()
            : '',
        );
        temp.push(
          deletedIndex !== -1
            ? previewProducts[i][deletedIndex + 1].toString()
            : '',
        );
        temp.push(
          categorydIndex !== -1
            ? previewProducts[i][categorydIndex + 1].toString()
            : '',
        );

        products.push(temp);
      }

      setConfirmedHeaders([
        ...ProductConfig.required.map((i) => i.title),
        ...ProductConfig.optional.map((i) => i.title),
      ]);
      setConfirmedProducts(products);
    },
    [selectedMatcher],
  );

  return (
    <ImportMain
      matchers={matchers}
      setMatchers={setMatchers}
      selectedMatcher={selectedMatcher}
      setSelectedMatcher={setSelectedMatcher}
      getMatchingStates={getMatchingStates}
      model="product"
      modelConfig={ProductConfig}
      confirmedHeaders={confirmedHeaders}
      confirmedItems={confirmedProducts}
      setConfirmContent={setConfirmContent}
      navigateToListPage={navigateToProductsList}
    />
  );
};

export default ProductsImportPage;

import { ConfirmModalBase } from 'components/modal';
import {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react';

type Ref = {
  openModal: () => void;
};

interface Props {
  children: React.ReactNode;

  title: string;
  description: string;

  onConfirm: () => void;
}

const ConfirmModalWrapperBase = forwardRef(({
  children, title, description, onConfirm,
}: Props, ref: React.Ref<Ref>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  return (
    <>
      {children}

      <ConfirmModalBase
        open={isModalOpen}
        setOpen={setIsModalOpen}
        title={title}
        description={description}
        onConfirm={onConfirm}
      />
    </>
  );
});

export default ConfirmModalWrapperBase;

const chf = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'CHF',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const eur = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatPrice = (amount: number, currency: string) => {
  switch (currency) {
    case 'EUR':
      return eur.format(amount);
    case 'CHF':
    default:
      return chf.format(amount);
  }
};

export { formatPrice };

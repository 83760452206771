import { useSearchParams } from 'react-router-dom';

import { ResetPasswordPage } from '../pages';

const ResetPasswordScreen = () => {
  const [searchParams] = useSearchParams();

  const appLink = searchParams.get('appLink');

  return <ResetPasswordPage appLink={appLink} />;
};

export default ResetPasswordScreen;

import {
  useState, useCallback, useRef, useEffect,
} from 'react';

import { httpGet } from '../helpers/xhr';
import { User } from '../models/User';
import { doesExist } from '../helpers/comparison';
import { globalUser } from '../state/globalUser';

const useFetchTeamMembers = (preventFetch: boolean = false) => {
  const [teamMembers, setTeamMembers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const teamMemberPaginationCursor = useRef(null);

  const loadTeamMembers = useCallback(
    async (searchValue?: string) => {
      if (preventFetch) return;

      setIsLoading(true);

      if (doesExist(searchValue)) {
        teamMemberPaginationCursor.current = null;
      }
      const response = await httpGet('/businesses/me/users', {
        params: {
          limit: 10,
          search_query: doesExist(searchValue, '') ? searchValue : null,
          cursor: teamMemberPaginationCursor.current,
        },
      });
      if (response.status === 200) {
        // To filter those users created via invitation link and should be fixed
        const tempTeamMembers = ((response.data.result as User[]) || []).filter(
          (u) => u.firstName && u.lastName,
        );
        const index = tempTeamMembers.findIndex((u) => u.id === globalUser.id);

        if (index !== -1) {
          const [memberToMove] = tempTeamMembers.splice(index, 1);
          tempTeamMembers.unshift(memberToMove);
        }
        setTeamMembers(tempTeamMembers);
        teamMemberPaginationCursor.current = response.data.cursor;
      }

      setIsLoading(false);
    },
    [preventFetch],
  );

  useEffect(() => {
    if (!preventFetch) loadTeamMembers();
  }, [loadTeamMembers, preventFetch]);

  return {
    teamMembers,
    setTeamMembers,
    isLoading,
    loadTeamMembers,
  };
};

export { useFetchTeamMembers };

import { useEffect, useMemo } from 'react';
import { AnimatePresence, Reorder } from 'framer-motion';
import { Tooltip } from '@mantine/core';
import { PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';

import { GroupedOption } from '../../../../../settings/SettingCard/ui/GroupedCombobox/types';
import ContentItem from './_ContentItem';
import { Button } from '../../../../../ui/Button';
import { FieldSpec } from '../../../../../../models/Schema';

interface Props {
  parentPath: string;
  fieldSpecs: FieldSpec[];
  setFieldSpecs: React.Dispatch<React.SetStateAction<FieldSpec[]>>;
  groupedOptions: GroupedOption[];

  previewNode: React.ReactNode;
}

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }, // Add exit animation
};

const BodyBase = ({
  parentPath,
  fieldSpecs,
  setFieldSpecs,
  groupedOptions: _groupedOptions,
  previewNode,
}: Props) => {
  const usedErpFields = useMemo(
    () => fieldSpecs.map((fieldSpec) => fieldSpec.path),
    [fieldSpecs],
  );

  const onAddButtonClicked = () => {
    setFieldSpecs((_fieldSpecs) => [
      ..._fieldSpecs,
      {
        name: '',
        path: '',
        type: 'string',
        isErpBindable: false,
        uiId: uuidv4(),
        inputType: 'queryable',
        lastCardableParentPath: parentPath,
        added: true,
      } as FieldSpec,
    ]);
  };

  useEffect(() => {
    if (fieldSpecs.length === 0) {
      setFieldSpecs((_fieldSpecs) => [
        ..._fieldSpecs,
        {
          name: '',
          path: '',
          type: 'string',
          isErpBindable: false,
          uiId: uuidv4(),
          inputType: 'queryable',
          lastCardableParentPath: parentPath,
          added: true,
        } as FieldSpec,
      ]);
    }
  }, [fieldSpecs, parentPath, setFieldSpecs]);

  return (
    <div className="flex w-full">
      <div className="w-[50%] px-2 space-y-4 overflow-hidden 2xl:w-[55%]">
        <Reorder.Group
          axis="y"
          values={fieldSpecs}
          onReorder={(newOrder) => setFieldSpecs((_fieldSpecs) => {
            const droppedFieldSpecs = _fieldSpecs.filter(
              (fieldSpec) => fieldSpec.lastCardableParentPath !== parentPath
              && !newOrder.some((f) => f.uiId === fieldSpec.uiId),
            );

            return [...droppedFieldSpecs, ...newOrder];
          })}
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="w-full space-y-4"
        >
          <div className="grid w-full grid-cols-[repeat(21,minmax(0,1fr))] items-center gap-4 px-1">
            <p className="col-span-8 col-start-2 text-xs font-semibold 2xl:text-sm">
              Field name
            </p>
            <p className="col-span-8 w-full text-xs font-semibold 2xl:text-sm">
              ERP field name
            </p>
            <div className="col-span-2 flex items-center justify-center">
              <div className="flex space-x-1">
                <p className="text-xs font-semibold 2xl:text-sm">Push</p>
                <Tooltip label="Indicate whether the field should be pushed to the ERP">
                  <QuestionMarkCircleIcon className="h-4 w-4 text-neutral-400" />
                </Tooltip>
              </div>
            </div>
          </div>
          <AnimatePresence>
            {fieldSpecs.map((fieldSpec) => (
              <ContentItem
                key={`content-${fieldSpec.uiId}`}
                fieldSpec={fieldSpec}
                setFieldSpecs={setFieldSpecs}
                groupedOptions={_groupedOptions.map((group) => ({
                  ...group,
                  options: group.options.filter(
                    (opt) => opt.value === fieldSpec.path || !usedErpFields.includes(opt.value),
                  ),
                }))}
                uiId={fieldSpec.uiId}
              />
            ))}
            {/* Add button */}
            <Reorder.Item
              value={null}
              id="button-add"
              className="grid w-full grid-cols-[repeat(21,minmax(0,1fr))] items-center gap-4 px-1"
              variants={itemVariants}
              exit="exit"
              draggable={false}
            >
              <div className="col-start-2 col-[16_/_span_16] flex w-full justify-center">
                <Button
                  icon={<PlusIcon className="h-6 w-6" />}
                  variant="small"
                  theme="secondary"
                  radius="sm"
                  onClick={onAddButtonClicked}
                />
              </div>
            </Reorder.Item>
          </AnimatePresence>
        </Reorder.Group>
      </div>

      <div className="w-[50%] px-2 space-y-4 overflow-hidden 2xl:w-[45%]">
        {previewNode}
      </div>
    </div>
  );
};

export default BodyBase;

import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { globalOrderDrafts } from 'state/globalOrderDrafts';
import { Page } from 'components/layout/Page/Page';
import { useFetchOrderDrafts } from 'features/order/api/useFetchOrderDrafts';
import { ListNavigator } from 'components/common/ListNavigator';
import { useOrderDraftNavigation } from 'features/order/hooks/useOrderDraftNavigation';
import { OrderProvider } from 'features/order/contexts/useOrderContext';
import { PlayRecordingProvider } from 'contexts/usePlayRecordingIndex';
import { TeamMembersProvider } from 'contexts/useTeamMembersContext';
import { globalUser } from 'state/globalUser';
import {
  MessageProviderType,
  MessagesProvider,
} from 'contexts/useMessagesContext';
import { BusinessSettingsProvider } from 'contexts/useBusinessSettingsContext';
import { SchemasProvider } from 'contexts/useSchemasContext';

import { ProcessOrderDrafts } from 'features/order/components/process-order-draft';
import { ProcessOrderProvider } from 'features/order/contexts/useProcessOrderContext';
import { isZeroId } from 'helpers/objectId';

interface Props {
  navigateToHome: () => void;
}

const ProcessOrderDraftsPage = observer(({ navigateToHome }: Props) => {
  const {
    orderDrafts,
    setOrderDrafts,
    isLoading: isOrderDraftsLoading,
    loadOrderDrafts,
    reloadOrderDrafts,
  } = useFetchOrderDrafts({});

  const {
    currentIndex,
    setCurrentIndex,
    selectNextOrderDraft,
    selectPrevOrderDraft,
    onOrderDraftProcessed,
  } = useOrderDraftNavigation({
    orderDrafts,
    setOrderDrafts,
    loadOrderDrafts,
    isOrderDraftsLoading,
  });

  const order = useMemo(() => {
    if (currentIndex !== -1) {
      return orderDrafts[currentIndex];
    }
    return null;
  }, [orderDrafts, currentIndex]);

  const messageReferenceId = useMemo(() => {
    if (order?.groupId && !isZeroId(order.groupId)) {
      return order.groupId;
    }

    return order?.id;
  }, [order?.groupId, order?.id]);

  // if not draft is left, go to home
  useEffect(() => {
    if (!isOrderDraftsLoading && globalOrderDrafts.count === 0) {
      navigateToHome();
    }

    if (globalOrderDrafts.isLoaded && globalOrderDrafts.count === 0) {
      navigateToHome();
    }
  }, [isOrderDraftsLoading, navigateToHome]);

  useEffect(() => {
    if (!globalOrderDrafts.isLoaded && globalOrderDrafts.count === 0) {
      globalOrderDrafts.setUserId(globalUser.id);
      globalOrderDrafts.loadOrderDraftsCount();
    }
  }, []);

  const onOrderDraftRetried = async (retriedOrderId: string) => {
    const latestOrderDrafts = await reloadOrderDrafts();
    const updatedIndex = latestOrderDrafts.findIndex((draft) => draft.id === retriedOrderId);
    if (updatedIndex !== -1) {
      setCurrentIndex(updatedIndex);
    }
  };

  return (
    <Page isLoading={false} contentWithBorder>
      <ListNavigator
        listLength={globalOrderDrafts.count || orderDrafts.length}
        currentIndex={currentIndex}
        onPrev={() => selectPrevOrderDraft()}
        onNext={() => selectNextOrderDraft()}
      />
      <PlayRecordingProvider>
        <MessagesProvider
          providerType={MessageProviderType.BY_REFERENCE_ID}
          referenceId={messageReferenceId}
        >
          <BusinessSettingsProvider>
            <OrderProvider>
              <ProcessOrderProvider onOrderDraftProcessed={onOrderDraftProcessed}>
                <SchemasProvider>
                  <TeamMembersProvider>
                    <ProcessOrderDrafts
                      order={order}
                      isOrderLoading={isOrderDraftsLoading}
                      onOrderRetried={onOrderDraftRetried}
                    />
                  </TeamMembersProvider>
                </SchemasProvider>
              </ProcessOrderProvider>
            </OrderProvider>
          </BusinessSettingsProvider>
        </MessagesProvider>
      </PlayRecordingProvider>
    </Page>
  );
});

export default ProcessOrderDraftsPage;

export enum Workflow {
  ORDER = 'Order',
  SUPPLIER_NOTE = 'Supplier Note',
}

export enum Pipeline {
  ORDER = 'order_parser',
  SUPPLIER_NOTE = 'supplier_note_parser',
}

export enum Action {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

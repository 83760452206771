import {
  memo,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Accordion, Switch, Popover,
  Select,
} from '@mantine/core';
import { Cog6ToothIcon, PlusIcon } from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { ProductSortingColumn } from 'types/product';
import { FieldSpec, Schema } from '../../../../../models/Schema';
import { getValueByPath } from '../../../../../helpers/schema';
import { ProductWithQuantity } from '../../../../../features/order/models/Order';
import { Button } from '../../../../ui/Button';
import { ProductField } from './ProductField';

interface Props {
  fieldSpecs: FieldSpec[];
  obj: any;
  schema: Schema;
  setError?: (key: string, error: string) => void;
}

const Products = memo(({
  accordionValue,
  products: _products,
  fieldSpecs,
  obj,
  schema,
  sortingColumn,
  filterEnabledProducts,
  setAccordionValue,
  getProductFieldValue,
  setError,
}: {
  accordionValue: string[];
  products: ProductWithQuantity[];
  fieldSpecs: FieldSpec[];
  obj: any;
  schema: Schema;
  sortingColumn: ProductSortingColumn;
  filterEnabledProducts: boolean;
  setAccordionValue: (value: string[]) => void;
  getProductFieldValue: (index: number, modelPath: string) => any;
  setError?: (key: string, error: string) => void;
}) => {
  const products = (_products || []).filter((product) => product.uiId);

  if (products.length === 0) return <p className="text-sm text-gray-500">No products found</p>;

  return (
    <Accordion
      multiple
      transitionDuration={300}
      value={accordionValue}
      variant="contained"
      onChange={setAccordionValue}
    >
      {products.map((product: ProductWithQuantity, i: number) => (
        <ProductField
          key={product.uiId}
          value={product.uiId}
          index={i}
          product={product}
          fieldSpecs={fieldSpecs}
          schema={schema}
          customerId={obj.customer?.id}
          sortingColumn={sortingColumn}
          filterEnabledProducts={filterEnabledProducts}
          setAccordionValue={setAccordionValue}
          getProductFieldValue={getProductFieldValue}
          setError={setError}
        />
      ))}
    </Accordion>
  );
});

const HeaderSection = memo(({
  sortingColumn,
  filterEnabledProducts,
  onSortingColumnChange,
  onFilterEnabledProductsSwitchChange,
  products,
  addNewProduct,
}: {
  sortingColumn: ProductSortingColumn;
  filterEnabledProducts: boolean;
  onSortingColumnChange: (value: string) => void;
  onFilterEnabledProductsSwitchChange: (boolean: boolean) => void;
  products: ProductWithQuantity[];
  addNewProduct: (product: ProductWithQuantity) => void;
}) => {
  const { scrollToKeyword, removeAllProducts } = useProcessOrderContext();
  const [opened, setOpened] = useState<boolean>(false);

  const [sortingColumn_, setSortingColumn_] = useState<string>(sortingColumn || 'created_at');
  const [filterEnabledProducts_, setFilterEnabledProducts_] = useState<boolean>(filterEnabledProducts);

  const onFilterEnabledProductsSwitchChange_ = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterEnabledProducts_(event.currentTarget.checked);
  }, []);

  const onApplyClick = useCallback(() => {
    onSortingColumnChange(sortingColumn_);
    onFilterEnabledProductsSwitchChange(filterEnabledProducts_);
    setOpened(false);
  }, [sortingColumn_, filterEnabledProducts_, onSortingColumnChange, onFilterEnabledProductsSwitchChange]);

  return (
    <div className="flex items-center justify-between py-sm sticky top-0 bg-white z-10">
      <Popover
        position="bottom"
        withArrow
        shadow="md"
        clickOutsideEvents={['mouseup', 'touchend']}
        transitionProps={{ transition: 'pop', duration: 200 }}
        opened={opened}
        onChange={setOpened}
      >
        <Popover.Target>
          <Button
            title="Settings"
            icon={<Cog6ToothIcon className="aspect-square w-5 text-white" />}
            variant="small"
            onClick={() => setOpened(true)}
          />
        </Popover.Target>
        <Popover.Dropdown className="space-y-4">
          <Select
            label="Sort by"
            data={[{ label: 'ID or SKU', value: 'id_or_sku' }, { label: 'Name', value: 'name' }, { label: 'Creation date', value: 'created_at' }]}
            value={sortingColumn_}
            onChange={setSortingColumn_}
            comboboxProps={{ withinPortal: false }}
          />
          <Switch
            checked={filterEnabledProducts_}
            onChange={onFilterEnabledProductsSwitchChange_}
            labelPosition="left"
            label="Show only enabled products"
          />

          <Button
            title="Apply"
            onClick={onApplyClick}
            className="w-full"
          />
        </Popover.Dropdown>
      </Popover>
      <div className="flex space-x-2">
        <Button
          title="Add product"
          theme="secondary"
          variant="small"
          icon={<PlusIcon className="aspect-square w-4" />}
          onClick={() => {
            const newProductname = `New product ${(products || []).length + 1}`;
            scrollToKeyword(newProductname);
            addNewProduct?.({
              id: '',
              uiId: uuidv4(),
              name: newProductname,
              quantity: null,
              product: null,
              unit: null,
              price: null,
              comment: '',
              score: 0,
              autoMatched: false,
              defaultUnitConversionFactor: null,
            });
          }}
        />
        <Button
          title="Delete all"
          theme="red"
          variant="small"
          onClick={removeAllProducts}
        />
      </div>
    </div>
  );
});

const ProductFields = ({
  fieldSpecs,
  obj,
  schema,
  setError,
}: Props) => {
  const {
    addNewProduct, productAccordionValue: accordionValue, setProductAccordionValue: setAccordionValue,
  } = useProcessOrderContext();

  const [sortingColumn, setSortingColumn] = useState<ProductSortingColumn | null>(null);
  const [filterEnabledProducts, setFilterEnabledProducts] = useState<boolean>(false);

  const firstRender = useRef(true);

  const productsPath = useMemo(() => fieldSpecs?.[0]?.modelPath?.split('.')?.[0], [fieldSpecs]);
  const products: ProductWithQuantity[] = useMemo(() => getValueByPath(obj, productsPath), [obj, productsPath]);

  const onSortingColumnChange = useCallback(
    (value: string) => setSortingColumn(value as ProductSortingColumn), []);

  const onFilterEnabledProductsSwitchChange = useCallback(
    (boolean: boolean) => setFilterEnabledProducts(boolean), []);

  const getProductFieldValue = useCallback((index: number, modelPath: string) => {
    const path = modelPath.split(`${productsPath}.*.`)[1];

    if (!path) return null;

    return getValueByPath(products[index], path);
  }, [products, productsPath]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      setAccordionValue(products?.filter(
        (product: ProductWithQuantity) => !product.confirmed).map((product: ProductWithQuantity) => product.uiId));
    }
  }, [products, setAccordionValue]);

  useEffect(() => {
    const storedSortingColumn = localStorage.getItem('sortingColumn');
    if (storedSortingColumn !== null) {
      setSortingColumn(JSON.parse(storedSortingColumn));
    }

    const storedFilterEnabledProducts = localStorage.getItem('filterEnabledProducts');
    if (storedFilterEnabledProducts !== null) {
      setFilterEnabledProducts(JSON.parse(storedFilterEnabledProducts));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('sortingColumn', JSON.stringify(sortingColumn));
  }, [sortingColumn]);

  useEffect(() => {
    localStorage.setItem('filterEnabledProducts', JSON.stringify(filterEnabledProducts));
  }, [filterEnabledProducts]);

  return (
    <div className="space-y-2 relative">
      <HeaderSection
        filterEnabledProducts={filterEnabledProducts}
        onFilterEnabledProductsSwitchChange={onFilterEnabledProductsSwitchChange}
        sortingColumn={sortingColumn}
        onSortingColumnChange={onSortingColumnChange}
        products={products}
        addNewProduct={addNewProduct}
      />

      <Products
        accordionValue={accordionValue}
        fieldSpecs={fieldSpecs}
        obj={obj}
        products={products}
        schema={schema}
        setError={setError}
        getProductFieldValue={getProductFieldValue}
        sortingColumn={sortingColumn}
        filterEnabledProducts={filterEnabledProducts}
        setAccordionValue={setAccordionValue}
      />
    </div>
  );
};

export default ProductFields;

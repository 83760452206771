import { createContext, useContext } from 'react';

enum DetailPanelWidthType {
  SMALL = 'small',
  LARGE = 'large',
}

type DetailPanelWidthContextType = {
  detailPanelWidth: DetailPanelWidthType; // Might add "collapsed" in the future.,
  setDetailPanelWidth: React.Dispatch<
  React.SetStateAction<DetailPanelWidthType>
  >;
};

const DetailPanelWidthContext = createContext<
DetailPanelWidthContextType | undefined
>(undefined);

const useDetailPanelWidth = () => {
  const context = useContext(DetailPanelWidthContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by DetailPanelWidthContext provider.');
  }

  return context;
};

export { DetailPanelWidthContext, useDetailPanelWidth, DetailPanelWidthType };

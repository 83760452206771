import {
  createContext, useContext, ReactNode, useMemo,
} from 'react';
import { useFormik, FormikProps, FormikConfig } from 'formik';

interface FormProviderProps<T> extends FormikConfig<T> {
  children: ReactNode;

  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormContext = createContext<
|(FormikProps<any> & {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
})
| null
>(null);

export const useFormContext = <T extends {}>() => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context as FormikProps<T> & {
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

export const FormProvider = <T extends {}>({
  children,
  isLoading,
  setIsLoading,
  ...formikConfig
}: FormProviderProps<T>) => {
  const formik = useFormik(formikConfig);

  const value = useMemo(
    () => ({
      ...formik,
      isLoading,
      setIsLoading,
    }),
    [formik, isLoading, setIsLoading],
  );
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

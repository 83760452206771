import Dayjs from 'dayjs';
import he from 'he';
import { useCallback, useMemo } from 'react';
import { UserIcon } from '@heroicons/react/20/solid';
import { isThisWeek, isToday, isYesterday } from 'date-fns';
import { Spacer } from '../../ui/Spacer';
import { OverflowEllipsisParagraph } from '../../ui/OverflowEllipsisParagraph';

interface Props {
  id: string;
  title: string;
  status: string;
  selectedChatId?: string;
  lastMessageTime: string;
  isAdamChat?: boolean;
  onClick: (id: string) => void;
}

const ChatPreview = ({
  id,
  title,
  status,
  selectedChatId,
  lastMessageTime,
  isAdamChat,
  onClick,
}: Props) => {
  const onChatClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const selectedStyle = useMemo(
    () => (selectedChatId === id ? { backgroundColor: '#f8f4f4' } : null),
    [id, selectedChatId],
  );

  const messageDateTime = useMemo(() => {
    const date = Dayjs(lastMessageTime);

    if (isToday(date.toDate())) {
      return date.format('HH:mm');
    }

    if (isYesterday(date.toDate())) {
      return 'Yesterday';
    }

    if (isThisWeek(date.toDate())) {
      return date.format('dddd');
    }

    return date.format('DD.MM.YY');
  }, [lastMessageTime]);

  if (isAdamChat) {
    return (
      <button
        className="flex h-fit w-full cursor-pointer rounded-smd border border-transparent py-md pl-2 pr-2 hover:bg-[#f8f4f4]"
        style={selectedStyle}
        type="button"
        onClick={onChatClick}
      >
        <div className="flex h-full w-full justify-between">
          <OverflowEllipsisParagraph maxLines={1}>
            <p className="text-start">{title}</p>
          </OverflowEllipsisParagraph>
        </div>
      </button>
    );
  }

  return (
    <button
      className="h-fit w-full cursor-pointer rounded-smd border border-transparent py-md pl-1 pr-2 hover:bg-[#f8f4f4]"
      style={selectedStyle}
      type="button"
      onClick={onChatClick}
    >
      <div className="flex h-full w-full">
        <div className="flex aspect-square h-full w-[min(17%,50px)] shrink-0 items-center justify-center rounded-full bg-gray-300">
          <UserIcon className="aspect-square w-full text-white" />
        </div>

        <div className="flex h-full w-[83%] flex-col pl-smd text-start">
          <div className="flex max-h-[38%] min-h-[38%] items-start justify-between text-content-smd-sm lg:text-content-smd">
            <span className="max-w-[15ch] overflow-hidden text-ellipsis whitespace-nowrap font-semibold lg:max-w-[20ch] xl:max-w-[15ch]">
              {title}
            </span>
            <p className="font-semibold text-gray-500">{messageDateTime}</p>
          </div>

          <div className="max-w-[15ch] truncate text-content-sm-sm lg:text-content-sm">
            {`${he.decode(status).toLowerCase()}`}
          </div>
        </div>
        <Spacer />
      </div>
    </button>
  );
};

export { ChatPreview };

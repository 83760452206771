import { Badge, Tooltip } from 'flowbite-react';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const BetaComponent = ({ children }: Props) => (
  <div
    className={`relative hidden ${SHOW_BETA_COMPONENTS === 'true' ? null : 'hidden'}`}
  >
    <div className="absolute right-2 top-2">
      <Tooltip
        content="This feature is currently in development"
        // eslint-disable-next-line react/style-prop-object
        style="light"
        arrow={false}
      >
        <Badge color="purple">Preview</Badge>
      </Tooltip>
    </div>
    {children}
  </div>
);

export { BetaComponent };

import { ReactNode } from 'react';

export type SelectOption = {
  visibleId?: string;
  label: string;
  customLabelTooltip?: string;
  additionalInfo?: ReactNode;
  flag?: string;
  value: string;
  meta?: string;
  metaDescription?: string;
  contentTheme?: ContentTheme;
};

export enum ContentTheme {
  STANDARD = 'standard',
  WARNING = 'warning',
  AIImproved = 'ai-improved',
  AIInfo = 'ai-info',
  AIWarning = 'ai-warning',
}

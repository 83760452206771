import CountUp from 'react-countup';

interface Props {
  icon: string;
  title: string;
  value: number;
}

const DocumentProcessedBody = ({ icon, title, value }: Props) => (
  <>
    <div className="flex justify-between items-center">
      <img src={icon} className="mr-4 w-[25px]" alt="" />
      <div className="flex justify-between items-center flex-1 gap-2">
        <div className="me-5">
          <span className="font-semibold text-sm">{title}</span>
        </div>
        <div className="d-flex align-items-center">
          <span className="text-gray-800 font-semibold text-sm me-3 d-block">
            <CountUp end={value} />
          </span>
        </div>
      </div>
    </div>
    <div className="border-b border-dashed border-gray-400 block my-2" />
  </>
);

export default DocumentProcessedBody;

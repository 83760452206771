import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const isCsvOrExcel = (file: File) => {
  const csvTypes: string[] = ['text/csv', 'application/vnd.ms-excel'];
  const excelTypes: string[] = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  return (
    csvTypes.includes(file.type)
    || excelTypes.includes(file.type)
    || /\.(csv|xlsx|xls)$/.test(file.name.toLowerCase())
  );
};

const readCsvFirstTenLines = (file: File): Promise<Array<any>> => new Promise((resolve, reject) => {
  if (!isCsvOrExcel(file)) {
    reject(new Error('Unsupported file type'));
    return;
  }

  const fileExtension = file.name.split('.').pop()?.toLowerCase();

  if (fileExtension === 'csv') {
    Papa.parse(file, {
      complete: (results: Papa.ParseResult<any>) => {
        resolve(results.data.slice(0, 11));
      },
      error: (error) => {
        reject(new Error(`Error parsing CSV: ${error.message}`));
      },
      preview: 10, // Only parse the first 10 rows
    });
  } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const data = e.target?.result;
        if (typeof data === 'string') {
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            range: 0,
            blankrows: false,
            defval: ' ',
          });
          resolve(json.slice(0, 11));
        } else {
          reject(new Error('File read error'));
        }
      } catch (error) {
        reject(new Error(`Error parsing Excel: ${error}`));
      }
    };
    reader.onerror = () => {
      reject(new Error('Error reading the file'));
    };
    reader.readAsBinaryString(file);
  }
});

export { readCsvFirstTenLines };

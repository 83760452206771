import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { ScrollAreaWrapper } from '../wrapper/ScrollAreaWrapper';

interface Props {
  header?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;

  // Styling props
  sidebarWidth?: string;
}

const SidebarBase = ({
  header,
  body,
  footer,
  sidebarOpen,
  setSidebarOpen,

  sidebarWidth = 'max-w-[40vw]',
}: Props) => (
  <Transition.Root show={sidebarOpen} as={Fragment}>
    <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-900/40" />
      </Transition.Child>

      <div className="fixed inset-0 flex justify-end">
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <Dialog.Panel
            className={`relative flex w-full ${sidebarWidth} flex-1`}
          >
            <div className="flex min-h-[70px] grow flex-col overflow-y-auto bg-white">
              {header ? (
                <div className="flex flex-wrap items-stretch justify-between border-b border-[#F1F1F4] px-4">
                  <div className="text-[1.275rem] font-semibold text-gray-900">
                    <div className="my-4">{header}</div>
                  </div>

                  <div className="flex items-center">
                    <button
                      type="button"
                      className=""
                      onClick={() => setSidebarOpen(false)}
                    >
                      <XMarkIcon className="h-6 w-6 text-gray-700" />
                    </button>
                  </div>
                </div>
              ) : null}

              <div className="relative flex flex-1 flex-col flex-wrap items-stretch justify-between px-4 py-4">
                <ScrollAreaWrapper className="flex-1">{body}</ScrollAreaWrapper>
              </div>

              {header ? (
                <div className="flex flex-wrap items-stretch justify-between border-t border-[#F1F1F4] px-4">
                  <div className="w-full text-[1.275rem] text-gray-900">
                    <div className="w-full py-2">{footer}</div>
                  </div>
                </div>
              ) : null}
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

export { SidebarBase };

import { twMerge } from 'tailwind-merge';

type ThreeDotsProp = {
  className?: string;
};

const ThreeDots = ({ className }: ThreeDotsProp) => (
  <div className="col-3 block">
    <div className="snippet" data-title="dot-pulse">
      <div className="stage">
        <div
          className={twMerge(
            'dot-pulse aspect-square w-[8px] bg-light-gray-50 text-light-gray-50 before:aspect-square before:w-[8px] before:bg-light-gray-50 before:text-light-gray-50 after:aspect-square after:w-[8px] after:bg-light-gray-50 after:text-light-gray-50',
            className,
          )}
        />
      </div>
    </div>
  </div>
);

export { ThreeDots };

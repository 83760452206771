import { useEffect, useMemo } from 'react';

import { useFetchBusinessSettings } from 'hooks/fetch/useFetchBusinessSettings';
import { useFetchSchemaByTypeRef } from 'hooks/fetch/useFetchSchemaByTypeRef';

import { useBusinessSettingsContext } from 'contexts/useBusinessSettingsContext';
import { useSchemasContext } from 'contexts/useSchemasContext';
import { DragDropProvider } from 'contexts/useDragDropContext';

import { useFetchPromptByCustomerId } from 'hooks/fetch/useFetchPromptByCustomerId';

import { Pipeline } from 'types/instruction';

import { useOrderContext } from 'features/order/contexts/useOrderContext';
import { CustomerSelect } from 'features/customer/components/CustomerSelect';
import LoadingOverlay from 'components/ui/LoadingOverlay';

interface Props {
  children: React.ReactNode;
}

const BodyWrapper = ({ children }: Props) => {
  const { schemas, setSchemas } = useSchemasContext();
  const { loadSchema: loadOrderSchema, isLoading: isOrderSchemaLoading } = useFetchSchemaByTypeRef();

  const { setBusinessSettings } = useBusinessSettingsContext();
  const { businessSettings, isLoading: isBusinessSettingsLoading } = useFetchBusinessSettings({});

  const { isLoading: isPromptLoading, loadPrompt } = useFetchPromptByCustomerId();

  const { groupOrders, selectedIndex, setGroupOrders } = useOrderContext();

  const order = useMemo(
    () => {
      const _order = groupOrders[selectedIndex];
      return {
        ..._order,
        products: (_order?.products || []).map((product) => ({ ...product })),
      };
    },
    [groupOrders, selectedIndex],
  );

  const customerId = useMemo(() => order?.customer?.id, [order]);

  useEffect(() => {
    setBusinessSettings(businessSettings);
  }, [businessSettings, setBusinessSettings]);

  useEffect(() => {
    if (order) {
      order.typeSpecs?.forEach((typeSpec) => {
        if (schemas[typeSpec.typeRef]) {
          return;
        }

        loadOrderSchema(typeSpec.typeRef)
          .then((schema) => {
            setSchemas((_schemas) => ({ ..._schemas, [typeSpec.typeRef]: schema }));
          });
      });
    }
  }, [order, loadOrderSchema, setSchemas, schemas]);

  // When customer is changed fetch the corresponding prompt
  useEffect(() => {
    if (customerId) {
      loadPrompt(customerId, Pipeline.ORDER)
        .then((prompt) => setGroupOrders((_groupOrders) => _groupOrders.map(
          (_order, idx) => (idx === selectedIndex ? { ..._order, typeSpecs: prompt.boundTypeSpecs, promptId: prompt.id }
            : _order),
        )));
    }
  }, [loadPrompt, customerId, selectedIndex, setGroupOrders]);

  if (order && !customerId) {
    return (
      <div>
        <div className="pb-xl">
          <h1 className="pb-sm text-title-sm font-semibold">
            Customer selection required
          </h1>
          <p className="text-label-caption-md text-description">
            Please select a customer to create a new order.
            The corresponding prompt will be automatically selected, and the processing view will open.
          </p>
        </div>
        <CustomerSelect />
      </div>
    );
  }

  if (isBusinessSettingsLoading) {
    return (
      <LoadingOverlay visible displayText="Loading business settings" />
    );
  }

  if (isOrderSchemaLoading) {
    return (
      <LoadingOverlay visible displayText="Loading order schema" />
    );
  }

  if (isPromptLoading) {
    return (
      <LoadingOverlay visible displayText="Loading prompt" />
    );
  }

  return (
    <DragDropProvider>
      {children}
    </DragDropProvider>
  );
};

export default BodyWrapper;

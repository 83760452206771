import { TextInput } from '@mantine/core';

import { Card } from 'components/common/Card';
import { useFetchCustomers } from '../../../../../hooks/fetch/useFetchCustomers';
import { useInstructionContext } from '../../../../../contexts/useInstructionContext';
import { MultiSelect } from '../../../../settings/SettingCard/ui/Multiselect';
import { Prompt } from '../../../../../models/Prompt';
import { Business } from '../../../../../models/Business';

interface Props {
  prompt: Prompt;
  setPrompt: React.Dispatch<React.SetStateAction<Prompt>>;
}

const optionLabel = (customer: Business) => {
  let label = `${customer.name}`;
  if (customer.email) {
    label += `; ${customer.email}`;
  } else if (customer.emails?.[0]) {
    label += `; ${customer.emails?.[0]}`;
  }
  if (customer.phones?.[0]) {
    label += `, ${customer.phones?.[0].number}`;
  }

  if (customer.address) {
    label += `; ${customer.address}`;
  }
  return label;
};

const HeaderCard = ({ prompt, setPrompt }: Props) => {
  const {
    instructionName,
    setInstructionName,
  } = useInstructionContext();

  const {
    customers,
    isLoading: customersIsLoading,
    loadCustomers,
    setSearchQuery,
  } = useFetchCustomers({ preventInitialFetch: false });
  return (
    <Card>
      <Card.Body className="space-y-2">
        <TextInput
          label="Instruction name"
          value={instructionName}
          onChange={(e) => setInstructionName(e.target.value)}
          className="w-full"
        />

        <MultiSelect
          label="Customers"
          description="Select customers to apply this instruction to. Leave empty to apply to all customers."
          options={customers?.map((customer) => ({
            value: customer.id,
            label: optionLabel(customer),
            shortName: customer.name,
          }))}
          isLoading={customersIsLoading}
          selectedValues={prompt.customerIds}
          isSearchable
          onSelect={(value) => setPrompt((_prompt) => ({
            ..._prompt,
            customerIds: _prompt.customerIds.includes(value)
              ? _prompt.customerIds.filter((id) => id !== value) : [..._prompt.customerIds, value],
          }))}
          onRemove={(value) => setPrompt((_prompt) => ({
            ..._prompt,
            customerIds: _prompt.customerIds.filter((id) => id !== value),
          }))}
          onScrolledEnd={() => loadCustomers({})}
          onSearch={(searchQuery) => setSearchQuery(searchQuery)}
        />
      </Card.Body>
    </Card>
  );
};

export default HeaderCard;

import {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react';

import { ConfirmOrderModal } from '../../modal/ConfirmOrderModal';

interface Props {
  children: React.ReactNode;

  onConfirm: () => void;
}

const ConfirmOrderModalWrapper = forwardRef(
  ({ children, onConfirm }: Props, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = useCallback(() => {
      setIsModalOpen(true);
    }, []);

    useImperativeHandle(ref, () => ({
      openModal,
    }));
    return (
      <>
        {children}

        <ConfirmOrderModal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          onConfirm={onConfirm}
        />
      </>
    );
  },
);

export default ConfirmOrderModalWrapper;

import { useState, useCallback } from 'react';

import { httpDel } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { globalAlertData } from 'state/globalAlertData';

const useDeleteUserById = () => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteUserById = useCallback(
    async (
      userId: string,
      createAlert: boolean = true,
      giveErrorFeedback: boolean = true,
    ): Promise<void> => {
      setIsLoading(true);
      return httpDel(`/users/${userId}`)
        .then(() => {
          if (createAlert) {
            globalAlertData.create('User deleted successfully');
          }

          return Promise.resolve();
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Failed to delete user')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return { isLoading, deleteUserById };
};

export { useDeleteUserById };

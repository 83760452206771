import {
  useState, useCallback,
} from 'react';

import { AxiosRequestConfig } from 'axios';
import { httpPost } from '../helpers/xhr';
import { genericErrorFeedback } from '../helpers/errors';

const useParseFields = () => {
  const [isLoading, setIsLoading] = useState(false);

  const parseFields = useCallback(
    async (
      files: File[],
      giveErrorFeedback: boolean = true,
    ): Promise<any> => {
      setIsLoading(true);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append('upload[]', file);
      });

      const config: AxiosRequestConfig<any> = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: null,
        transformResponse: (data) => JSON.parse(data),
      };

      return httpPost('/workflows/order/parse_fields', formData, config)
        .then((data) => data.data)
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Error parsing fields')(error);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  return {
    isLoading,
    parseFields,
  };
};

export { useParseFields };

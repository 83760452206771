import { MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { Button as MantineButton } from '@mantine/core';

import { Button } from 'components/ui/Button';

import { DefaultButtonsProps } from './types';

const DefaultButtons = ({ zoomIn, zoomOut, onUseMagicPenClick }: DefaultButtonsProps) => (
  <div className="p-1 flex items-center">
    <div className="flex-1" />
    <div className="flex flex-1 justify-center items-center">
      <Button
        onClick={zoomIn}
        theme="transparent"
        title=""
        icon={
          <MagnifyingGlassPlusIcon className="aspect-square w-5" />
        }
      />
      <Button
        onClick={zoomOut}
        theme="transparent"
        title=""
        icon={
          <MagnifyingGlassMinusIcon className="aspect-square w-5" />
        }
      />
    </div>
    <div className="flex-1 justify-end flex space-x-3">
      <MantineButton onClick={onUseMagicPenClick} variant="filled" size="xs" color="indigo">
        {/* <span className="text-xs space-x-1 flex items-center"> */}
        <FontAwesomeIcon icon={faMagicWandSparkles} />
        <p className="ml-1 text-xs">
          Magic Pen
        </p>
        {/* <span className="flex-none font-semibold text-gray-400">
            <kbd className="font-sans">⌘</kbd>
            <kbd className="font-sans">M</kbd>
          </span>
        </span> */}
      </MantineButton>
    </div>
  </div>
);

export default DefaultButtons;

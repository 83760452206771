import { uniq } from 'lodash';
import { globalUser } from 'state/globalUser';
import { AssignedStatus } from 'types/order';
import { isZeroId } from 'helpers/objectId';

import { Order, convertibleUnits } from '../models/Order';

const getAssignedStatus = (assigneeId: string | null) => {
  if (!assigneeId || isZeroId(assigneeId)) return AssignedStatus.UNASSIGNED;

  if (assigneeId === globalUser.id) return AssignedStatus.ME;

  return AssignedStatus.OTHER;
};

const calcTotalPrice = (order: Order) => {
  const currencies = uniq(
    order.products
      .map((_product) => _product?.price?.currency)
      .filter((_currency) => _currency),
  );
  return {
    ...order,
    totalPrice: {
      amount:
        currencies.length === 1
          ? order.products.reduce(
            (total, product) => total
                + (product?.price?.amount || 0) * (product?.quantity || 0),
            0,
          )
          : 0,
      currency:
        currencies.length === 1 ? currencies[0] : order.totalPrice?.currency,
    },
  };
};

const isUnitConvertible = (unit: string) => convertibleUnits.includes(unit?.toUpperCase());

export { getAssignedStatus, calcTotalPrice, isUnitConvertible };

import { useMemo } from 'react';
import {
  DetailPanelContentType,
  useDetailPanelContent,
} from 'contexts/useDetailPanelContentContext';
import { UserInfoPanel } from 'components/common/DetailPanel/detail-panels/UserInfoPanel';
import { OrderDetailPanel } from 'components/common/DetailPanel/detail-panels/OrderDetailPanel';

interface Props {
  chatId?: string;
}

const DetailPanel = ({ chatId }: Props) => {
  const { detailPanelContent } = useDetailPanelContent();

  const ContentNode = useMemo(() => {
    switch (detailPanelContent) {
      case DetailPanelContentType.USER_INFO:
        return <UserInfoPanel chatId={chatId} />;
      case DetailPanelContentType.ORDER_DETAIL:
        return (
          <OrderDetailPanel />
        );
      default:
        return null;
    }
  }, [chatId, detailPanelContent]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden bg-white">
      <div className="flex w-full flex-1 flex-col overflow-hidden p-lg">
        {ContentNode}
      </div>
      {/* <div>
        <Button
          title="Detail Panel"
          onClick={() => setDetailPanelWidth(
            (current) => (current === DetailPanelWidthType.SMALL ? DetailPanelWidthType.LARGE : DetailPanelWidthType.SMALL),
          )}
        />
      </div> */}
    </div>
  );
};

export default DetailPanel;

import { BluePinkyBackground } from 'components/background/BluePinkyBackground';
import { Page } from 'components/layout/Page/Page';
import { useResetPassword } from 'features/user/hooks/useResetPassword';

import { ThreeDots } from 'components/ThreeDots';
import { FormInput } from 'components/FormInput';
import { object, ref, string } from 'yup';
import { password as passwordValidation } from 'helpers/validations';
import { globalAlertData } from 'state/globalAlertData';

import { ROUTES } from 'config/routes';

import logo from 'assets/logo/hoshii.svg';
import { Navigate, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { globalUser } from 'state/globalUser';

interface Props {
  appLink: string;
}

interface SigninFormValues {
  password: string;
  confirmPassword: string;
}

const formInitialValues = {
  password: '',
  confirmPassword: '',
};

const formValidationSchema = object({
  password: passwordValidation().required(),
  confirmPassword: string()
    .required()
    .oneOf([ref('password')], "Does't match the password"),
});

const ResetPasswordPage = ({ appLink }: Props) => {
  const navigate = useNavigate();

  const { isLoading, resetPassword } = useResetPassword();

  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    inputRef.current?.focus();

    const handleKeyDown = (event: KeyboardEvent) => {
      if (buttonRef.current && event.key === 'Enter') {
        buttonRef.current?.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (globalUser.isSignedIn) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return (
    <Page isLoading={isLoading} hideMenu hideHeader>
      <div className="flex flex-1 items-start justify-center p-lg">
        <div className="relative flex h-full w-full items-center justify-center space-y-5">
          <BluePinkyBackground />

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[30%] 3xl:max-w-[25%] 4xl:max-w-[20%]">
            <div className="space-y-3 bg-white px-6 py-12 shadow-md sm:rounded-lg sm:px-12">
              <div className="mb-3">
                <div className="flex w-full items-center justify-center">
                  <img
                    className="relative mr-2 h-[2.5rem] w-auto sm:h-[3rem]"
                    src={logo}
                    alt=""
                    style={{
                      filter: 'brightness(0) saturate(100%) invert(12%) sepia(18%) saturate(5372%) hue-rotate(193deg) brightness(97%) contrast(96%)',
                    }}
                  />
                </div>
                <h1 className="font-bold pt-4">Reset password</h1>
                <p className="text-xs text-gray-500 pt-1">
                  Enter your new password.
                </p>
              </div>
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={(values: SigninFormValues) => {
                  resetPassword(appLink, values.password)
                    .then(() => {
                      globalAlertData.create(
                        'Password reset successfully',
                        '',
                        '#00c853',
                      );
                      navigate(ROUTES.SIGNIN(''));
                    });
                }}
                enableReinitialize
              >
                {({
                  handleChange, handleBlur, handleSubmit, values, errors, touched,
                }) => (
                  <div className="space-y-6">
                    <FormInput
                      field="password"
                      type="password"
                      required
                      disabled={isLoading}
                      label="Password"
                      value={values.password}
                      error={errors.password}
                      touched={touched.password}
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                    />
                    <FormInput
                      field="confirmPassword"
                      type="password"
                      required
                      disabled={isLoading}
                      label="Confirm password"
                      value={values.confirmPassword}
                      error={errors.confirmPassword}
                      touched={touched.confirmPassword}
                      onChange={handleChange('confirmPassword')}
                      onBlur={handleBlur('confirmPassword')}
                    />

                    <div>
                      <button
                        type="button"
                        onClick={() => handleSubmit()}
                        ref={buttonRef}
                        className="flex w-full justify-center rounded-md bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                      >
                        <span>
                          {isLoading ? (
                            <div className="flex max-h-[24px] min-h-[24px] flex-col items-center justify-center">
                              <ThreeDots />
                            </div>
                          ) : (
                            'Reset password'
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ResetPasswordPage;

import { ComboboxItem, Select as MantineSelect } from '@mantine/core';
import LabelWrapper from '../LabelWrapper';

interface Props {
  label: string;
  value: string;
  fitWidth?: boolean;
  values?: string[];
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (value: string, option: ComboboxItem) => void;
}

const Select = ({
  label,
  value,
  values,
  fitWidth = false,
  readOnly = true,
  disabled = false,
  onChange,
}: Props) => (
  <LabelWrapper label={label}>
    <span className={`w-full ${fitWidth && 'flex justify-end'}`}>
      <MantineSelect
        value={value}
        data={values || [value]}
        onChange={onChange}
        readOnly={readOnly}
        w={fitWidth ? 'fit-content' : '100%'}
        disabled={disabled}
      />
    </span>
  </LabelWrapper>
);

export default Select;

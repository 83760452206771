import {
  createContext, useContext, useMemo, useState,
} from 'react';

type DragDropType = {
  draggedItemId: string;
  setDraggedItemId: React.Dispatch<React.SetStateAction<string>>;
  dragging: boolean;
  setDragging: React.Dispatch<React.SetStateAction<boolean>>;
};

const DragDropContext = createContext<DragDropType | undefined>(undefined);

const useDragDropContext = () => {
  const context = useContext(DragDropContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by DragDropContext provider.');
  }

  return context;
};

const DragDropProvider = ({ children }: { children: React.ReactNode }) => {
  const [draggedItemId, setDraggedItemId] = useState('');
  const [dragging, setDragging] = useState(false);

  const value = useMemo(
    () => ({
      draggedItemId,
      setDraggedItemId,
      dragging,
      setDragging,
    }),
    [draggedItemId, dragging],
  );

  return (
    <DragDropContext.Provider value={value}>
      {children}
    </DragDropContext.Provider>
  );
};

export { DragDropProvider, useDragDropContext };

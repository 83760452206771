import { CustomerSelect } from 'features/customer/components/CustomerSelect';
import {
  getFieldByPath, getValueByPath, inputTypeToUniversalFieldType,
} from 'helpers/schema';
import { UniversalField } from 'components/common/UniversalField';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { useCallback } from 'react';
import { FieldSpec, Schema } from '../../../../models/Schema';

interface Props {
  fieldSpecs: FieldSpec[];
  setError?: (key: string, error: string) => void;
  obj: any;
  schema: Schema;
}

const CustomerFields = ({
  fieldSpecs, setError, obj, schema,
}: Props) => {
  const { updateValueByPath } = useProcessOrderContext();

  const setCustomerSelectError = useCallback((error: string) => {
    setError?.('customer', error);
  }, [setError]);

  return (
    <div className="space-y-4 relative">
      <CustomerSelect setError={setCustomerSelectError} />
      {fieldSpecs.map((fieldSpec) => {
        const field = getFieldByPath(schema?.fields, fieldSpec.path);
        return (
          fieldSpec.modelPath.split('.')[1] === '*'
            ? (
          // TODO
              <div key={fieldSpec.path}>
                {getValueByPath(obj, fieldSpec.modelPath.split('.')[0]).map((value: any) => (
                  <div key={value.uiId}>
                    <pre>{JSON.stringify(value, null, 2)}</pre>
                  </div>
                ))}
              </div>
            )
            : (
              <UniversalField
                key={fieldSpec.path}
                type={inputTypeToUniversalFieldType(field?.inputType, field?.type)}
                validation={fieldSpec.validation}
                label={fieldSpec.name}
                value={getValueByPath(obj, fieldSpec.modelAutoMatchedPath) || getValueByPath(obj, fieldSpec.modelPath)}
                shouldScroll={false}
                setError={(error) => setError?.(fieldSpec.path, error)}
                onValueChange={(value) => updateValueByPath(value, fieldSpec.modelPath)}
              />
            )
        );
      })}
    </div>
  );
};

export default CustomerFields;

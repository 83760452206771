// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TqJpYt_HY0zkmNu6uMaF {\n    transition: opacity 150ms ease;\n    opacity: 0;\n}\n\n.TqJpYt_HY0zkmNu6uMaF[data-inactive] {\n        opacity: 0;\n        cursor: default;\n    }\n\n.p5BczokUlZ1fmjvWZ70H {\n    height: '100%';\n}\n\n.OrgaatFP3CVLHk90_dKa {\n    height: 100%;\n}\n\n.Pm5G4KvYek0Ba_VbwAeR:hover .TqJpYt_HY0zkmNu6uMaF {\n            opacity: 1;\n        }", "",{"version":3,"sources":["webpack://./src/components/dashboard/AdamInsights/AdamInsights.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,UAAU;AAMd;;AAJI;QACI,UAAU;QACV,eAAe;IACnB;;AAGJ;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAIQ;YACI,UAAU;QACd","sourcesContent":[".controls {\n    transition: opacity 150ms ease;\n    opacity: 0;\n\n    &[data-inactive] {\n        opacity: 0;\n        cursor: default;\n    }\n}\n\n.viewport {\n    height: '100%';\n}\n\n.container {\n    height: 100%;\n}\n\n.root {\n    &:hover {\n        .controls {\n            opacity: 1;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "TqJpYt_HY0zkmNu6uMaF",
	"viewport": "p5BczokUlZ1fmjvWZ70H",
	"container": "OrgaatFP3CVLHk90_dKa",
	"root": "Pm5G4KvYek0Ba_VbwAeR"
};
export default ___CSS_LOADER_EXPORT___;

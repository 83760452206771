import { ReactNode } from 'react';

const styles = {
  label: {
    fontWeight: 'bold',
  },
};

interface Props {
  label: string;
  description?: string;
  children: ReactNode;
}

const FieldDisplay = ({ label, description, children }: Props) => (
  <div className="flex flex-col rounded-md border border-light-gray-200 bg-white p-lg">
    <div className="flex">
      <div style={styles.label} className="text-title-sm">
        {label}
      </div>
    </div>
    {description ? (
      <div className="mt-[6px] text-label-caption text-description">
        {description}
      </div>
    ) : null}
    <div>{children}</div>
  </div>
);

FieldDisplay.defaultProps = {
  description: null,
};

export { FieldDisplay };

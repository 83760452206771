import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Combobox,
  ComboboxProps,
  Input,
  InputBase,
  ScrollArea,
  useCombobox,
} from '@mantine/core';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { twMerge } from 'tailwind-merge';

import { Tag } from 'components/common/Tag';
import { LoadingIndicator } from '../../LoadingIndicator';
import { OverflowEllipsisParagraph } from '../OverflowEllipsisParagraph';

import stars from '../../../assets/stars-svgrepo-com.svg';
import { TagVariant } from '../../../types/tag';
import { ContentTheme, SelectOption } from './types';

interface Props extends ComboboxProps {
  label?: string;
  error?: string;
  placeholder?: string;
  required?: boolean;
  isLoading?: boolean;
  size?: 'sm' | 'xs';

  selectedOption: SelectOption;
  options: SelectOption[];

  isExternalSearchEnabled?: boolean;

  onSelectionChange: (option: SelectOption) => void;
  onSearchPerformed?: (search: string) => void;
  onScrolledEnd?: (search: string) => void;
  onDropdownOpen?: () => void;
}

const Select = ({
  label,
  error,
  placeholder,
  required,
  options,
  selectedOption,
  isLoading = false,
  isExternalSearchEnabled = false,
  size = 'sm',
  onSelectionChange,
  onScrolledEnd,
  onSearchPerformed,
  onDropdownOpen,
  ...props
}: Props) => {
  const [search, setSearch] = useState('');

  const viewPortRef = useRef<HTMLDivElement>(null);
  const isDropdownOpenedRef = useRef(false);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      isDropdownOpenedRef.current = true;
      if (isExternalSearchEnabled) {
        combobox?.focusSearchInput();
      }
      onDropdownOpen?.();
    },
  });

  const filteredOptions = useMemo(
    () => (isExternalSearchEnabled
      ? options
      : options.filter((o) => o.value.toLowerCase().includes(search.toLowerCase().trim()),
      )),
    [isExternalSearchEnabled, options, search],
  );

  const showSparkle = useCallback(
    (option: SelectOption) => option?.contentTheme === ContentTheme.AIImproved
      || option?.contentTheme === ContentTheme.AIInfo
      || option?.contentTheme === ContentTheme.AIWarning,
    [],
  );

  const tooltipTheme = useCallback((option: SelectOption) => {
    switch (option?.contentTheme) {
      case ContentTheme.AIInfo:
        return 'ai-info';
      case ContentTheme.AIWarning:
      case ContentTheme.WARNING:
        return 'warning';
      default:
        return 'standard';
    }
  }, []);

  const _getTextColor = useCallback((option: SelectOption) => {
    switch (option?.contentTheme) {
      case ContentTheme.WARNING:
      case ContentTheme.AIWarning:
        return 'text-[#ff6f00]';
      case ContentTheme.AIInfo:
        return 'text-violet-500';
      case ContentTheme.AIImproved:
        return 'text-yellow-400';
      default:
        return '';
    }
  }, []);

  const borderStyle = useMemo(() => {
    switch (selectedOption?.contentTheme) {
      case ContentTheme.WARNING:
      case ContentTheme.AIWarning:
        return '1px solid #FFAD6E';
      case ContentTheme.AIImproved:
        return '2px solid #FFEB99';
      default:
        return '';
    }
  }, [selectedOption?.contentTheme]);

  const sparkleFilter = useCallback((option: SelectOption) => {
    switch (option?.contentTheme) {
      case ContentTheme.AIImproved:
        return 'brightness(0) saturate(100%) invert(80%) sepia(70%) saturate(2000%) hue-rotate(0deg) brightness(100%) contrast(90%)';
      case ContentTheme.AIInfo:
        return 'invert(54%) sepia(62%) saturate(6540%) hue-rotate(237deg) brightness(99%) contrast(94%)';
      case ContentTheme.AIWarning:
        return 'invert(57%) sepia(100%) saturate(1415%) hue-rotate(341deg) brightness(100%) contrast(97%)';
      default:
        return '';
    }
  }, []);

  const renderOption = useCallback(
    (option: SelectOption, showMeta: boolean = true) => (
      <Combobox.Option
        value={option.value}
        key={option.value}
        className="w-full"
      >
        <div className="flex w-full items-center justify-between gap-2">
          <div
            className={`flex flex-shrink flex-grow-0 items-center gap-2 ${_getTextColor(option)}`}
            style={{
              maxWidth:
                option.meta && showMeta ? 'calc(100% - 72px)' : undefined,
            }}
          >
            {option.visibleId ? (
              <div className="max-w-22 4xl:max-w-full flex-shrink-0 flex-grow">
                <OverflowEllipsisParagraph
                  maxLines={1}
                  isTooltipEnabled
                  className="relative top-[1px] !font-mono"
                >
                  {option.visibleId}
                </OverflowEllipsisParagraph>
              </div>
            ) : null}
            <div className="flex flex-shrink flex-grow-0 items-center space-x-2 overflow-hidden">
              <OverflowEllipsisParagraph
                customTooltipContent={option.customLabelTooltip || option.label}
                theme={tooltipTheme(option)}
                maxLines={1}
                isTooltipEnabled
                openDelay={0}
              >
                {option.label}
              </OverflowEllipsisParagraph>

              <img
                className={twMerge(
                  'relative ml-auto h-[25.4px] w-[20px]',
                  showSparkle(option) ? 'block' : 'hidden',
                )}
                src={stars}
                alt=""
                style={{
                  filter: sparkleFilter(option),
                }}
              />
              {!!option.flag && (
                <Tag
                  className="static whitespace-nowrap"
                  tagTitle={option.flag}
                  tagVariant={TagVariant.GRAY}
                  isTagLoading={false}
                  hideCircle
                />
              )}
            </div>
          </div>

          <div className="flex w-fit items-center space-x-2">
            {option.additionalInfo && option.additionalInfo !== '' ? (
              <OverflowEllipsisParagraph
                maxLines={1}
                isTooltipEnabled
                customTooltipContent={option.additionalInfo}
              >
                <ExclamationCircleIcon className="aspect-square w-5" />
              </OverflowEllipsisParagraph>
            ) : null}
            {option.meta && showMeta ? (
              <div className="max-w-16 flex-shrink-0">
                <OverflowEllipsisParagraph
                  maxLines={1}
                  isTooltipEnabled
                  customTooltipContent={
                    option.metaDescription || 'Additional details'
                  }
                  className="relative top-[1px] !font-mono text-gray-500"
                >
                  {option.meta}
                </OverflowEllipsisParagraph>
              </div>
            ) : null}
          </div>
        </div>
      </Combobox.Option>
    ),
    [_getTextColor, showSparkle, sparkleFilter, tooltipTheme],
  );

  const comboOptions = useMemo(
    () => filteredOptions.map((o) => renderOption(o)),
    [filteredOptions, renderOption],
  );

  const onOptionSelect = useCallback(
    (value: string) => {
      const option = options.find((o) => o.value === value);
      if (option) onSelectionChange(option);
      combobox.closeDropdown();
    },
    [combobox, onSelectionChange, options],
  );

  const onOptionsScroll = useCallback(
    (position: { x: number; y: number }) => {
      if (
        viewPortRef.current.scrollHeight
        <= Math.round(position.y) + viewPortRef.current.clientHeight
      ) onScrolledEnd?.(search);
    },
    [onScrolledEnd, search],
  );

  const onComboSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      setSearch(value);
    },
    [setSearch],
  );

  const onInputBaseClick = useCallback(() => {
    combobox.toggleDropdown();
  }, [combobox]);

  useEffect(() => {
    if (isDropdownOpenedRef.current) onSearchPerformed?.(search);
  }, [onSearchPerformed, search]);

  return (
    <div className="w-full">
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={onOptionSelect}
        offset={4}
        shadow="md"
        {...props}
      >
        <Combobox.Target>
          <InputBase
            label={label}
            error={error}
            component="button"
            type="button"
            pointer
            rightSection={<Combobox.Chevron />}
            onClick={onInputBaseClick}
            rightSectionPointerEvents="none"
            required={required}
            size={size}
            styles={{ input: { border: borderStyle } }}
          >
            <div className="flex items-center gap-2">
              {selectedOption ? (
                renderOption(selectedOption, false)
              ) : (
                <Input.Placeholder>
                  {isLoading ? 'Loading' : placeholder || 'Select an option...'}
                </Input.Placeholder>
              )}
              <LoadingIndicator isLoading={isLoading} />
            </div>
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown style={{ outline: '0.5px solid #E8E8E8' }}>
          {isExternalSearchEnabled ? (
            <Combobox.Search
              value={search}
              onChange={onComboSearch}
              placeholder="Search options..."
            />
          ) : null}
          <Combobox.Options mah={200}>
            <ScrollArea.Autosize
              mah={200}
              type="scroll"
              offsetScrollbars
              onScrollPositionChange={onOptionsScroll}
              mt="sm"
              viewportRef={viewPortRef}
            >
              {comboOptions.length > 0 ? (
                comboOptions
              ) : (
                <Combobox.Empty>Nothing found</Combobox.Empty>
              )}
            </ScrollArea.Autosize>
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
};

export default Select;

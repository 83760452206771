import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  enabled?: boolean;
}

const RoutingLog = ({ enabled }: Props): ReactElement => {
  const location = useLocation();
  if (enabled) console.log(`[Routing] Current location "${location.pathname}"`);
  return null;
};

export { RoutingLog };

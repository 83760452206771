import { ApexOptions } from 'apexcharts';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface ChartThreeState {
  series: number[];
}

const options: ApexOptions = {
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'donut',
  },
  colors: ['#3C50E0', '#6577F3', '#8FD0EF'],
  labels: ['WebShop', 'Email', 'Phone'],
  legend: {
    show: false,
    position: 'bottom',
  },

  plotOptions: {
    pie: {
      donut: {
        size: '55%',
        background: 'transparent',
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 380,
        },
      },
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

const ChartThree = () => {
  const [state, setState] = useState<ChartThreeState>({
    series: [55, 28, 17],
  });

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (e.target.value === 'month') {
      setState({
        series: [55, 28, 17],
      });
    } else {
      setState({
        series: [48, 32, 20],
      });
    }
  };

  return (
    <div className="shadow-default col-span-12 rounded-lg border border-stroke bg-white p-lg xl:col-span-4">
      <div className="mb-3 justify-between gap-lg sm:flex">
        <div>
          <h5 className="text-md text-black">Orders Touchpoints</h5>
        </div>
        <div>
          <div className="relative z-20 inline-block">
            <select
              onChange={handleSelectChange}
              className="pr-smdxl relative z-20 inline-flex appearance-none rounded bg-transparent py-1 pl-slg text-sm font-medium outline-none"
            >
              <option value="month">Monthly</option>
              <option value="year">Yearly</option>
            </select>
          </div>
        </div>
      </div>

      <div className="mb-2">
        <div id="chartThree" className="mx-auto flex justify-center">
          <ReactApexChart
            options={options}
            series={state.series}
            type="donut"
          />
        </div>
      </div>

      <div className="-mx-8 flex flex-wrap items-center justify-center gap-y-slg">
        <div className="w-full px-8 sm:w-1/2">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-primary" />
            <p className="flex w-full justify-between text-sm font-medium text-black">
              <span> WebShop </span>
              <span>
                {' '}
                {state.series[0]}
                %
                {' '}
              </span>
            </p>
          </div>
        </div>
        <div className="w-full px-8 sm:w-1/2">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#6577F3]" />
            <p className="flex w-full justify-between text-sm font-medium text-black">
              <span> Email </span>
              <span>
                {' '}
                {state.series[1]}
                %
                {' '}
              </span>
            </p>
          </div>
        </div>
        <div className="w-full px-8 sm:w-1/2">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#8FD0EF]" />
            <p className="flex w-full justify-between text-sm font-medium text-black">
              <span> Phone </span>
              <span>
                {' '}
                {state.series[2]}
                %
                {' '}
              </span>
            </p>
          </div>
        </div>
        <div className="w-full px-8 sm:w-1/2" />
      </div>
    </div>
  );
};

export { ChartThree };

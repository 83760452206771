import {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react';

import { RetryWorkflowSidebar } from '../../sidebars/RetryWorkflow';
import { WorkflowRun } from '../../../models/Workflow';

interface Props {
  children: React.ReactNode;

  workflowRunId: string;
  isVoiceAttachment?: boolean;
  transcriptionLanguage?: string;
  onRetryWorkflow: (
    selectedWorkflow: string, workflowRun: WorkflowRun, transcriptionLanguage?: string,
  ) => void;
}

const WorkflowRetrySidebarWrapper = forwardRef(
  ({
    children, workflowRunId, isVoiceAttachment, transcriptionLanguage, onRetryWorkflow,
  }: Props, ref) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const onRetryWorkflowButtonClick = useCallback(() => {
      setIsSidebarOpen(true);
    }, []);

    const onSidebarClose = useCallback(() => {
      setIsSidebarOpen(false);
    }, []);

    useImperativeHandle(ref, () => ({
      onRetryWorkflowButtonClick,
      onSidebarClose,
    }));
    return (
      <>
        {children}

        <RetryWorkflowSidebar
          workflowRunId={workflowRunId}
          sidebarOpen={isSidebarOpen}
          setSidebarOpen={setIsSidebarOpen}
          isVoiceAttachment={isVoiceAttachment}
          transcriptionLanguage={transcriptionLanguage}
          onComplete={onRetryWorkflow}
        />
      </>
    );
  },
);

export default WorkflowRetrySidebarWrapper;

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { SettingsPage } from '../pages';

const SettingsScreen = () => (
  <ProtectedScreen>
    <SettingsPage />
  </ProtectedScreen>
);

export default SettingsScreen;

import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface CategoryProps {
  categories: string[];
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<string>;
}

const Categories = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}: CategoryProps) => (
  <Menu as="div" className="relative z-[100] inline-block text-left">
    <div>
      <Menu.Button className="xs relative left-[1px] inline-flex !h-[38px] w-auto min-w-36 items-center justify-between rounded-l-smd bg-white py-smd pl-4 pr-sm text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        {selectedCategory}
        <ChevronDownIcon
          className="-mr-1 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </Menu.Button>
    </div>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block w-full px-lg py-smd text-start text-sm',
                )}
                onClick={() => setSelectedCategory('All Categories')}
              >
                All Categories
              </button>
            )}
          </Menu.Item>
          {categories.map((category) => (
            <Menu.Item key={`category-${category}`}>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block w-full px-lg py-smd text-start text-sm',
                  )}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
);

interface SearchBarProp {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  categories: string[];
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<string>;
}

function ProductSearchBar({
  onSubmit,
  categories,
  selectedCategory,
  setSelectedCategory,
}: SearchBarProp) {
  return (
    <form onSubmit={onSubmit} className="flex items-center space-x-3">
      <div className="flex">
        <Categories
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <input
          type="text"
          name="search_query"
          id="search_query"
          className="block !h-[38px] min-w-[300px] border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset sm:text-sm sm:leading-6"
          placeholder="Name, ID or SKU"
        />
        <button
          type="submit"
          className="rounded-r-smd border border-l-0 border-blue-gray-100 bg-white py-1.5 pl-slg pr-lg text-sm text-blue-gray-200 shadow-sm"
        >
          Search
        </button>
      </div>
    </form>
  );
}

export { ProductSearchBar };

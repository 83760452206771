import { useCallback, useMemo } from 'react';
import { Tooltip } from '@mantine/core';
import Dayjs from 'dayjs';

import { isBefore, startOfToday } from 'date-fns';
import { isUnitConvertible } from 'features/order/utils/order';

import { Button } from 'components/ui/Button';
import { useOrderContext } from 'features/order/contexts/useOrderContext';
import { globalAlertData } from 'state/globalAlertData';
import { Order } from 'features/order/models/Order';
import { isZeroTime } from 'helpers/dateTime';
import { getUnits } from 'helpers/product';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { useLocation } from 'react-router-dom';

interface Props {
  orderDraftsProcessingMode: boolean;
  isInnerLoading: boolean;
  isNewOrderDraft: boolean;

  onCancel: () => void;
  onConfirmButtonClick: () => void;
  updateOrderDrafts: (
    orderDrafts: Order[],
    deletedOrderIds: string[],
    messageId?: string,
    createAlertData?: boolean,
    giveErrorFeedback?: boolean,
  ) => Promise<Order[]>;
  declineOrderDraft: (orderId: string) => Promise<void>;
}

const ButtonSection = ({
  orderDraftsProcessingMode,
  isInnerLoading,
  isNewOrderDraft,

  onCancel,
  onConfirmButtonClick,
  updateOrderDrafts,
  declineOrderDraft,
}: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const messageId = searchParams.get('message_id');

  const { order, onOrderDraftProcessed } = useProcessOrderContext();
  const {
    groupOrders,
    setGroupOrders,
    deletedOrderIds,
    setDeletedOrderIds,
  } = useOrderContext();

  const didChangeMade = useMemo(
    () => groupOrders.some((o) => o.didChangeMade),
    [groupOrders],
  );

  const newDraftCreated = useMemo(
    () => groupOrders.some((o) => o.isCreated),
    [groupOrders],
  );

  // If exists at least one product with incorrect unit
  const showUnitWarning = useMemo(
    () => groupOrders.some((o) => o?.products?.some(
      (p) => !getUnits(p?.product).includes(p?.unit?.symbol)
            && !isUnitConvertible(p?.unit?.symbol),
    ),
    ),
    [groupOrders],
  );

  // TODO: move validations in a separate file
  const existsAtLeastOneProduct = useMemo(
    () => !groupOrders.some(
      (o) => !o?.products
          || o.products.length === 0
          || o.products.some((p) => !p?.product?.name),
    ),
    [groupOrders],
  );

  const isDatetimeValid = useMemo(
    () => !groupOrders.some((o) => {
      if (!o?.requestedDeliveryTime) return true;

      const requestedDeliveryDatetime = Dayjs(
        o.requestedDeliveryTime,
      ).toDate();

      if (!requestedDeliveryDatetime) return true;

      return (
        isZeroTime(o.requestedDeliveryTime)
          || isBefore(requestedDeliveryDatetime, startOfToday())
      );
    }),
    [groupOrders],
  );

  const isValidQuantity = useMemo(
    () => !groupOrders.some((o) => o?.products?.some((p) => !p?.quantity)),
    [groupOrders],
  );

  const isValidUnit = useMemo(
    () => !groupOrders.some((o) => o?.products?.some((p) => !p?.unit)),
    [groupOrders],
  );

  const isValidCustomer = useMemo(
    () => !groupOrders.some((o) => !o?.customer?.customer),
    [groupOrders],
  );

  const saveTooltipLabel = useMemo(() => {
    if (!didChangeMade && deletedOrderIds.length === 0) {
      return 'No changes have been made yet';
    }

    if (!isValidCustomer) {
      return 'Select a customer';
    }

    if (isNewOrderDraft && !order?.customer?.customer?.isConfirmed) {
      return 'Manage the unkown customer.';
    }

    // Check if there is at least one product
    if (!existsAtLeastOneProduct) {
      return 'Add at least one product';
    }

    if (!isValidQuantity) {
      return 'Add a valid quantity to all products';
    }

    if (!isValidUnit) {
      return 'Add a valid unit to all products';
    }

    return '';
  }, [
    didChangeMade,
    deletedOrderIds.length,
    isValidCustomer,
    isNewOrderDraft,
    order?.customer?.customer?.isConfirmed,
    existsAtLeastOneProduct,
    isValidQuantity,
    isValidUnit,
  ]);

  const confirmTooltipLabel = useMemo(() => {
    if (!isDatetimeValid) {
      return 'Select a valid date';
    }

    if (!isValidCustomer) {
      return 'Select a customer';
    }

    if (!order?.customer?.customer?.isConfirmed) {
      return 'Manage the unkown customer.';
    }

    if (didChangeMade || deletedOrderIds.length > 0) {
      return 'Save changes before confirming.';
    }
    if (showUnitWarning) {
      return 'Specify all the units before confirming.';
    }
    return '';
  }, [
    deletedOrderIds.length,
    didChangeMade,
    isDatetimeValid,
    isValidCustomer,
    order?.customer?.customer?.isConfirmed,
    showUnitWarning,
  ]);

  // Button clicks
  const onDeclineButtonClick = useCallback(() => {
    if (!order?.id) return;

    declineOrderDraft(order.id).then(() => {
      onOrderDraftProcessed(order.id);
    });
  }, [order?.id, declineOrderDraft, onOrderDraftProcessed]);

  const onSaveButtonClick = useCallback(() => {
    if (!didChangeMade && !newDraftCreated && deletedOrderIds.length === 0) {
      globalAlertData.create(
        'No changes made',
        'No changes were made to the order draft.',
        'info',
      );
      return;
    }

    // Save action should save all the orders that has some changes
    updateOrderDrafts(groupOrders, deletedOrderIds, messageId, true, true).then(
      (orders) => {
        setGroupOrders(
          orders.map((_order) => ({
            ..._order,
            didChangeMade: false,
            isCreated: false,
          })),
        );
        setDeletedOrderIds([]);
      },
    );
  }, [
    didChangeMade,
    newDraftCreated,
    messageId,
    updateOrderDrafts,
    groupOrders,
    deletedOrderIds,
    setGroupOrders,
    setDeletedOrderIds,
  ]);
  return (
    <>
      <div>
        <Button title="Decline" theme="red" onClick={onDeclineButtonClick} />
      </div>
      <div className="text-[0.6rem] px-4 h-full flex items-center italic">
        <p className="text-gray-400">
          This is an alpha version of Adam. Please double check the
          output and report bugs to
          {' '}
          <a href="mailto:support@hoshii.ai">
            support@hoshii.ai
          </a>
          .
        </p>
      </div>
      <div className="flex gap-sm">
        {orderDraftsProcessingMode ? null : (
          <Button
            theme="secondary"
            title="Cancel"
            disabled={isInnerLoading}
            onClick={onCancel}
          />
        )}
        {
          confirmTooltipLabel === '' ? (
            <Tooltip
              label={confirmTooltipLabel}
              transitionProps={{ transition: 'pop', duration: 300 }}
              hidden={confirmTooltipLabel === ''}
            >
              <Button
                title="Confirm"
                disabled={isInnerLoading || confirmTooltipLabel !== ''}
                onClick={onConfirmButtonClick}
              />
            </Tooltip>
          ) : (
            <Tooltip
              label={saveTooltipLabel}
              transitionProps={{ transition: 'pop', duration: 300 }}
              hidden={saveTooltipLabel === ''}
            >
              <Button
                theme="secondary"
                title="Save"
                disabled={isInnerLoading || saveTooltipLabel !== ''}
                onClick={onSaveButtonClick}
              />
            </Tooltip>
          )
        }
      </div>
    </>
  );
};

export default ButtonSection;

import { useEffect, useCallback } from 'react';
import { reaction } from 'mobx';
import { useLocalObservable } from 'mobx-react-lite';

import { LROData, pendingLROsStore, pollPendingLROGroup } from '../state/globalLROs';
import { genericErrorFeedback } from '../helpers/errors';
import { Operation } from '../models/Lro';
import { WorkflowRun } from '../models/Workflow';

export const usePendingLROs = (group: string, onLroDone: (lro: Operation<WorkflowRun>) => void) => {
  const localStore = useLocalObservable(() => ({
    get pendingLROs() {
      return pendingLROsStore.pendingLROsByGroup.get(group) ?? new Map<string, LROData>();
    },
    addPendingLRO(lroId: string, data: any) {
      pendingLROsStore.addPendingLRO(lroId, { group, data });
    },
    removePendingLRO(lroId: string) {
      pendingLROsStore.removePendingLRO(lroId);
    },
  }));

  const startPolling = useCallback(() => {
    const intervalId = setInterval(() => {
      pollPendingLROGroup(group).then((lros) => {
        lros.forEach((lro) => {
          onLroDone(lro);
        });
      }).catch((err) => {
        genericErrorFeedback(`Error polling LROs for group ${group}: ${err}`);
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, [group, onLroDone]);

  useEffect(() => {
    let stopPolling: (() => void) | undefined;

    const dispose = reaction(
      () => localStore.pendingLROs.size,
      (size) => {
        if (size > 0 && !stopPolling) {
          stopPolling = startPolling();
        } else if (size === 0 && stopPolling) {
          stopPolling();
          stopPolling = undefined;
        }
      },
      { fireImmediately: true },
    );

    return () => {
      dispose();
      if (stopPolling) stopPolling();
    };
  }, [localStore, startPolling]);

  return localStore;
};

import { Modal } from 'flowbite-react';
import ParseHtml from 'html-react-parser';

interface Props {
  html: string;
  isHtmlPreviewModalVisible: boolean;
  onHtmlPreviewModalClose: () => void;
}

const PreviewHTMLModal = ({
  html,
  isHtmlPreviewModalVisible,
  onHtmlPreviewModalClose,
}: Props) => (
  <Modal
    show={isHtmlPreviewModalVisible}
    onClose={onHtmlPreviewModalClose}
    size="7xl"
    position="top-center"
    dismissible
  >
    <Modal.Header>
      <div className="flex items-center gap-smd">Original email content</div>
    </Modal.Header>
    <Modal.Body className="p-smd">
      <div className="relative overflow-hidden rounded-lg">
        {ParseHtml(html)}
      </div>
    </Modal.Body>
  </Modal>
);

export { PreviewHTMLModal };

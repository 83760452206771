import { useState, useCallback } from 'react';

import { httpPost } from '../helpers/xhr';
import { genericErrorFeedback } from '../helpers/errors';
import { globalAlertData } from '../state/globalAlertData';
import { Prompt } from '../models/Prompt';

const useCreatePrompt = () => {
  const [isLoading, setIsLoading] = useState(false);

  const createPrompt = useCallback(
    async (
      prompt: Prompt,
      promptName: string,
      pipeline: string,
      createAlertData: boolean = true,
      giveErrorFeedback: boolean = true,
    ): Promise<Prompt> => {
      if (isLoading) {
        return Promise.reject(
          new Error('Orders creation is already in progress'),
        );
      }

      setIsLoading(true);

      const filteredPrompt = {
        ...prompt,
        boundTypeSpecs: prompt.boundTypeSpecs.map((spec) => ({
          ...spec,
          fields: spec.fields.filter((field) => field.path && field.name),
        })),
      };

      return httpPost(`/llm/pipelines/${pipeline}/prompts`, {
        name: promptName,
        specs: filteredPrompt.boundTypeSpecs,
        customerIds: filteredPrompt.customerIds,
      })
        .then((response) => {
          if (createAlertData) {
            globalAlertData.create('Prompt created successfully');
          }
          return response.data as Prompt;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Create order failed')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [isLoading],
  );

  return { isLoading, createPrompt };
};

export { useCreatePrompt };

import { Business } from '../models/Business';
import { Message } from '../models/Message';
import { globalUser } from '../state/globalUser';
import { MessageDirection } from '../types/message';

const getMessageDirection = (
  message: Message,
  business: Business,
) => {
  if (!business) {
    return MessageDirection.Received;
  }

  // Internal chat (i.e., same business, user-to-user), direction
  // is determined by the user
  if (business.id === globalUser.business?.id) {
    return message.userSentBy === globalUser.id ? MessageDirection.Sent : MessageDirection.Received;
  }

  // External chat (i.e., different business, business-to-user)
  return message.userSentBy === globalUser.id
    && message.businessSentBy === globalUser.business.id ? MessageDirection.Sent : MessageDirection.Received;
};

function decodeEntities(encodedString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(encodedString, 'text/html');
  return doc.documentElement.textContent;
}

export { getMessageDirection, decodeEntities };

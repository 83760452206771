import { User } from './classes/User';

let globalUser: User = null;

const initGlobalUser = async () => {
  globalUser = new User();
  await globalUser.initPersistence();
};

export { initGlobalUser, globalUser };

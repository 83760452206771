import TypeWriter from 'typewriter-effect';
import { AdamMessageWrapper } from '../../../../wrapper/AdamMessageWrapper';

const text = 'Please enter the name for your new instruction.';

const SpecifyInstructionName = () => (
  <AdamMessageWrapper>
    <TypeWriter
      onInit={(typewriter) => {
        typewriter
          .changeDelay(15)
          .typeString(text)
          .callFunction(() => {
            document
              .querySelectorAll('.Typewriter__cursor')
              ?.forEach((cursor) => {
                cursor.classList.add('hidden');
              });
          })
          .start();
      }}
    />
  </AdamMessageWrapper>
);
export default SpecifyInstructionName;

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Card } from 'components/common/Card';
import { BusinessSettings } from '../../../../../models/BusinessSettings';

import Select from '../../ui/Select/Select';
import Input from '../../ui/Input';

interface Props {
  businessSettings: BusinessSettings;
}

const Models = ({ businessSettings }: Props) => (
  <Card id="erp_integration_models">
    <Card.Header>ERP models</Card.Header>
    <Card.Body>
      {!businessSettings?.erpConnectionSettings?.syncSettings
        ?.syncedEntities && (
        <div className="flex justify-center text-sm text-gray-900/50">
          No ERP models found.
        </div>
      )}

      {businessSettings?.erpConnectionSettings?.syncSettings
        ?.syncedEntities && (
        <>
          <div className="text-sm text-gray-900/50">
            The following information is technical and whenever you want to
            change the settings, please contact our technical staff.
          </div>
          <div className="space-y-6">
            {Object.entries(
              businessSettings?.erpConnectionSettings?.syncSettings
                ?.syncedEntities || {},
            ).map(([entityName, entity]) => (
              <Disclosure as="div" key={entityName} className="">
                <DisclosureButton className="group flex w-full items-center justify-between">
                  <span className="text-sm/6 font-medium text-gray-900 group-data-[hover]:text-gray-900/80">
                    {entityName.charAt(0).toUpperCase() + entityName.slice(1)}
                  </span>
                  <ChevronDownIcon className="size-5 fill-gray-900/60 group-data-[open]:rotate-180 group-data-[hover]:fill-gray-900/50" />
                </DisclosureButton>
                <DisclosurePanel className="mt-2 space-y-2 text-sm/5 text-gray-900/50">
                  <Select
                    label="Status"
                    value={entity?.enabled ? 'Enabled' : 'Disabled'}
                    values={['Enabled', 'Disabled']}
                  />
                  <Input label="Direction" value={entity?.direction} />
                  <Input label="Interval" value={entity?.interval} />
                  <Select
                    label="Delete excluded documents"
                    value={entity?.softDeleteExcludedDocuments ? 'Yes' : 'No'}
                    values={['Yes', 'No']}
                  />
                  <Input label="Matcher ID" value={entity?.matcherId} />
                  <Input label="Encoding" value={entity?.encoding} />
                  <Input
                    label="Input search directory"
                    value={entity?.inputSearchDirectory}
                  />
                  <Input
                    label="Input filename regex"
                    value={entity?.inputFilenameRegex}
                  />
                  <Input label="Output format" value={entity?.outputFormat} />
                  <Input
                    label="Output directory"
                    value={entity?.outputDirectory}
                  />
                  <Input
                    label="Output filename format"
                    value={entity?.outputFilenameFormat}
                  />
                  <Input
                    label="Output filename format args"
                    value={entity?.outputFilenameFormatArgs?.join(', ')}
                  />
                  <Input
                    label="Output max records per file"
                    value={entity?.outputMaxRecordsPerFile?.toString()}
                  />
                </DisclosurePanel>
              </Disclosure>
            ))}
          </div>
        </>
      )}
    </Card.Body>
  </Card>
);

export default Models;

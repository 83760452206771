import { Formik } from 'formik';
import { object, ref, string } from 'yup';

import { useEffect, useRef } from 'react';
import { phone, username, password } from 'helpers/validations';
import { Button } from '../ui/Button';
import { FormInput } from '../FormInput';
import { LoadingIndicator } from '../LoadingIndicator';

interface AccessData {
  username: string;
  password: string;
  confirmPassword: string;
  phone: string;
  email: string;
  confirmEmail: string;
}

const formInitialValues = {
  username: '',
  password: '',
  confirmPassword: '',
  phone: '',
  email: '',
  confirmEmail: '',
};

// const formInitialValues = {
//   username: 'test',
//   password: 'testTest119',
//   confirmPassword: 'testTest119',
//   phone: '+41788601342',
//   email: 'testtest@gmail.com',
//   confirmEmail: 'testtest@gmail.com',
// };

const formValidationSchema = object().shape({
  username: username().required(),
  password: password().required(),
  confirmPassword: string()
    .required()
    .oneOf([ref('password')], "Does't match the password"),
  phone: phone().required(),
  email: string().email().required(),
  confirmEmail: string()
    .required()
    .oneOf([ref('email')], "Does't match the email"),
});

interface Props {
  isLoading: boolean;
  onCompletion: (accessData: AccessData) => void;
}

const AccessDataStep = ({ onCompletion, isLoading }: Props) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (buttonRef.current && event.key === 'Enter') {
        buttonRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <LoadingIndicator isLoading={isLoading} />

      <div className="flex flex-col">
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={onCompletion}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <div className="flex flex-col gap-lg">
              <div className="felx-1 flex flex-col gap-lg">
                <FormInput
                  field="username"
                  required
                  disabled={isLoading}
                  label="Username"
                  value={values.username}
                  error={errors.username}
                  touched={touched.username}
                  onChange={handleChange('username')}
                  onBlur={handleBlur('username')}
                />
                <FormInput
                  field="password"
                  type="password"
                  required
                  disabled={isLoading}
                  label="Password"
                  value={values.password}
                  error={errors.password}
                  touched={touched.password}
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                />
                <FormInput
                  field="confirmPassword"
                  type="password"
                  required
                  disabled={isLoading}
                  label="Confirm password"
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  onBlur={handleBlur('confirmPassword')}
                />
                <FormInput
                  field="phone"
                  type="phone"
                  required
                  disabled={isLoading}
                  label="Phone"
                  value={values.phone}
                  error={errors.phone}
                  touched={touched.phone}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur('phone')}
                />
                <FormInput
                  field="email"
                  required
                  disabled={isLoading}
                  label="Email"
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                />
                <FormInput
                  field="confirmEmail"
                  required
                  disabled={isLoading}
                  label="Confirm email"
                  value={values.confirmEmail}
                  error={errors.confirmEmail}
                  touched={touched.confirmEmail}
                  onChange={handleChange('confirmEmail')}
                  onBlur={handleBlur('confirmEmail')}
                />
              </div>

              <div className="mt-lg flex w-full justify-end border-t pt-lg">
                <Button
                  title="Next"
                  theme="indigo"
                  radius="md"
                  ref={buttonRef}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export { AccessDataStep };

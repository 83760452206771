import { useFormContext } from '../../../../contexts/useFormContext';
import { Button } from '../../../ui/Button';

interface Props {
  isLoading: boolean;
}

const _Footer: React.FC<Props> = ({ isLoading }) => {
  const { handleSubmit } = useFormContext();
  return (
    <div className="flex w-full justify-end">
      <Button
        title="Save"
        disabled={isLoading}
        onClick={() => handleSubmit()}
      />
    </div>
  );
};

export { _Footer };

import {
  memo, useCallback, useEffect, useMemo,
} from 'react';
import { uniq } from 'lodash';

import { ContentTheme, Select, SelectOption } from 'components/ui/Select';
import { isUnitConvertible } from 'features/order/utils/order';
import { getUnits } from 'helpers/product';
import { Unit } from 'models/Product';

import { _UnitSelectProps, UnitSelectProps, UnitWithConversion } from './types';

const MemoizedSelect = memo(({
  label,
  size,
  selectedUnit,
  allOptions,
  onUnitSelectChange,
}: {
  label: string;
  size: 'sm' | 'xs';
  selectedUnit: SelectOption;
  allOptions: SelectOption[];
  onUnitSelectChange: (unit: SelectOption) => void;
}) => (
  <Select
    label={label}
    size={size}
    placeholder="UnitWithConversion"
    selectedOption={selectedUnit}
    options={allOptions}
    onSelectionChange={onUnitSelectChange}
  />
));

const MemoizedUnitSelect = memo(({
  index,
  product,
  unitSymbol,
  autoMatchedUnitSymbol,
  label = '',
  size = 'sm',
  onUnitChange,
  setError,
}: _UnitSelectProps) => {
  const doesUnitMatch: boolean = useMemo(
    () => getUnits(product).includes(
      unitSymbol,
    ),
    [product, unitSymbol],
  );

  const willAdamConvertUnit = useMemo(
    () => product?.defaultUnitConversionFactor !== undefined
      && product?.defaultUnitConversionFactor !== null
      && !getUnits(product).includes(
        unitSymbol,
      )
      && isUnitConvertible(unitSymbol),
    [product, unitSymbol],
  );

  const allUnits: UnitWithConversion[] = useMemo(() => {
    const units = getUnits(product).map((unit) => ({
      value: unit,
      willAdamConvertUnit: false,
    }));

    if (
      !getUnits(product).includes(
        unitSymbol,
      )
      && isUnitConvertible(unitSymbol)
      && product?.defaultUnitConversionFactor
    ) {
      units.unshift({
        value: unitSymbol,
        willAdamConvertUnit: true,
      });
    }

    return uniq(units);
  }, [product, unitSymbol]);

  const selectedUnit: SelectOption = useMemo(() => {
    if (
      doesUnitMatch
      && unitSymbol
        === autoMatchedUnitSymbol
    ) {
      return {
        label: unitSymbol,
        value: unitSymbol,
        contentTheme: ContentTheme.AIInfo,
        customLabelTooltip: 'Adam has detected this unit',
      };
    }

    if (doesUnitMatch || !unitSymbol) {
      return {
        label: unitSymbol,
        value: unitSymbol,
      };
    }

    if (!doesUnitMatch && willAdamConvertUnit) {
      return {
        label: unitSymbol,
        value: unitSymbol,
        contentTheme: ContentTheme.AIImproved,
        customLabelTooltip:
          'Adam will convert this unit to the default unit for this product',
      };
    }

    return {
      label: unitSymbol,
      value: unitSymbol,
      contentTheme: ContentTheme.AIWarning,
      customLabelTooltip:
        'Adam has detected this unit which is not in the list of units for this product',
    };
  }, [
    doesUnitMatch,
    unitSymbol,
    autoMatchedUnitSymbol,
    willAdamConvertUnit,
  ]);

  const allOptions: SelectOption[] = useMemo(
    () => allUnits.map((unit): SelectOption => {
      let contentTheme = ContentTheme.STANDARD;
      let customLabelTooltip = null;

      if (unit.willAdamConvertUnit) {
        contentTheme = ContentTheme.AIImproved;
        customLabelTooltip = 'Adam has detected this unit, but the unit is not a valid sales unit';
      } else if (
        unit.value === autoMatchedUnitSymbol
      ) {
        contentTheme = ContentTheme.AIInfo;
        customLabelTooltip = 'Adam has detected this unit';
      }

      return {
        label: unit.value,
        value: unit.value,
        contentTheme,
        customLabelTooltip,
      };
    }),
    [allUnits, autoMatchedUnitSymbol],
  );

  const onUnitSelectChange = useCallback(
    (unit: SelectOption) => {
      const unitObj = product?.salesUnits.find(
        (u: Unit) => u.symbol === unit.value,
      );
      onUnitChange(index, unitObj);
    },
    [product?.salesUnits, onUnitChange, index],
  );

  useEffect(() => {
    if (!doesUnitMatch) {
      setError?.('Inconsistent units found');
    } else {
      setError?.('');
    }
  }, [doesUnitMatch, setError]);
  return (
    <div className="flex w-[170px] lg:w-[20ch]">
      <MemoizedSelect
        label={label}
        size={size}
        selectedUnit={selectedUnit}
        allOptions={allOptions}
        onUnitSelectChange={onUnitSelectChange}
      />
    </div>
  );
});

const UnitSelect = memo(({
  index,
  productWithQuantity,
  label = '',
  size = 'sm',
  onUnitChange,
  setError,
}: UnitSelectProps) => (
  <MemoizedUnitSelect
    index={index}
    product={productWithQuantity?.product}
    unitSymbol={productWithQuantity?.unit?.symbol}
    autoMatchedUnitSymbol={productWithQuantity?.autoMatchedUnit?.symbol}
    label={label}
    size={size}
    onUnitChange={onUnitChange}
    setError={setError}
  />
));

export default UnitSelect;

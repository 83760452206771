import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'config/routes';

import { InvitedSignupPage } from '../pages';

const InvitedSignupScreen = () => {
  const { appLink } = useParams();
  const navigate = useNavigate();

  const navigateToHome = useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  return (
    <InvitedSignupPage inviteAppLink={appLink} navigateToHome={navigateToHome} />
  );
};

export default InvitedSignupScreen;

import {
  MotionValue,
  Reorder,
  animate,
  useDragControls,
  useMotionValue,
} from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { Bars3Icon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import {
  MinusIcon, PlusIcon, QuestionMarkCircleIcon, TrashIcon,
} from '@heroicons/react/24/outline';
import {
  Checkbox,
  Input,
  ThemeIcon,
  Popover,
  TextInput,
  Textarea,
  InputWrapper,
  Tooltip,
} from '@mantine/core';

import { GroupedCombobox } from '../../../../../settings/SettingCard/ui/GroupedCombobox';
import { GroupedOption } from '../../../../../settings/SettingCard/ui/GroupedCombobox/types';
import { FieldSpec } from '../../../../../../models/Schema';
import { Button } from '../../../../../ui/Button';

const inactiveShadow = '0px 0px 0px rgba(0,0,0,0.8)';

export function useRaisedShadow(value: MotionValue<number>) {
  const boxShadow = useMotionValue(inactiveShadow);

  useEffect(() => {
    let isActive = false;
    value.onChange((latest) => {
      const wasActive = isActive;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(boxShadow, '5px 5px 10px rgba(0,0,0,0.3)');
        }
      } else {
        isActive = false;
        if (isActive !== wasActive) {
          animate(boxShadow, inactiveShadow);
        }
      }
    });
  }, [value, boxShadow]);

  return boxShadow;
}

interface Props {
  fieldSpec: FieldSpec;
  setFieldSpecs: React.Dispatch<React.SetStateAction<FieldSpec[]>>;
  groupedOptions: GroupedOption[];
  uiId: string;
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }, // Add exit animation
};

const ContentItem = ({
  fieldSpec,
  setFieldSpecs,
  groupedOptions,
  uiId,
}: Props) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();

  const isRemovable = typeof fieldSpec.isRemovable !== 'boolean' || fieldSpec.isRemovable;

  const isErpBindable = useMemo(() => {
    const option = groupedOptions
      .map((_option) => _option.options).flat()
      .find((_option) => _option.value === fieldSpec.path);
    return option?.meta?.isErpBindable;
  }, [fieldSpec.path, groupedOptions]);

  return (
    <Reorder.Item
      id={`content-${fieldSpec.uiId}`}
      className="grid w-full grid-cols-[repeat(21,minmax(0,1fr))] items-center gap-4 px-1 pb-2 2xl:pb-4"
      value={fieldSpec}
      variants={itemVariants}
      exit="exit"
      style={{ boxShadow, y }}
      dragListener={false}
      dragControls={dragControls}
    >
      <div className="col-span-1 flex justify-center">
        <Bars3Icon
          className="h-5 w-5 cursor-pointer"
          onPointerDown={(e) => {
            e.preventDefault();
            dragControls.start(e);
          }}
        />
      </div>
      <Input
        className="col-span-8 w-full"
        value={fieldSpec.name}
        placeholder="Enter the field name"
        maxLength={32}
        onChange={(e) => {
          setFieldSpecs((_fieldSpecs) => _fieldSpecs.map((_fieldSpec) => (_fieldSpec.uiId === uiId
            ? {
              ..._fieldSpec,
              name: e.target.value,
            }
            : _fieldSpec),
          ),
          );
        }}
      />
      <GroupedCombobox
        placeHolder="Select the erp field name"
        groupedOptions={groupedOptions}
        selectedValue={fieldSpec.path}
        inputBaseClassName="w-full col-span-8"
        onChange={(option) => {
          setFieldSpecs((_fieldSpecs) => _fieldSpecs.map((_fieldSpec) => (_fieldSpec.uiId === uiId
            ? {
              ..._fieldSpec,
              path: option.value,
              inputType: option.meta?.inputType,
              isErpBindable: option.meta?.isErpBindable,
              type: option.meta?.type,
            }
            : _fieldSpec),
          ),
          );
        }}
        disabled={!isRemovable}
      />
      <div className="col-span-2 flex justify-center">
        <Checkbox
          radius="xs"
          checked={fieldSpec.isErpBindable}
          onChange={(e) => {
            setFieldSpecs((_fieldSpecs) => _fieldSpecs.map((_fieldSpec) => (_fieldSpec.uiId === uiId
              ? {
                ..._fieldSpec,
                isErpBindable: e.target.checked,
              }
              : _fieldSpec),
            ),
            );
          }}
          disabled={!isErpBindable || !isRemovable}
          readOnly={!isErpBindable}
        />
      </div>
      <div className="col-span-1 flex justify-center">
        <Popover
          position="top"
          clickOutsideEvents={['mouseup', 'touchend']}
          shadow="md"
          withArrow
        >
          <Popover.Target>
            <ThemeIcon size="xs" color="#868e96" radius="full" variant="outline" className="cursor-pointer">
              <EllipsisHorizontalIcon style={{ width: '100%', height: '100%' }} />
            </ThemeIcon>
          </Popover.Target>
          <Popover.Dropdown>
            <div className="space-y-4">
              <Textarea
                className="w-[400px]"
                label="Description"
                description="Give a short description of the field. Max 100 characters."
                value={fieldSpec.description}
                maxRows={4}
                maxLength={101}
                onChange={(e) => {
                  setFieldSpecs((_fieldSpecs) => _fieldSpecs.map((_fieldSpec) => (_fieldSpec.uiId === uiId
                    ? {
                      ..._fieldSpec,
                      description: e.target.value,
                    }
                    : _fieldSpec),
                  ),
                  );
                }}
                autosize
              />
              <InputWrapper
                label="Examples"
                className="space-y-2"
                description="Provide some examples of the field to help Adam understand it better. Max 5 examples."
              >
                {fieldSpec.examples?.map((example, i) => (
                  <div key={`${uiId}-example-${i.toString()}`} className="flex items-center gap-2">
                    <TextInput
                      value={example}
                      maxLength={64}
                      className="w-full"
                      onChange={(e) => {
                        setFieldSpecs((_fieldSpecs) => _fieldSpecs.map((_fieldSpec) => (_fieldSpec.uiId === uiId
                          ? {
                            ..._fieldSpec,
                            examples: _fieldSpec.examples.map(
                              (_example, _i) => (_i === i ? e.target.value : _example),
                            ),
                          }
                          : _fieldSpec),
                        ),
                        );
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setFieldSpecs((_fieldSpecs) => _fieldSpecs.map((_fieldSpec) => (_fieldSpec.uiId === uiId
                          ? {
                            ..._fieldSpec,
                            examples: _fieldSpec.examples.filter((_, _i) => _i !== i),
                          }
                          : _fieldSpec),
                        ),
                        );
                      }}
                    >
                      <MinusIcon className="h-6 w-6" />
                    </button>
                  </div>
                ))}
                <div className="w-full flex justify-center">
                  <Button
                    icon={<PlusIcon className="h-6 w-6" />}
                    variant="small"
                    theme="secondary"
                    radius="sm"
                    onClick={() => {
                      if (fieldSpec.examples?.length === 5) {
                        return;
                      }
                      setFieldSpecs((_fieldSpecs) => _fieldSpecs.map((_fieldSpec) => (_fieldSpec.uiId === uiId
                        ? {
                          ..._fieldSpec,
                          examples: [...(_fieldSpec.examples || []), ''],
                        }
                        : _fieldSpec),
                      ),
                      );
                    }}
                  />
                </div>
              </InputWrapper>
            </div>
          </Popover.Dropdown>
        </Popover>
      </div>
      {
        isRemovable ? (
          <button
            type="button"
            className="col-span-1 flex justify-center"
            onClick={() => {
              setFieldSpecs((_fieldSpecs) => _fieldSpecs.filter((_fieldSpec) => _fieldSpec.uiId !== uiId),
              );
            }}
          >
            <ThemeIcon size="xs" color="#868e96" variant="white" className="cursor-pointer">
              <TrashIcon style={{ width: '100%', height: '100%' }} />
            </ThemeIcon>
          </button>
        ) : (
          <Tooltip
            label="This is a default field that cannot be removed"
            transitionProps={{ transition: 'pop', duration: 200 }}
            bg="white"
            styles={{
              tooltip: {
                color: 'black',
                border: '1.5px solid #E8E8E8',
                boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
              },
            }}
          >
            <ThemeIcon size="xs" color="#868e96" variant="white" className="cursor-pointer">
              <QuestionMarkCircleIcon style={{ width: '100%', height: '100%' }} />
            </ThemeIcon>
          </Tooltip>
        )
      }
    </Reorder.Item>
  );
};

export default ContentItem;

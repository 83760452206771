import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { NewAdamChatPage } from '../pages';

const NewAdamChatScreen = () => (
  <ProtectedScreen>
    <NewAdamChatPage />
  </ProtectedScreen>
);

export default NewAdamChatScreen;

import {
  ReactNode, createContext, useContext, useMemo, useState,
} from 'react';

type KeywordAndMessageIdType = {
  keyword: string;
  messageId: string;
};

type PlayRecordingKeywordAndMessageIdType = {
  isRecordingAvailable: boolean;
  setIsRecordingAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  playRecordingKeywordAndMessageId: KeywordAndMessageIdType;
  setPlayRecordingKeywordAndMessageId: React.Dispatch<
  React.SetStateAction<KeywordAndMessageIdType>
  >;
};

const PlayRecordingContext = createContext<
PlayRecordingKeywordAndMessageIdType | undefined
>(undefined);

type PlayRecordingProviderProps = {
  children: ReactNode;
};

const PlayRecordingProvider: React.FC<PlayRecordingProviderProps> = ({
  children,
}: PlayRecordingProviderProps) => {
  const [isRecordingAvailable, setIsRecordingAvailable] = useState<boolean>(false);
  const [
    playRecordingKeywordAndMessageId,
    setPlayRecordingKeywordAndMessageId,
  ] = useState<KeywordAndMessageIdType>(null);

  const contextValue = useMemo(
    () => ({
      isRecordingAvailable,
      setIsRecordingAvailable,
      playRecordingKeywordAndMessageId,
      setPlayRecordingKeywordAndMessageId,
    }),
    [isRecordingAvailable, playRecordingKeywordAndMessageId],
  );

  return (
    <PlayRecordingContext.Provider value={contextValue}>
      {children}
    </PlayRecordingContext.Provider>
  );
};

const usePlayRecordingContext = () => {
  const context = useContext(PlayRecordingContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by PlayRecordingContext provider.');
  }

  return context;
};

export { usePlayRecordingContext, PlayRecordingProvider };

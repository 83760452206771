import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

import { globalUser } from '../../state/globalUser';

interface Props {
  children: ReactNode;
}

const ProtectedScreen = observer(({ children }: Props) => {
  if (!globalUser.isSignedIn) {
    return <Navigate to="/signin" />;
  }
  if (!globalUser.activated) {
    return <Navigate to="/activate-user" />;
  }
  return <section>{children}</section>;
});

export { ProtectedScreen };

import { useState } from 'react';

import { Page } from 'components/layout/Page/Page';
import { Product } from 'models/Product';

interface Props {
  productId: string;
  navigateToProductsList: () => void;
}

const ProductDetailsPage = ({ productId, navigateToProductsList }: Props) => {
  const [product, setProduct] = useState<Product>();
  const [isLoading, setIsLoading] = useState(false);

  console.log(
    productId,
    navigateToProductsList,
    product,
    setProduct,
    setIsLoading,
  );

  return (
    <Page isLoading={isLoading}>
      Product page content
    </Page>
  );
};

export default ProductDetailsPage;

import { Component, ErrorInfo, ReactNode } from 'react';
import { Button } from './Button';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
  error: Error;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error occurred:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="m-lg flex flex-1 flex-col gap-md rounded-lg border border-[orange] bg-white p-lg">
          <b>Uncaught error occurred:</b>
          <div>{this.state.error.message}</div>
          <div>{this.state.error.stack}</div>
          <div>
            <Button
              title="Reload app"
              onClick={() => {
                window.location.replace('/');
              }}
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { ReactNode, useMemo } from 'react';

interface Props {
  title: string;
  description: string;
  theme?: 'info' | 'warning';
  size?: 'small' | 'normal';
  children?: ReactNode;
}

const FlashCard = ({
  title, description, theme, size, children,
}: Props) => {
  const wrapperStyle: string = useMemo(() => {
    const style: string = 'gap-sm p-lg rounded-lg flex justify-start w-full';
    switch (theme) {
      case 'info':
        return `${style} bg-[rbg(232,242,255)] border border-primary`;
      case 'warning':
        return `${style} bg-warning-bg border border-warning-border`;
      default:
        return style;
    }
  }, [theme]);

  const iconColor: string = useMemo(() => {
    switch (theme) {
      case 'info':
        return 'text-primary';
      case 'warning':
        return 'text-[#ff6f00]';
      default:
        return '';
    }
  }, [theme]);

  const titleColor: string = useMemo(() => {
    switch (theme) {
      case 'warning':
        return 'text-warning-title';
      default:
        return '';
    }
  }, [theme]);

  const descriptionColor: string = useMemo(() => {
    switch (theme) {
      case 'warning':
        return 'text-warning-description';
      default:
        return '';
    }
  }, [theme]);

  return (
    <div className={wrapperStyle}>
      <div>
        <ExclamationTriangleIcon
          className={`${iconColor} ${size === 'normal' ? 'w-[24px]' : 'w-[16px]'} aspect-square`}
        />
      </div>
      <div>
        <div className="items-center">
          <div
            className={`${titleColor} ${size === 'normal' ? 'text-title-sm' : 'text-xs'}`}
          >
            {title}
          </div>
        </div>
        <div
          className={`text-description ${size === 'normal' ? 'text-label-caption-md' : 'text-label-caption-sm'} ${descriptionColor}`}
        >
          {description}
        </div>
        {children ? <div className="mt-2">{children}</div> : null}
      </div>
    </div>
  );
};

FlashCard.defaultProps = {
  theme: 'info',
  size: 'normal',
  children: null,
};

export { FlashCard };

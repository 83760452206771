import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { ROUTES } from 'config/routes';

import { ProductListPage } from '../pages';

const ProductListScreen = () => {
  const navigate = useNavigate();

  const navigateToProductsImportList = useCallback(() => {
    navigate(ROUTES.PRODUCTS_IMPORT);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <ProductListPage
        navigateToProductsImportList={navigateToProductsImportList}
      />
    </ProtectedScreen>
  );
};

export default ProductListScreen;

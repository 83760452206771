import {
  useCallback,
  useEffect, useMemo,
} from 'react';
import {
  Accordion,
} from '@mantine/core';
import LoadingOverlay from 'components/ui/LoadingOverlay';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { groupFields } from 'helpers/prompt';
import { useOrderContext } from 'features/order/contexts/useOrderContext';
import { useSchemasContext } from 'contexts/useSchemasContext';
import GroupFieldAccordionItem from './GroupFieldAccordionItem';
import AssigneeAccordionItem from './AssigneeAccordionItem';

const OrderDraftRender = () => {
  const {
    groupOrders, selectedIndex,
  } = useOrderContext();
  const { schemas } = useSchemasContext();
  const {
    groupedFields, setGroupedFields,
    typeRef, setTypeRef,
    confirmedFields, onConfirmField,
    accordionValue, setAccordionValue,
    onAccordionItemClick,
    setFirstAccordionValue,
  } = useProcessOrderContext();

  const selectedOrder = useMemo(() => {
    const _order = groupOrders[selectedIndex];
    return {
      ..._order,
      products: (_order?.products || []).map((product) => ({ ...product })),
    };
  }, [groupOrders, selectedIndex]);

  const onMarkAsCheckedButtonClick = useCallback((key: string) => {
    onConfirmField(key);
  }, [onConfirmField]);

  useEffect(() => {
    const _typeRef = selectedOrder?.typeSpecs?.[0]?.typeRef;

    setTypeRef(_typeRef);

    const _groupedFields = groupFields(selectedOrder?.typeSpecs?.[0]?.fields, schemas[_typeRef]);

    setGroupedFields(_groupedFields);

    setFirstAccordionValue(_groupedFields);
  }, [schemas, selectedOrder?.typeSpecs, setFirstAccordionValue, setGroupedFields, setTypeRef]);

  if (groupedFields.length === 0) {
    return <LoadingOverlay visible displayText="Loading order processing view" />;
  }

  return (
    <Accordion
      multiple
      transitionDuration={300}
      value={accordionValue}
    >
      <AssigneeAccordionItem
        setAccordionValue={setAccordionValue}
        accordionValue={accordionValue}
      />
      {
          groupedFields.map((groupedField) => (
            <GroupFieldAccordionItem
              key={groupedField.title}
              groupKey={groupedField.title}
              isConfirmed={confirmedFields.includes(groupedField.title)}
              showItem={accordionValue.includes(groupedField.title)}
              groupedFields={groupedField}
              selectedOrder={selectedOrder}
              schemas={schemas}
              typeRef={typeRef}
              onAccordionItemClick={onAccordionItemClick}
              onMarkAsCheckedButtonClick={onMarkAsCheckedButtonClick}
            />
          ))
      }
    </Accordion>
  );
};

export default OrderDraftRender;

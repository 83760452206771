import Axios, {
  AxiosError,
  AxiosInterceptorOptions,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import AxiosRetry from 'axios-retry';

import { doesExist } from './comparison';
import { convertKeysToCamelCase, convertKeysToSnakeCase } from './mapping';

const axiosInstance = Axios.create({
  baseURL: `${HOSHII_API_URL}/v1`,
  timeout: 60 * 1000,
  withCredentials: true,
  transformRequest: [
    (data) => (data ? JSON.stringify(convertKeysToSnakeCase(data)) : ''),
  ],
  transformResponse: [
    (data) => (data ? convertKeysToCamelCase(JSON.parse(data)) : ''),
  ],
});

const axiosBlobInstance = Axios.create({
  baseURL: `${HOSHII_API_URL}/v1`,
  timeout: 60 * 1000,
  withCredentials: true,
  responseType: 'blob',
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.patch['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.put['Content-Type'] = 'application/json';

const retryConfig = {
  retries: 2,
  retryDelay: AxiosRetry.exponentialDelay,
  shouldResetTimeout: true,
  retryCondition: () => true,
};

AxiosRetry(axiosInstance, retryConfig);
AxiosRetry(axiosBlobInstance, retryConfig);

const httpDel = async (url: string, config?: AxiosRequestConfig) => axiosInstance.delete(url, config);

const httpGet = async (url: string, config?: AxiosRequestConfig) => axiosInstance.get(url, config);

const httpPost = async (
  url: string,
  data?: object,
  config?: AxiosRequestConfig,
) => axiosInstance.post(url, data, config);

const httpPatch = async (
  url: string,
  data: object,
  config?: AxiosRequestConfig,
) => axiosInstance.patch(url, data, config);

const httpPut = async (
  url: string,
  data: object,
  config?: AxiosRequestConfig,
) => axiosInstance.put(url, data, config);

const httpRequest = async (config: AxiosRequestConfig) => axiosInstance.request(config);

const httpGetBlob = async (url: string, config?: AxiosRequestConfig) => axiosBlobInstance.get(url, config);

const activeAxiosInterceptors: { [key: string]: number | null } = {};

const removeHttpRequestConfigInterceptor = (key: string) => {
  const id = activeAxiosInterceptors[key];
  if (doesExist(id)) {
    axiosInstance.interceptors.request.eject(activeAxiosInterceptors[key]!);
    activeAxiosInterceptors[key] = null;
    console.log(
      `[XHR] Removed ${key} (id ${id}) global http request config interceptor`,
    );
  }
};

const removeHttpResponseInterceptor = (key: string) => {
  const id = activeAxiosInterceptors[key];
  if (doesExist(id)) {
    axiosInstance.interceptors.response.eject(activeAxiosInterceptors[key]!);
    activeAxiosInterceptors[key] = null;
    console.log(
      `[XHR] Removed ${key} (id ${id}) global http response interceptor`,
    );
  }
};

const addHttpResponseInterceptor = (
  key: string,
  onFulfilled?: (arg0: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
  onRejected?: (arg0: AxiosError) => any | void,
  options?: AxiosInterceptorOptions,
) => {
  if (doesExist(activeAxiosInterceptors[key])) {
    removeHttpResponseInterceptor(key);
  }

  const id = axiosInstance.interceptors.response.use(
    onFulfilled,
    onRejected,
    options,
  );
  activeAxiosInterceptors[key] = id;
  console.log(`[XHR] Added ${key} (id ${id}) global http response interceptor`);
};

const addHttpRequestConfigInterceptor = (
  key: string,
  onFulfilled?: (
    arg0: InternalAxiosRequestConfig,
  ) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>,
  onRejected?: (arg0: AxiosError) => any | void,
  options?: AxiosInterceptorOptions,
) => {
  if (doesExist(activeAxiosInterceptors[key])) {
    removeHttpRequestConfigInterceptor(key);
  }

  const id = axiosInstance.interceptors.request.use(
    onFulfilled,
    onRejected,
    options,
  );
  activeAxiosInterceptors[key] = id;
  console.log(
    `[XHR] Added ${key} (id ${id}) global http request config interceptor`,
  );
};

const logXhrError = (error: AxiosError) => {
  if (error.response) {
    console.error(
      `[XHR] Server error ${JSON.stringify(error.response.status)}\n`,
      `Data: ${JSON.stringify(error.response.data)}\n`,
      `Headers: ${JSON.stringify(error.response.headers)}`,
    );
    // @ts-ignore
    return error.response.data?.error;
  }
  if (error.request) {
    console.error(
      '[XHR] Network error\n',
      `Request: ${JSON.stringify(error.request)}`,
    );
    return 'Network error';
  }
  console.error('[XHR] Error', error.message);
  console.info(error.config);
  return 'Application error';
};

export {
  httpDel,
  httpGet,
  httpPatch,
  httpPost,
  httpPut,
  httpRequest,
  httpGetBlob,
  addHttpRequestConfigInterceptor,
  removeHttpRequestConfigInterceptor,
  addHttpResponseInterceptor,
  removeHttpResponseInterceptor,
  logXhrError,
};

import {
  useState, useCallback,
} from 'react';

import { httpPatch } from '../helpers/xhr';
import { Prompt } from '../models/Prompt';
import { globalAlertData } from '../state/globalAlertData';
import { genericErrorFeedback } from '../helpers/errors';

const useUpdatePrompt = () => {
  const [isLoading, setIsLoading] = useState(false);

  const updatePrompt = useCallback(
    async (
      prompt: Prompt,
      createAlertData: boolean = true,
      giveErrorFeedback: boolean = true,
    ): Promise<Prompt> => {
      if (!prompt) return Promise.reject(new Error('Prompt is required'));

      setIsLoading(true);

      const filteredPrompt = {
        ...prompt,
        specs: prompt.boundTypeSpecs.map((spec) => ({
          ...spec,
          fields: spec.fields.filter((field) => field.path && field.name),
        })),
      };

      return httpPatch(`/llm/pipelines/prompts/${prompt.id}`, {
        ...filteredPrompt,
      })
        .then((response) => {
          if (createAlertData) {
            globalAlertData.create('Prompt updated successfully');
          }
          return response.data;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Update prompt failed')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    isLoading,
    updatePrompt,
  };
};

export { useUpdatePrompt };

import {
  forwardRef, useImperativeHandle, useMemo, useState, useEffect, useCallback,
} from 'react';
import { Select, SelectOption } from 'components/ui/Select';
import { useFetchTranscriptionLanguages } from 'features/order/api/useFetchTranscriptionLanguages';

type Ref = {
  selectedLanguage: SelectOption;
  isLoading: boolean;
};

interface Props {
  transcriptionLanguage?: string;
}

const TranscriptionLanguageSelect = forwardRef<Ref, Props>(({ transcriptionLanguage }, ref) => {
  const {
    languages, isLoading: isLanguagesLoading, loadLanguages,
  } = useFetchTranscriptionLanguages();

  const [selectedLanguage, setSelectedLanguage] = useState<SelectOption>(null);

  const languageOptions = useMemo(() => languages?.map((language) => ({
    label: language.name,
    value: language.code,
  })) || [],
  [languages],
  );

  const onSelectedLanguageSelectChange = useCallback(
    (selected: { value: string; label: string }) => {
      setSelectedLanguage(selected);
    },
    [],
  );

  useEffect(() => {
    loadLanguages();
  }, [loadLanguages]);

  useEffect(() => {
    const languageOption = languageOptions.find((option) => option.value === transcriptionLanguage);
    if (languageOption) {
      setSelectedLanguage(languageOption);
    }
  }, [languageOptions, transcriptionLanguage]);

  useImperativeHandle(ref, () => ({
    selectedLanguage,
    isLoading: isLanguagesLoading,
  }));

  return (
    <Select
      isLoading={isLanguagesLoading}
      options={languageOptions}
      selectedOption={selectedLanguage}
      onSelectionChange={onSelectedLanguageSelectChange}
      label="Audio language"
    />
  );
});

export default TranscriptionLanguageSelect;

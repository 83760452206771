import { v4 as uuidv4 } from 'uuid';

import { Business } from './Business';
import { MessageSource, MessageType } from './Message';
import { User } from './User';

class Chat {
  id: string;

  business: Business;

  title?: string;

  user?: User;

  lastMessage: string;

  lastMessageCreatedAt: string;

  lastMessageRefAction: ReferenceAction;

  lastMessageRefType: ReferenceType;

  lastMessageType: MessageType;

  lastMessageSource: MessageSource;

  // TODO: proper constructor
  constructor({
    id,
    business,
    user,
  }: {
    id?: string;
    business: Business;
    user?: User;
  }) {
    this.id = id || uuidv4();
    this.business = business;
    this.user = user;
  }
}

class ReferenceAction {
  details: { [key: string]: string };

  type: string;
}

enum ReferenceType {
  Order = 'ORDER',
}

export { Chat };

import { useState, useMemo } from 'react';

import { Page } from 'components/layout/Page/Page';
import {
  ChooseInstructionType,
  InstructionDetail,
} from 'components/steps/instructions';
import { Action, Workflow } from 'types/instruction';
import { InstructionContext } from 'contexts/useInstructionContext';
import { Prompt } from 'models/Prompt';
import { TypeRef } from 'types/type-ref';
import { useCreatePrompt } from 'hooks/useCreatePrompt';
import { workflowToPipeline } from 'helpers/instruction';

const steps = [
  { component: ChooseInstructionType },
  { component: InstructionDetail },
];

const CreateInstructionPage = () => {
  const { isLoading: isCreatePromptLoading, createPrompt } = useCreatePrompt();

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedWorkflow, setSelectedWorkflow] = useState<
  Workflow | null>(Workflow.ORDER); // TODO: reset to null
  const [selectedAction, setSelectedAction] = useState<Action | null>(Action.CREATE);
  const [instructionName, setInstructionName] = useState('Test');

  const [prompt, setPrompt] = useState<Prompt | null>({
    id: '',
    name: instructionName,
    boundTypeSpecs: [{
      // TODO: support multiple workflows
      typeRef: TypeRef.ORDER_DRAFT,
      fields: [],
    }],
    customerIds: [],
  });

  const handleNextStep = () => setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  const handlePreviousStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  const StepComponent = steps[currentStep].component;

  const stepProps = {
    onPrevious: handlePreviousStep,
    ...(currentStep === 0 && {
      onNext: handleNextStep,
    }),
    ...(currentStep === 1 && {
      buttonTitle: 'Create',
      onButtonClick: () => {
        createPrompt(prompt, instructionName, workflowToPipeline(selectedWorkflow));
      },
      isOuterLoading: isCreatePromptLoading,
      title: 'Create instruction',
      subtitle: 'Create a new instruction by specifying customers and fields to be extracted.',
    }),
  };

  const contextValue = useMemo(
    () => ({
      selectedAction,
      setSelectedAction,
      selectedWorkflow,
      setSelectedWorkflow,
      instructionName,
      setInstructionName,
      prompt,
      setPrompt,
    }),
    [selectedAction, selectedWorkflow, instructionName, prompt],
  );

  return (
    <InstructionContext.Provider value={contextValue}>
      <Page contentWithPadding>
        <StepComponent {...stepProps} />
      </Page>
    </InstructionContext.Provider>
  );
};

export default CreateInstructionPage;

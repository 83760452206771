import { useEffect, useState } from 'react';

import { SidebarBase } from '../SidebarBase';
import { SelectedStep } from './_Initial/types';

import { _Body as InitialBody } from './_Initial/_Body';
import { _Header as InitialHeader } from './_Initial/_Header';
import { _Footer as InitialFooter } from './_Initial/_Footer';
import { _Header as MergeHeader } from './_Merge/_Header';
import { _Body as MergeBody } from './_Merge/_Body';
import { _Footer as MergeFooter } from './_Merge/_Footer';
import { _Sidebar as CreateSidebar } from './_Create/_Sidebar';
import { Business } from '../../../models/Business';

interface Props {
  customer?: Business;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  onComplete: (customer: Business) => void;
}

const ManageCustomerSidebar = ({
  customer,
  sidebarOpen,
  setSidebarOpen,
  onComplete,
}: Props) => {
  const [selectedStep, setSelectedStep] = useState<SelectedStep | null>(null);

  const onBack = () => {
    setSelectedStep(null);
  };

  const onNext = (next: SelectedStep) => {
    setSelectedStep(next);
  };

  const _onComplete = (_customer: Business) => {
    setSidebarOpen(false);
    onComplete({
      ..._customer,
      customer: {
        ..._customer.customer,
        isConfirmed: true,
      },
    });
  };

  useEffect(() => {
    // reset on close
    if (!sidebarOpen) {
      setSelectedStep(null);
    }
  }, [sidebarOpen]);

  if (selectedStep === null) {
    return (
      <SidebarBase
        header={<InitialHeader />}
        body={<InitialBody onNext={onNext} />}
        footer={<InitialFooter />}
        sidebarWidth="max-w-[30vw]"
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    );
  }

  if (selectedStep === SelectedStep.CREATE) {
    return (
      <CreateSidebar
        customer={customer}
        onBack={onBack}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        _onComplete={_onComplete}
      />
    );
  }

  return (
    <SidebarBase
      header={<MergeHeader onBack={onBack} />}
      body={<MergeBody customer={customer} onComplete={_onComplete} />}
      footer={<MergeFooter />}
      sidebarWidth="max-w-[30vw]"
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
    />
  );
};

export { ManageCustomerSidebar };

import {
  ReactNode, createContext, useContext, useMemo, useState,
} from 'react';
import { Order } from '../models/Order';

type OrderContextType = {
  groupOrders: Order[];
  setGroupOrders: React.Dispatch<React.SetStateAction<Order[]>>;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;

  deletedOrderIds: string[];
  setDeletedOrderIds: React.Dispatch<React.SetStateAction<string[]>>;
};
const OrderContext = createContext<OrderContextType | undefined>(undefined);

type OrderProviderProps = {
  children: ReactNode;

  orderGroup?: Order[];
};

const OrderProvider: React.FC<OrderProviderProps> = ({
  children,
  orderGroup: _orderGroup,
}: OrderProviderProps) => {
  const [groupOrders, setGroupOrders] = useState<Order[]>(_orderGroup || []);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [deletedOrderIds, setDeletedOrderIds] = useState<string[]>([]);

  const contextValue = useMemo(
    () => ({
      groupOrders,
      setGroupOrders,
      selectedIndex,
      setSelectedIndex,
      deletedOrderIds,
      setDeletedOrderIds,
    }),
    [groupOrders, selectedIndex, deletedOrderIds],
  );

  return (
    <OrderContext.Provider value={contextValue}>
      {children}
    </OrderContext.Provider>
  );
};

const useOrderContext = () => {
  const context = useContext(OrderContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by OrderContext provider.');
  }

  return context;
};

export { OrderContext, useOrderContext, OrderProvider };

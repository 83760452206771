import {
  useState, useRef, useEffect, ReactNode, useCallback,
} from 'react';
import { ScrollArea } from '@mantine/core';

interface Props {
  children: ReactNode;
  maxVh?: number; // when this is indicated, take the minimum bet the children height and maxVh

  className: string;
  childrenClassName?: string;
  offsetScrollbar?: boolean;
  scrollType?: 'scroll' | 'auto' | 'always' | 'hover' | 'never';
  onScroll?: (position: { x: number; y: number }) => void;
  onScrolledEnd?: () => void;
}

const ScrollAreaWrapper = ({
  children,
  maxVh,
  className,
  childrenClassName,
  offsetScrollbar,
  scrollType = 'scroll',
  onScroll,
  onScrolledEnd,
}: Props) => {
  const [scrollAreaHeight, setScrollAreaHeight] = useState(0);
  const [scrollAreaWidth, setScrollAreaWidth] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const viewPortRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  const onScrollPositionChange = useCallback(
    (position: { x: number; y: number }) => {
      onScroll?.(position);
      if (
        viewPortRef.current.scrollHeight
        <= Math.round(position.y) + viewPortRef.current.clientHeight
      ) onScrolledEnd?.();
    },
    [onScroll, onScrolledEnd],
  );

  // Set the scrollarea's height and width
  useEffect(() => {
    if (containerRef.current !== null) {
      if (maxVh) {
        const childrenHeight = viewPortRef.current.scrollHeight;
        const maxHeight = maxVh * window.innerHeight;
        setScrollAreaHeight(Math.min(childrenHeight, maxHeight));
      } else {
        setScrollAreaHeight(containerRef.current.clientHeight);
      }

      setScrollAreaWidth(containerRef.current.clientWidth);

      viewPortRef.current.style.width = `${containerRef.current.clientWidth}px`;
    }
  }, [maxVh]);

  return (
    <div className={className} ref={containerRef}>
      <ScrollArea.Autosize
        viewportRef={viewPortRef}
        mah={scrollAreaHeight}
        w="100%"
        maw={scrollAreaWidth}
        type={scrollType}
        scrollbars="y"
        onScrollPositionChange={onScrollPositionChange}
        scrollbarSize={8}
        offsetScrollbars={offsetScrollbar}
      >
        <div
          ref={childrenRef}
          style={{ height: scrollAreaHeight, maxWidth: scrollAreaWidth }}
          className={childrenClassName}
        >
          {children}
        </div>
      </ScrollArea.Autosize>
    </div>
  );
};

export default ScrollAreaWrapper;

import { useState, useCallback } from 'react';

import { httpPost } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';

const useResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = useCallback(
    async (appLink: string, password: string, giveErrorFeedback: boolean = true): Promise<void> => {
      setIsLoading(true);
      return httpPost(`/auth/password/reset/${appLink}`, { password })
        .then(() => {})
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Failed to reset password')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return { isLoading, resetPassword };
};

export { useResetPassword };

import { useState } from 'react';
import { motion } from 'framer-motion';
import TypeWriter from 'typewriter-effect';

import { AdamMessageWrapper } from '../../../../wrapper/AdamMessageWrapper';

const text = 'Please enter the name for your new instruction.';

const buttonContainerVariants = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const buttonVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  show: { opacity: 1, scale: 1 },
};

const ChooseExistingInstruction = () => {
  const [textField, setTextField] = useState(false);
  const instructionNames = [
    'test 1',
    'test 2',
    'test 3',
    'test 4',
    'test 5',
  ];

  return (
    <AdamMessageWrapper>
      <TypeWriter
        onInit={(typewriter) => {
          typewriter
            .changeDelay(15)
            .typeString(text)
            .callFunction(() => {
              document
                .querySelectorAll('.Typewriter__cursor')
                ?.forEach((cursor) => {
                  cursor.classList.add('hidden');
                });
              setTextField(true);
            })
            .start();
        }}
      />
      {
        textField && (
        <motion.div
          className="flex flex-wrap gap-x-3 pt-3"
          variants={buttonContainerVariants}
          initial="hidden"
          animate="show"
        >
          { instructionNames.map((name) => (
            <motion.div
              key={name}
              className="flex flex-col items-center space-y-2"
              variants={buttonVariants}
            >
              <button
                type="button"
                className="w-[300px]"
                onClick={() => {
                }}
              >
                {name}
              </button>
            </motion.div>
          ))}
        </motion.div>
        )
}
    </AdamMessageWrapper>
  );
};
export default ChooseExistingInstruction;

import { useCallback } from 'react';
import { Tooltip } from '@mantine/core';
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';

import { Button } from '../../../../ui/Button';
import { Business } from '../../../../../models/Business';
import { Message } from '../../../../../models/Message';
import { decodeEntities } from '../../../../../utils/messageUtils';

interface Props {
  message: Message;
  business?: Business;
}

const Actions = ({
  message,
  business,
}: Props) => {
  const makeMailToLink = useCallback(() => {
    let href = `mailto:${business?.emails[0]}`;
    if (message.context?.subject) {
      href += `?subject=${encodeURIComponent(`Re: ${decodeEntities(message.context.subject)}`)}`;
    }
    href += '&body=';
    return href;
  }, [business?.emails, message.context?.subject]);

  return (
    <div
      className="invisible group-hover:visible flex flex-col items-center space-y-2 transition-opacity duration-300"
    >
      {business?.emails?.length > 0 && (
        <Tooltip label="Reply" position="right" offset={5} withArrow arrowOffset={1} arrowSize={4} openDelay={1000}>
          <a href={makeMailToLink()} className="block">
            <Button
              className="rounded-full shadow-md bg-gra-50 hover:bg-gray-100 font-semibold"
              theme="transparent"
              title=""
              icon={
                <ArrowUturnLeftIcon className="w-4 h-4 text-gray-600 aspect-square" />
              }
              onClick={() => {}}
            />
          </a>
        </Tooltip>
      )}
    </div>
  );
};

export default Actions;

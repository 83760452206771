import { Checkbox } from '@mantine/core';
import { useRef } from 'react';
import { ImportPreviewTable } from '../ImportPreviewTable';
import { Wrapper } from './Wrapper';

interface Props {
  isLoading: boolean;
  selectedMatcher: any;
  confirmedHeaders: string[];
  confirmedItems: string[][];
  onStepBackClicked: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => void;
  onUploadClicked: (isChecked: boolean) => void;
}

const UploadConfirm = ({
  isLoading,
  selectedMatcher,
  confirmedHeaders,
  confirmedItems,
  onStepBackClicked,
  onUploadClicked,
}: Props) => {
  const checkRef = useRef(null);

  const buttonClicked = () => {
    onUploadClicked(checkRef.current?.checked);
  };

  return (
    <Wrapper
      isButtonLoading={isLoading}
      onStepBackClicked={(e) => {
        onStepBackClicked(e, 'Review & Confirm');
      }}
      onConfirmClicked={buttonClicked}
    >
      <div className="pt-2">
        <div>
          {selectedMatcher && (
            <p className="text-label-caption-md">
              Matcher name:
              {' '}
              <span className="">{selectedMatcher.matcherName}</span>
            </p>
          )}
        </div>

        <div className="pt-5">
          <ImportPreviewTable
            headers={confirmedHeaders}
            contents={confirmedItems}
          />
        </div>

        <div className="pt-5">
          <Checkbox
            ref={checkRef}
            radius="xs"
            label="Delete all of the products that were not included in the file"
          />
        </div>
      </div>
    </Wrapper>
  );
};

export { UploadConfirm };

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { CreateInstructionPage } from '../pages';

const CreateInstructionScreen = () => (
  <ProtectedScreen>
    <CreateInstructionPage />
  </ProtectedScreen>
);

export default CreateInstructionScreen;

import {
  TextInput,
  PasswordInput,
  InputWrapper,
  PinInput,
} from '@mantine/core';
import { ChangeEvent, FocusEvent, forwardRef } from 'react';
import { FormikErrors } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Props {
  field: string;
  type?: string;
  value: string;
  label: string;
  placeHolder?: string;
  description?: string;
  autoComplete?: string;
  error?: string;
  required?: boolean;
  touched?: boolean;
  disabled?: boolean;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<any>>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const FormInput = forwardRef(
  (
    {
      field,
      type,
      value,
      label,
      placeHolder,
      description,
      autoComplete,
      error,
      required,
      touched,
      disabled,
      setFieldValue,
      onChange,
      onBlur,
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    if (type === 'phone') {
      return (
        <div>
          <InputWrapper
            ref={ref}
            id={field}
            label={label}
            required={required}
            error={error && touched ? error : ''}
          >
            <PhoneInput
              country="ch"
              onlyCountries={['ch', 'at', 'de']}
              value={value}
              onChange={(v, data, event) => {
                setFieldValue?.(field, `+${v}`);
                onChange?.(event);
              }}
              inputProps={{
                name: field,
              }}
              inputStyle={{
                width: '100%',
              }}
              buttonStyle={{}}
            />
          </InputWrapper>
        </div>
      );
    }

    if (type === 'password') {
      return (
        <div>
          <div>
            <PasswordInput
              ref={ref}
              id={field}
              name={field}
              type={type}
              label={label}
              description={description}
              placeholder={placeHolder}
              autoComplete={autoComplete}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              required={required}
              disabled={disabled}
              error={error && touched ? error : ''}
            />
            {/* {error && touched ? <div className="mb-[6px] text-error text-label-caption">{error}</div> : null} */}
          </div>
        </div>
      );
    }

    if (type === 'totpCode') {
      return (
        <div>
          <PinInput
            ref={ref}
            placeholder="-"
            id={field}
            name={field}
            value={value}
            onChange={(v) => setFieldValue('totpCode', v)}
            onBlur={onBlur}
            length={6}
            size="md"
            autoFocus
            manageFocus
            disabled={disabled}
          />
        </div>
      );
    }

    return (
      <div>
        <div>
          <TextInput
            ref={ref}
            id={field}
            name={field}
            type={type}
            label={label}
            description={description}
            placeholder={placeHolder}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            disabled={disabled}
            error={error && touched ? error : ''}
          />
        </div>
      </div>
    );
  },
);

FormInput.defaultProps = {
  type: 'text',
  description: null,
  error: null,
  required: false,
  autoComplete: 'off',
  touched: false,
  disabled: false,
  onBlur: null,
};

export { FormInput };

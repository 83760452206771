import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { ROUTES } from 'config/routes';

import { ChatPage } from '../pages';

const ChatScreen = () => {
  const { chatId } = useParams();
  const navigate = useNavigate();

  const navigateToChat = useCallback(
    (chatIdParam: string) => {
      navigate(ROUTES.CHAT_BY_ID(chatIdParam));
    },
    [navigate],
  );

  return (
    <ProtectedScreen>
      <ChatPage chatId={chatId} navigateToChat={navigateToChat} />
    </ProtectedScreen>
  );
};

export default ChatScreen;

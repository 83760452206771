import { memo, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import { Message } from '../../../../../models/Message';
import { decodeEntities } from '../../../../../utils/messageUtils';

interface Props {
  message: Message;
}

const Subject = memo(({ message }: Props) => {
  const [subjectExpanded, setSubjectExpanded] = useState(false);

  if (message.context?.subject) {
    return (
      <div className="group mb-2 flex w-full space-x-2 border-b border-solid pb-2">
        <div
          className={`flex ${subjectExpanded ? 'w-[90%]' : 'w-[max(30ch,90%)] truncate'}`}
        >
          <span className="mr-[0.5ch] font-extralight">Subject:</span>
          <div className={!subjectExpanded && 'truncate'}>
            {decodeEntities(message.context?.subject)}
          </div>
        </div>
        {/* TODO: add group-hover:block */}
        <div className="hidden">
          <button
            type="button"
            onClick={() => setSubjectExpanded((prevState) => !prevState)}
          >
            {subjectExpanded ? (
              <ChevronUpIcon className="aspect-square w-4" />
            ) : (
              <ChevronDownIcon className="aspect-square w-4" />
            )}
          </button>
        </div>
      </div>
    );
  }

  return null;
});

export default Subject;

const splitAtIndex = (string: string, index: number) => [
  string.substring(0, index),
  string.substring(index),
];

const capitalizeFirstLetter = (text: string) => {
  if (!text || text.length === 0) return text;
  return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
};

export { splitAtIndex, capitalizeFirstLetter };

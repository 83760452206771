import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

const initDateTimeHelper = () => {
  dayjs.extend(LocalizedFormat);
};

const isZeroTime = (datetime: string) => datetime === '0001-01-01T00:00:00Z';

export { initDateTimeHelper, isZeroTime };
